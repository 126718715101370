import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
var format = require("date-format");

export default function MiniChartRegulating({
  telemetryData,
  pumpNo,
  telemetryType,
  loading,
  noDataOrFound,
  type,
}) {
  const COLORS = [
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e74c3c",
    "#7f8c8d",
    "#107C10",
    "#E81123",
    "#FF8C00",
    "#0099BC",
  ];
  const tickFormatter = (tick) => format("hh:mm:ss", new Date(tick));

  var domain = [0, 120];
  if (type === "pressure") {
    domain = [0, 40];
  }
  // console.log("telemetryData", telemetryData);
  //conly show the data from telemetryData that has the InverterSlaveIdPacket equal to pumpNo
  const telemetryDataNew = telemetryData.filter(
    (data) =>
      data.InverterSlaveIdPacket === data.InverterRegSlaveId ||
      data.InverterSlaveIdPacket === 1,
  );

  const getCardTitle = () => {
    switch (telemetryType) {
      case "PressureIN":
        return "Pressure in";
      case "PressureOUT":
        return "Pressure out";
      case "Temperature_Water":
        return "Water temperature";
      case "Temperature_igbt":
        return "Power Module";
      case "InverterFreqReadout":
        return "Inverter Frequency";
      case "Inverter_loadPowP":
        return "Power";
      default:
        return null;
    }
  };

  const getCardValue = (value) => {
    switch (telemetryType) {
      case "PressureIN":
        return value?.PressureIN.toFixed(2) + " Bar";
      case "PressureOUT":
        return value?.PressureOUT.toFixed(2) + " Bar";
      case "Temperature_Water":
        return value?.Temperature_Water.toFixed(2) + " °C";
      case "Temperature_igbt":
        return value?.Temperature_igbt.toFixed(2) + " °C";
      case "InverterFreqReadout":
        return value?.InverterFreqReadout.toFixed(2) + " Hz";
      case "Inverter_loadPowP":
        return value?.Inverter_loadPowP.toFixed(2) / 1000 + " kW";
      default:
        return null;
    }
  };
  // console.log("telemetryData", telemetryDataNew);
  return (
    <div className="m-2 rounded-xl border-t border-gray-100 bg-gray-100">
      <dl className="divide-y divide-gray-100">
        <div className="min-h-20 min-w-20 px-4 py-2 sm:px-4">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              {getCardTitle()} #{pumpNo}
            </h3>
            <h3 className="text-2xl font-bold leading-9 text-gray-900">
              {getCardValue(telemetryDataNew[telemetryDataNew.length - 1])}
            </h3>
            {loading ? (
              <div className="h-80 w-auto animate-pulse rounded bg-slate-200"></div>
            ) : noDataOrFound ? (
              <div>
                <div className="flex flex-row items-center justify-center">
                  <div className="text-base text-gray-700">
                    We could not receive data from the machines <br />
                    The machine might be turned off or the internet connection
                    is lost.
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ width: "100%", height: "100%" }}>
                  <ResponsiveContainer width={500} height={100}>
                    <LineChart
                      label={<CustomLabel />}
                      data={telemetryDataNew}
                      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                      syncId={"telemetry-page"}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      {/* <XAxis
                  dataKey="EventEnqueuedUtcTime"
                  tickFormatter={tickFormatter}
                  angle={30}
                  dx={20}
                  tickMargin={15}
                  height={50}
                  type="category"
                ></XAxis> */}
                      {/* <YAxis yAxisId="left" unit=' Bar' domain={[0, 40]} />  label={{ value: "Bar", position: 'insideLeft' }} domain={['dataMin', 'dataMax']} label={{ value: this.state.widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}

                      {/* label={{ value: "°C", position: 'insideRight' }}*/}
                      <YAxis yAxisId="left" domain={[0, 40]} hide={true} />
                      {/*label={{ value: "Bar", position: 'insideLeft' }}  domain={['dataMin', 'dataMax']} label={{ value: widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}

                      <Line
                        unit={" Bar"}
                        strokeWidth={2}
                        legendType="plainline"
                        isAnimationActive={false}
                        type="monotone"
                        dataKey={telemetryType}
                        stroke={COLORS[1]}
                        name="Pressure In"
                        dot={false}
                        yAxisId="left"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div id="html-dist"></div>
              </div>
            )}
          </div>
        </div>
      </dl>
    </div>
  );
}
function CustomLabel({ x, y, stroke, value, width }) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}
