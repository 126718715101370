import io from "socket.io-client";
let socket;
export const initiateSocket = (room) => {
  socket = io(
    "https://dev-backend-nilfiskfood.azurewebsites.net/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJ0Y2lvYmFudUBuaWxmaXNrZm9vZC5jb20iLCJ1dWlkIjoiYmZlMzNhMmQtYTg2My00Yjg3LTk0MzEtY2M3NDBlMzY1YjE1IiwiaWF0IjoxNzE5OTAzODQzLCJleHAiOjE3MjA1MDg2NDN9.b9m890i2ja4IKsysRuyE_WrwhEqk3WhGa_OjE6lfrKw",
    // "https://dev-backend-nilfiskfood.azurewebsites.net",
    
    {
      // transport: ["websocket"],
      withCredentials: true, // Add this line
      extraHeaders: {
        // Add this block
        // "Access-Control-Allow-Origin": "*", // Adjust this if necessary
      },
    },
  );
  console.log(`Connecting socket...`);
  console.log("room", room)
  console.log("socket", socket)
  if (socket && room) {
    console.log("here1")
    // socket.emit("subscribe", room);
  }
};
export const disconnectSocket = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};

export const joinRoom = (room) => {
  console.log("joining room..." + room);
  if (socket) socket.emit("subscribe", room);
};

export const leaveRoom = (room) => {
  console.log("Leaving room..." + room);
  if (socket) socket.emit("unsubscribe", room);
};

export const getDeviceData = (deviceId) => {
  console.log("Getting device data...");
  if (socket) socket.emit("leave", deviceId);
};
export const getSocket = () => {
  if (socket) return socket;
};
