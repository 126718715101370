import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeIntializeServer, HttpServer, InitializeServer, SendCommand, ServerExist } from "../Models/ServerCommuncation/Server";
import { DisconnectErrorType, IoSocket } from "../Models/ServerCommuncation/Socket";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import ClientMenuPage from "./ClientMenuPage";
import "../dashboard.css";
import { useIdleTimer } from 'react-idle-timer'


const TEN_MINUTES = 1000 * 60 * 10;
export default function NavigatorApp() {
  const navigate = useNavigate();
  const state: { serialNumber?: string, serviceCode?: string, DeinitServer?: boolean, userDiconnected?: boolean } = useLocation().state;
  window.history.replaceState({ state: {} }, "");
  const [deviceConnected, setDeviceConnected] = React.useState({ state: false, has_been_true: false });
  const [Server, setServer] = React.useState<HttpServer | null>(null);
  useEffect(() => {
    window.onbeforeunload = (event) => {
      if (ServerExist()) {
        DeIntializeServer();

      }

      SendCommand("SetNavigatorDisconnect", state.serialNumber!, state.serviceCode!, "", (data, err) => {

      });
      return undefined;

    };
    window.onpopstate = (event) => {
      if (ServerExist()) {
        DeIntializeServer();

      }
      SendCommand("SetNavigatorDisconnect", state.serialNumber!, state.serviceCode!, "", (data, err) => {

      });
      return undefined;

    };
  });
  useIdleTimer({
    onIdle: () => {
      SendCommand("SetNavigatorDisconnect", state.serialNumber!, state.serviceCode!, "", (data, err) => {

      });

      DeIntializeServer();
      navigate("/navigator/connect", { state: { userInactive: true } });
    },
    timeout: TEN_MINUTES
  });





  useEffect(() => {
    if (deviceConnected.state === false && deviceConnected.has_been_true === true && Server !== null) {
      DeIntializeServer();
      setServer(null);
    }
  }, [Server, deviceConnected])
  useEffect(() => {
    if (Server) {
      return;
    }
    function DisconnectFromDeviceWithError(err: DisconnectErrorType) {
      switch (err) {
        case "Machine": {


          navigate("/navigator/connect", { state: { errorMessage: "Machine disconnected" } })
          break;
        }
        case "Server": {

          navigate("/navigator/connect", { state: { errorMessage: "Server disconnected. Machine did not disconnect navigator mode. Please ask user to disconnect navigator mode" } })
          break;
        }
        case "NoError": {
          SendCommand("SetNavigatorDisconnect", state.serviceCode!, state.serialNumber!, "", () => {

          });

          navigate("/navigator/connect");
          break;
        }
      }

      setDeviceConnected({ state: false, has_been_true: false });
      if (Server !== null) {
        DeIntializeServer();

        setServer(null);
      }



    }
    const IntializeAndSetServer = async () => {

      setServer(await InitializeServer(new IoSocket(state.serialNumber!), state.serialNumber!, state.serviceCode!, DisconnectFromDeviceWithError));

    }
    if (!state?.serialNumber) {
      navigate("/navigator/connect", { state: { errorMessage: "Machine disconnected" } })
      return;

    }

    IntializeAndSetServer();


  }, [Server]);

  useEffect(() => {



    if (!Server) {
      return;
    }

    function LookForNavigatorMode(data: DatedIotData) {

      if (data.data.NavigatorEnabled === 1 && deviceConnected.has_been_true === false) {
        setDeviceConnected({ state: data.data!.NavigatorEnabled === 1, has_been_true: true });

        return;
      }


      if (data.data.NavigatorEnabled === 0 && deviceConnected.has_been_true === true) {
        Server?.disconnect_with_reason("Machine")
        setDeviceConnected({ state: data.data!.NavigatorEnabled === 0, has_been_true: true });

        return;
      }
      setDeviceConnected((prev) => prev);

    }

    Server.on_telemtry(LookForNavigatorMode);

    return () => {
      Server.off_telemtry(LookForNavigatorMode);
    };
  }, [Server, deviceConnected]);





  if (!deviceConnected.state || !state?.serialNumber) {

    return <Grid item container xs height={"81vh"} direction={"column"} justifyContent={"center"} alignContent={"center"}>
      <Grid item xs container justifyContent={"center"}><Typography variant="button" color={"black"}>succesfully established connection to machine.. Loading settings</Typography></Grid>
      <Grid item xs container justifyContent={"center"}> <CircularProgress size={"5vmax"}></CircularProgress></Grid>
    </Grid>;
  }
  return <div className=" w-full overflow-x-hidden"><ClientMenuPage machineId={state.serialNumber}></ClientMenuPage></div>;


}
