import apiClient from "./apiClient";

export const downloadWashProgramFile = async (serialNumber) => {
  return apiClient.get(`foamatic/download-washprogram/${serialNumber}`);
};

export const uploadWashProgramFile = async (serialNumber, file) => {
  return apiClient.post(`foamatic/upload-washprogram/${serialNumber}`, {
    data: file,
  });
};
