import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import BarChartConsumption from "pages/Dashboard/Chart/BarChartConsumption";
import BarChartPower from "pages/Dashboard/Chart/BarChartPower";
import { useMachineConsumptionDataQuery } from "hooks/machine/useMachineConsumptionDataQuery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const { data, isLoading, error } =
    useMachineConsumptionDataQuery("14701000001");

  return (
    <div>
      <h3 className="text-base font-semibold text-gray-900">Dashboard</h3>

      <dl className="mt-5 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-5">
        <div className="flex flex-col space-y-8">
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <UsersIcon aria-hidden="true" className="size-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                Total Factories
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">5</p>

              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    View all<span className="sr-only"> Factories</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
          {/* machines */}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <UsersIcon aria-hidden="true" className="size-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                Total Machines
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">16</p>
              <p
                className={classNames(
                  "ml-2 flex items-baseline text-sm font-semibold text-green-600",
                )}
              >
                Online: 10
              </p>
              <p
                className={classNames(
                  "ml-2 flex items-baseline text-sm font-semibold text-red-600",
                )}
              >
                Offline: 5
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    View all<span className="sr-only"> Machines stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        </div>
        {/* ------------------------------------------------ */}
        {/* Running hours */}
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <UsersIcon aria-hidden="true" className="size-6 text-white" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Total Running Hours
            </p>
          </dt>
          <dd className="ml-16 mt-4 pb-6 sm:pb-7">
            <BarChartConsumption data={data?.data?.result} loading={false} />
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all<span className="sr-only"> Machines stats</span>
                </a>
              </div>
            </div>
          </dd>
        </div>
        {/* ------------------------------------------------ */}
        {/* Running hours */}
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <UsersIcon aria-hidden="true" className="size-6 text-white" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Total Power Consumption
            </p>
          </dt>
          <dd className="ml-16 mt-4  pb-6 sm:pb-7">
            <BarChartPower data={data?.data?.result} loading={isLoading} />
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  View all<span className="sr-only"> Machines stats</span>
                </a>
              </div>
            </div>
          </dd>
        </div>
        {/* ------------------------------------------------ */}
        {/* {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon aria-hidden="true" className="size-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">
                {item.stat}
              </p>
              <p
                className={classNames(
                  item.changeType === "increase"
                    ? "text-green-600"
                    : "text-red-600",
                  "ml-2 flex items-baseline text-sm font-semibold",
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className="size-5 shrink-0 self-center text-green-500"
                  />
                ) : (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className="size-5 shrink-0 self-center text-red-500"
                  />
                )}

                <span className="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))} */}
      </dl>
    </div>
  );
}
