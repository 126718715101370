import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { addMachine, addOwnMachine } from "api/machine.service";

export const useAddMachine = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return addMachine(data);
      else return addOwnMachine(data);
    },
    onSuccess: () => {
      // navigate("/commander");
    },
    onError: (error) => {
      console.log("errror", error);
    },
  });
};
