import { Button, CircularProgress, Grid, Grow, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { InfoElement } from "./InfoElement";

type TotalTripCountersData = {
  "Install Time": string;
  "Last Reset Trip Time": string & {
    [key: string]: InverterData;
  };
};

type InverterData = { "Total Run Hours": number; "Total On Hours": number; "Total kWh": number };





export function DisplayInverters(): JSX.Element {
  function GetInverters(TotalTripCounters: TotalTripCountersData): Record<string, InverterData> {

    const { "Install Time": omit1, "Last Reset Trip Time": omit2, ...rest } = TotalTripCounters;

    return rest;
  }

  const Server = GetServer();
  const [Inverters, SetInverters] = React.useState<Record<string, InverterData> | null>(null);
  const [Inverter, SetInverter] = React.useState<string>("Inverter1");

  useEffect(() => {
    Server.SendCommand("GetTotalCountersJSON", null, (data, err) => {
      if (!err) {

        let totalTripCounters = data as any as TotalTripCountersData;

        SetInverters(GetInverters(totalTripCounters));
      }
    });
  }, [Server]);
  if (!Inverters) {
    return <Grid item container xs={11} bgcolor={"white"} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"} className="shadow" height={"14vh"} width={"100%"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (
    <Grid item container xs={11} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"14vh"}>
      <SelectInverters Inverters={Inverters} inverter={Inverter} SetInverter={SetInverter}></SelectInverters>
      <DisplayInverter Inverter={Inverters[Inverter]}></DisplayInverter>
    </Grid>
  );
}

function DisplayInverter({ Inverter }: { Inverter: InverterData }) {
  let Inverters = Object.entries(Inverter);

  return (
    <Paper sx={{ width: '100%', height: "max(110px,14.3vh)", borderRadius: "0.5vw", '& .MuiTableCell-head': { lineHeight: "0.5vh" } }} className="shadow" >
      <TableContainer sx={{ height: "max(110px,14.3vh)", overflowX: "hidden", }} className="scroll" >
        <Table stickyHeader>
          <TableHead sx={{ lineHeight: "20px" }}>
            <TableRow  >
              <TableCell sx={{ maxWidth: "5vw" }} >
                Name
              </TableCell>
              <TableCell  >
                Value
              </TableCell >

            </TableRow>
          </TableHead>

          <TableBody>
            {Inverters.map(([key, val], idx) => {
              if (idx === Inverters.length - 1) {
                return <TableRow sx={{ "& td": { border: 0 } }}><InfoElement name={key} value={val.toString()}></InfoElement> </TableRow>;

              }
              return <TableRow><InfoElement name={key} value={val.toString()}></InfoElement> </TableRow>;
            })}

          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

  )

}
function SelectInverters({ Inverters, inverter, SetInverter }: { Inverters: Record<string, InverterData>; inverter: String; SetInverter: React.Dispatch<React.SetStateAction<string>> }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose(key: string) {
    SetInverter(key);

    setAnchorEl(null);
  }
  return (
    <Grid item container justifyContent={"center"} alignItems={"center"}>
      <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        {inverter}
      </Button>
      <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" sx={{ bgcolor: "white", position: "absolute", overflow: "auto", }} className="scroll shadow">
                {Object.keys(Inverters).map((inverterkey) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose(inverterkey);
                      }}
                    >
                      {inverterkey}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
