import { useEffect, useState } from "react";

function getWindowDimensions() {
  let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  return {
    width: vw,
    height: vh,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}


function getBounding(div: string) {
  let elem = document.querySelector(div);
  let rect = elem!.getBoundingClientRect();
  let vw = rect.width
  let vh = rect.height
  return {
    width: vw,
    height: vh,
  };
}

export function useBoundingClientRect(div: string) {
  const [boundingDimensions, setBoundingDimensions] = useState(getBounding(div));

  useEffect(() => {
    function handleResize() {
      setBoundingDimensions(getBounding(div));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return boundingDimensions;
}
export function get_tick(start: number, end: number, times: number): number[] {
  let step = (end - start) / (times - 1);


  let base = start;
  let ticks = [];
  for (let i = 0; i < times; i++) {
    ticks.push(base);
    base += step;
  }
  return ticks;
}
