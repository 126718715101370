import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useChangeMachineName } from "../../../hooks/machine/useChangeMachineName";

export default function TextInputForm({
  name,
  autoComplete,
  defaultValue,
  type,
  required,
  isLoading,
  machineData,
  editDisabled = false,
  canEdit = true,
}) {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const [isEditing, setIsEditing] = useState(false);

  const {
    mutate: changeMachineName,
    isPending,
    isError,
    isSuccess,
  } = useChangeMachineName();

  const onSubmit = (data) => {
    clearErrors();
    if (required && (!data[name] || !data[name].trim())) {
      setError(name, { type: "required", message: name + " is required" });
      return;
    }

    let newData = {};

    switch (name) {
      case "Alias":
        newData = {
          name: data[name],
          MachineId: machineData.id,
        };
        break;
      default:
        break;
    }
    changeMachineName(newData, {
      onSuccess: () => {
        queryClient.setQueryData(
          ["machine", machineData.serialNumber, "details"],
          (oldData) => {
            console.log("oldData", oldData);
            console.log("newData", newData);
            return {
              ...oldData,
              data: {
                ...oldData.data,
                machine: [
                  { ...oldData.data.machine[0], ...newData },
                  ...oldData.data.machine.slice(1),
                ],
              },
            };
          },
        );
        queryClient.invalidateQueries({
          queryKey: ["machine", machineData.serialNumber, "details"],
        });
        setIsEditing(false);
      },
      onError: (error) => {
        setError(name, { type: "manual", message: "Please check your input" });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ${!isEditing ? "sm:py-6" : "sm:py-4"}`}
      >
        <dt className="text-sm font-medium text-gray-900 self-center">{name}</dt>
        <dd
          className={`mt-1 flex space-x-4 text-sm text-gray-700 sm:col-span-2 sm:mt-0`}
        >
          <span className="flex-grow self-center">
            {isLoading ? (
              <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
            ) : !isEditing ? (
              <>{defaultValue || " "}</>
            ) : (
              <div className="relative sm:w-2/3">
                <input
                  id={name}
                  name={name}
                  type={type}
                  autoComplete={autoComplete}
                  required={required}
                  defaultValue={defaultValue}
                  disabled={isPending}
                  {...register(name, { required: required })}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400  focus:ring-2 focus:ring-inset sm:w-full ${
                    errors[name]
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
                <p
                  className="mt-2 text-sm text-red-600"
                  id="email-error"
                  hidden={!errors[name]}
                >
                  {errors[name]?.message}
                </p>
                {isPending && (
                  <div
                    className="absolute right-2  top-1.5 inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            )}
          </span>

          <span className="ml-4 flex-shrink-0 self-center">
            <button
              type="button"
              onClick={() => {
                setValue(name, defaultValue);
                setIsEditing(true);
              }}
              hidden={isEditing || isLoading || !canEdit}
              disabled={editDisabled}
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:hover:text-blue-600"
            >
              Change
            </button>
            <button
              hidden={!isEditing || isLoading || !canEdit}
              disabled={isPending}
              type="submit"
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save
            </button>
          </span>
        </dd>
      </div>
    </form>
  );
}
