import { Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React from "react";
import { Customized, Line, LineChart, Rectangle, ReferenceLine, ResponsiveContainer, Text, XAxis, YAxis } from "recharts";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
import { get_tick, useWindowDimensions } from "../../Models/dashBoardUtilities";

type OccurencesByHour = { ocurrences: number; range: [number, number] };
type OcurrencesByDay = Map<number, OccurencesByHour>;
type ErrorType = "Warning" | "Error";
type ErrorOccurence = { date: Moment, type: ErrorType, name: string };
const x_offset = 19;



function GetErrorsByHourForDay(errors: ErrorLogEntry[], startDate: Moment, endDate: Moment, step: number, GetIndex: (date: Moment) => number): OcurrencesByDay {
  ;
  let ticks = get_tick(startDate.valueOf(), endDate.valueOf(), step + 1).reduce((dateOccurrence: OcurrencesByDay, tick) => {
    let date = new Date(tick);
    date.setMinutes(0);
    let current_date = moment(date);

    let idx = GetIndex(current_date);
    let PrevDate = new Date(tick);
    PrevDate.setMinutes(0);

    PrevDate.setMinutes(PrevDate.getMinutes() + 59);
    let prev_date = moment(PrevDate);
    if (!dateOccurrence.get(idx)) {
      dateOccurrence.set(idx, { ocurrences: 0, range: [current_date.valueOf(), prev_date.valueOf()] });
    }
    return dateOccurrence;
  }, new Map());

  return errors
    .filter((elemment) => {
      let date = elemment.date as Date;

      return startDate.valueOf() < date.valueOf() && endDate.valueOf() > date.valueOf();
    })
    .reduce((dateOccurrences: OcurrencesByDay, elemment) => {
      let date = moment(elemment.date as Date);
      let idx = GetIndex(date);

      dateOccurrences.get(idx)!.ocurrences += 1;

      return dateOccurrences;
    }, ticks);
}



function GetErrorOccurences(errors: ErrorLogEntry[], startDate: Moment, endDate: Moment, step: number, GetIndex: (date: Moment) => number): ErrorOccurence[] {
  let occurences: ErrorOccurence[] = [];

  return errors
    .filter((elemment) => {
      let date = elemment.date as Date;

      return startDate.valueOf() < date.valueOf() && endDate.valueOf() > date.valueOf();
    })
    .reduce((dateOccurrences: ErrorOccurence[], element) => {
      let date = moment(element.date as Date);
      let ErrorType: ErrorType = element.ErrorType.startsWith("W") ? "Warning" : "Error";
      dateOccurrences.push({ date: date, type: ErrorType, name: element.errorMessage });

      return dateOccurrences;
    }, occurences);
}

function FilterErrorLogs(errors: ErrorLogEntry[], errorName: string): ErrorLogEntry[] {
  if (errorName === "") {
    return errors;
  }
  return errors.filter((log) => {
    return log.ErrorName === errorName;
  });
}

function GetErrorsSinceYesterDay(errors: ErrorLogEntry[]): ErrorOccurence[] {
  const [step, startDate, endDate] = getFullDayRange();
  ;

  let Ocurrence: ErrorOccurence[] = GetErrorOccurences(errors, moment(startDate), moment(endDate), step, (date) => date.hour());

  return Ocurrence;
}

function getFullDayRange(): [number, Date, Date] {
  let current_date = new Date(Date.now() + 60 * 60 * 1000);
  current_date.setMinutes(0);
  current_date.setSeconds(0);
  let prev_date = new Date();
  prev_date.setDate(prev_date.getDate() - 1);
  prev_date.setMinutes(0);
  prev_date.setSeconds(0);
  let step = 26;

  return [step, prev_date, current_date];
}

export default function ShowTimeErrorBarView({ displayname, errorName, ErrorLogsList }: { displayname: string; errorName: string; ErrorLogsList: ErrorLogEntry[] }): JSX.Element | null {
  let errorLogsFilteredOnName = FilterErrorLogs(ErrorLogsList, errorName);

  let occurences = GetErrorsSinceYesterDay(errorLogsFilteredOnName);


  let [step, start_date, end_date] = getFullDayRange();
  let ticks = get_tick(start_date.valueOf(), end_date.valueOf(), step);


  return (
    <>
      <Grid item xs container style={{ overflow: "hidden" }} direction="column" bgcolor={"#001b54"}>
        <Grid item xs container height={"1vmax"} justifyContent={"center"}>
          <Typography color={"white"}>Up Time Last 24 hours</Typography>
        </Grid>

        <Grid item xs container className="shadow" justifyContent={"center"} alignContent={"center"}>
          <Grid container item xs justifyContent={"start"} alignContent={"center"}>
            <ResponsiveContainer width="100%" height={"100%"}>
              <LineChart
                data={occurences}
                layout="vertical"
                margin={{
                  top: 10,
                  right: 20,
                  bottom: -20,
                  left: -20,
                }}
              >
                <XAxis
                  label={{ value: "days", offset: 15, style: { textAnchor: "middle" }, position: "inssideBottom" }}
                  tickLine={false}
                  axisLine={false}
                  ticks={ticks}
                  type="number"
                  dy={-40}
                  interval={0}
                  tick={{ width: "30px", height: "2vh", fontSize: "0.8em", fill: "white", dy: -35 }}
                  domain={[ticks[0], ticks[ticks.length - 1]]}
                  tickFormatter={(time, idx) => {
                    return moment(time).format("HH:mm");
                  }}
                />
                <YAxis axisLine={false} tickLine={false} type="number" dataKey="centerId" ticks={[0, 2, 4, 6, 8]} tickFormatter={() => ""} />
                {ticks.map((tick, idx) => {
                  let now = moment(tick);
                  if (idx !== 0) {
                    let lastMoment = moment(ticks[idx - 1]);

                    if (lastMoment.days() !== now.days()) {
                      return (
                        <ReferenceLine
                          label={(props) => <DayLabel props={props} day={now.format("DD")}></DayLabel>}
                          strokeWidth={3}
                          stroke="white"
                          segment={[
                            { x: tick, y: 0 },
                            { x: tick, y: 4 },
                          ]}
                        />
                      );
                    }
                  }
                  return (
                    <ReferenceLine
                      stroke="white"
                      segment={[
                        { x: tick, y: 0 },
                        { x: tick, y: 4 },
                      ]}
                    />
                  );
                })}
                <ReferenceLine stroke="white" y={4} />
                <Line></Line>
                <Customized component={(props) => <ErrorBlocks props={props}></ErrorBlocks>} />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
function DayLabel({ props, day }: { props: any; day: string }) {
  const { viewBox } = props;

  return (
    <text x={viewBox.x - 7.5} y={viewBox.height + 40} fill={"white"} fontSize={15}>
      {day}
    </text>
  );
}

// using Customized gives you access to all relevant chart props
function ErrorBlocks({ props }: { props: any }) {
  // get first and second series in chart
  // render custom content using points from the graph

  return <GenerateRecTangles props={props}></GenerateRecTangles>;
}

// function GenerateToolTipBox({ occurencesByHour }: { occurencesByHour: OccurenceByDayWithPosData }): JSX.Element {
//   const { occurences, x, y, width } = occurencesByHour;

//   const dim = useWindowDimensions();
//   let [start_date, end_date] = [moment(occurences.range[0]), moment(occurences.range[1])];
//   return (
//     <Grid container direction={"column"} rowGap={1} sx={{ position: "fixed", zIndex: 200, top: dim.height - dim.height * 0.1 - y - y * (11 / 12), right: dim.width - dim.width * 0.2 - x }} maxWidth={"20%"}>
//       <Grid item container>
//         <Grid item xs container bgcolor={"black"} sx={{ opacity: 0.8 }} borderRadius={"0.5vw"}>
//           <Grid item xs={6}>
//             <Typography color={"white"}> {"start date: "} </Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography color={"white"}>{start_date.format("HH:mm")}</Typography>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Grid item xs container bgcolor={"black"} sx={{ opacity: 0.8 }} borderRadius={"0.5vw"}>
//         <Grid item xs={6}>
//           <Typography color={"white"}> {"end date: "} </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography color={"white"}>{end_date.format("HH:mm")}</Typography>
//         </Grid>
//       </Grid>
//       <Grid item xs container bgcolor={"black"} sx={{ opacity: 0.8 }} borderRadius={"0.5vw"}>
//         <Grid item xs={6}>
//           <Typography color={"white"}> {"occurences: "} </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography color={"white"}>{occurences.ocurrences}</Typography>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

function ConvertDateToXpos(date: number, totalWidth: number): number {
  const [_, startDate, endDate] = getFullDayRange();


  let ratio = (date - startDate.valueOf()) / (endDate.valueOf() - startDate.valueOf());

  return ratio * totalWidth;
}


// function GenerateRecTanglegForEachDay({ props, occurences, setOccurences }: { props: any; occurences: OcurrencesByDay; setOccurences: React.Dispatch<React.SetStateAction<OccurenceByDayWithPosData | null>> }): JSX.Element[] {
//   const {  xAxisMap, yAxisMap } = props;

//   let ticks = get_tick(0, 24, 25);
//   return ticks.map((tick: number, index: number) => {
//     let rectangleWidth = xAxisMap["0"].width / 25;


//     let [x, y] = [xAxisMap["0"].x + tick * rectangleWidth, yAxisMap["0"].height / 2 - 10];
//     return (
//       <Rectangle
//         width={rectangleWidth}
//         height={30}
//         x={x}
//         style={{
//           opacity: 1,
//         }}
//         z={1}
//         onClick={() => {
//           setOccurences((prev) => {
//             if (prev === null) {
//               let sus = { occurences: occurences.get(index)!, width: rectangleWidth, x: x, y: y };

//               return sus;
//             }
//             return null;
//           });
//         }}
//         y={y - 10}
//         fill={"lightblue"}
//       />
//     );
//   });
// }

function GenerateRecTangles({ props }: { props: any }): JSX.Element {
  console.log(props);
  const { data, offset, xAxisMap, yAxisMap } = props;
  const { right } = offset;
  const { width, height } = useWindowDimensions();
  const [Occurence, SetOccurence] = React.useState<{ Occurence: ErrorOccurence, x: number, y: number } | null>(null);
  if (Occurence) {
    let name_length = Occurence.Occurence.name[0].length;

    let text_length = 13 * name_length;
    console.log(Occurence.Occurence.name);
    return <>

      {data.map((error: ErrorOccurence, index: number) => {
        let rectangleWidth = width / 24 / 60;
        let xPos = ConvertDateToXpos(error.date.valueOf(), xAxisMap["0"].width);
        console.log(xAxisMap["0"].width);

        let [x, y] = [xPos - right + xAxisMap["0"].x + x_offset, yAxisMap["0"].height / 2 - 10];

        return (
          <Rectangle

            width={rectangleWidth * 2}
            height={20}
            x={x}
            strokeWidth={4}
            style={{
              opacity: 0.9,
            }}
            onMouseOut={() => {

              SetOccurence(null)
                ;
            }}
            y={y}
            fill={error.type === "Warning" ? "#f2a835" : "#ea3323"}
          />
        );
      })}
    </>
  }

  return data.map((error: ErrorOccurence, index: number) => {
    let rectangleWidth = width / 24 / 60;
    console.log(xAxisMap["0"].width);

    let xPos = ConvertDateToXpos(error.date.valueOf(), xAxisMap["0"].width);

    let [x, y] = [xPos + xAxisMap["0"].x - right + x_offset, yAxisMap["0"].height / 2 - 10];
    return (
      <Rectangle
        width={rectangleWidth * 2}
        height={20}
        x={x}
        strokeWidth={4}
        style={{
          opacity: 0.9,
        }}
        onMouseOver={() => {

          SetOccurence({ Occurence: error, x: x, y: y })
            ;
        }}

        y={y}
        fill={error.type === "Warning" ? "#f2a835" : "#ea3323"}
      />
    );
  });
}
