//implement the stop machine Button

import React, { useEffect } from "react";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
//@ts-ignore
import stopButton from "shared/utils/images/StopButton.png";
export default function StopMachineButton({ sendUpdate, disabled }: { sendUpdate?: () => void, disabled: boolean }): JSX.Element {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  const [MachineStandby, SetMachineStandBy] = React.useState<boolean>(!LatestData.data.MachineStandby);

  function CheckMachineStandby(data: DatedIotData) {
    if (data.data === undefined || data.data.MachineStandby == undefined) {
      return;
    }
    SetMachineStandBy(!data.data.MachineStandby);
  }
  function StopMachine() {
    if (disabled) {
      return;
    }
    Server.SendCommand("SetNavigatorButton", "StopMachine", () => { });
    sendUpdate?.();
  }

  useEffect(() => {
    Server.on_telemtry(CheckMachineStandby);
    return () => {
      Server.off_telemtry(CheckMachineStandby);
    };
  }, []);
  if (MachineStandby) {
    return <></>;
  }
  return <img onClick={StopMachine} src={stopButton} alt="stop" style={{ cursor: disabled ? "default" : "pointer", maxHeight: "2.4vmax", maxWidth: "2.4vmax" }}></img>;
}
