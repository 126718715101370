import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import {
  useMyNotificationPreferencesQuery,
  useUpdateAllNotificationMachineCode,
  useUpdateNotificationMachineCode,
} from "hooks/notification";
import { useUpdateNotificationEmail } from "hooks/notification/useUpdateNotificationEmail";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Notifications() {
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true);
  const [all, setAll] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const { data, isLoading, error } = useMyNotificationPreferencesQuery();
  const { mutate: updateNotificationPreferences, isPending, isError, isSuccess } = useUpdateNotificationMachineCode();
  const { mutate: updateAllNotificationPreferences } = useUpdateAllNotificationMachineCode();
  const { mutate: updateNotificationEmail } = useUpdateNotificationEmail();

  console.log("data", data);
  const [machineCodes, setMachineCodes] = useState([]);

  useEffect(() => {
    if (data) {
      setMachineCodes(data.data.notificationPreferences.MachineCodes);
      setAll(data.data.notificationPreferences.pushNotification);
      setEmailNotifications(data.data.notificationPreferences.emailNotification);
    }
  }, [data]);

  const switchEmailNotification = () => {
    setEmailNotifications(!emailNotifications);

    updateNotificationEmail({
      emailNotification: !emailNotifications,
    });
  };

  const switchMachineCode = (MachineCodeId, inUse, index) => {
    let newArr = [...machineCodes];
    newArr[index].NotificationMachineCode.inUse = inUse;

    setMachineCodes(newArr);

    updateNotificationPreferences({
      id: MachineCodeId,
      inUse: inUse,
    });
  };

  const switchAll = () => {
    let newArr = [...machineCodes]; // copying the old datas array

    newArr.forEach((machineCode) => {
      machineCode.NotificationMachineCode.inUse = !all;
    });

    setMachineCodes(newArr);
    setAll(!all);
    updateAllNotificationPreferences({ allNotifications: !all });
    // updateAllOwnNotificationMachineCode({ allNotifications: !all })
  };

  return (
    <>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Email notifications</h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Receive an email when an error occurred on one of your machines.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <Switch.Group as="div" className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <div className="flex flex-col">
                <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                  Machine errors and warnings
                </Switch.Label>
                <p className="text-xs text-gray-500 mt-1">Receive email notifications for all errors and warnings.</p>
              </div>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <Switch
                  checked={emailNotifications}
                  onChange={switchEmailNotification}
                  className={classNames(
                    emailNotifications ? "bg-blue-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      emailNotifications ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
          </dl>
        </div>
      </div>

      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Push notifications</h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Receive push notifications on your mobile devices if you are using our mobile app. Here you can enable or disable the push notifications for
            specific errors and warnings.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            {isLoading ? (
              <div className="flex justify-center">
                <div className="mt-4 animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-400 border-t-transparent"></div>
              </div>
            ) : (
              <>
                <Switch.Group key={"all"} as="div" className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5 sm:pt-5">
                  <div className="flex flex-col">
                    <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                      All notifications
                    </Switch.Label>
                    <p className="text-xs text-gray-500 mt-1">All errors and warnings</p>
                  </div>
                  <dd className="mt-1 flex items-center text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    <Switch
                      checked={all}
                      onChange={() => {
                        switchAll();
                      }}
                      className={classNames(
                        all ? "bg-blue-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          all ? "translate-x-5" : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </dd>
                </Switch.Group>
                {data?.data?.notificationPreferences?.MachineCodes.map((machineCode, index) => (
                  <Switch.Group
                    key={machineCode.id}
                    as="div"
                    className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-5 sm:pt-5"
                  >
                    <div className="flex flex-col">
                      <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                        {machineCode.name}
                      </Switch.Label>
                      <p className="text-xs text-gray-500 mt-1">{machineCode.description}</p>
                    </div>
                    <dd className="mt-1 flex items-center text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                      <Switch
                        disabled={!all}
                        checked={machineCode.NotificationMachineCode.inUse}
                        onChange={() => {
                          switchMachineCode(machineCode.id, !machineCode.NotificationMachineCode.inUse, index);
                        }}
                        className={classNames(
                          machineCode.NotificationMachineCode.inUse ? "bg-blue-600" : "bg-gray-200",
                          "disabled:opacity-40 disabled:cursor-not-allowed relative inline-flex h-6 w-11 flex-shrink-0  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            machineCode.NotificationMachineCode.inUse ? "translate-x-5" : "translate-x-0",
                            "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </dd>
                  </Switch.Group>
                ))}
              </>
            )}
          </dl>
        </div>
      </div>
    </>
  );
}
