import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { useChangeMachineRoom } from "../../../hooks/machine";
import { useRoomsByFactoryIdQuery } from "../../../hooks/factory/useRoomsByFactoryIdQuery";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function ChangeRoomField({
  name,
  defaultValue,
  type,
  required,
  isLoading,
  machineData,
  editDisabled = false,
  canEdit = true,
  factoryId,
}) {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [isEditing, setIsEditing] = useState(false);

  const { data: roomsData, isLoading: roomsIsLoading } =
    useRoomsByFactoryIdQuery(factoryId);

  const {
    mutate: changeMachineRoom,
    isPending,
    isError,
    isSuccess,
  } = useChangeMachineRoom();

  const onSubmit = (data) => {
    clearErrors();
    console.log("dataonSubmit", data);
    // if (required && (!data[name] || !data[name].trim())) {
    //   setError(name, { type: "required", message: name + " is required" });
    //   return;
    // }

    let newData = {RoomId: data.Room.id, MachineId: machineData.id};

    // switch (name) {
    //   case "Alias":
    //     newData = {
    //       name: data[name],
    //       MachineId: machineData.id,
    //     };
    //     break;
    //   default:
    //     break;
    // }
    changeMachineRoom(newData, {
      onSuccess: () => {
        queryClient.setQueryData(
          ["machine", machineData.serialNumber, "details"],
          (oldData) => {
            console.log("oldData", oldData);
            console.log("newData", newData);
            return {
              ...oldData,
              data: {
                ...oldData.data,
                machine: [
                  { ...oldData.data.machine[0], ...newData },
                  ...oldData.data.machine.slice(1),
                ],
              },
            };
          },
        );
        queryClient.invalidateQueries({
          queryKey: ["machine", machineData.serialNumber, "details"],
        });
        setIsEditing(false);
      },
      onError: (error) => {
        setError(name, { type: "manual", message: "Please check your input" });
      },
    });
  };

  console.log("defaultValue", defaultValue);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ${!isEditing ? "sm:py-6" : "sm:py-4"}`}
      >
        <dt className="self-center text-sm font-medium text-gray-900">
          {name}
        </dt>
        <dd
          className={`mt-1 flex space-x-4 text-sm text-gray-700 sm:col-span-2 sm:mt-0`}
        >
          <span className="flex-grow self-center">
            {isLoading ? (
              <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
            ) : !isEditing ? (
              <>{defaultValue.name || " "}</>
            ) : (
              <div className="relative sm:w-2/3">
                <Controller
                  name="Room"
                  control={control}
                  defaultValue={defaultValue.name}
                  rules={{ required: "Room is required" }} // Add validation rule
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <RoomsCombobox
                        {...field}
                        defaultRoom={defaultValue}
                        rooms={roomsData?.data?.rooms}
                      />
                      {/* {error && (
                        <p className="mt-2 text-sm text-red-600">
                          {error.message}
                        </p>
                      )} */}
                    </>
                  )}
                />
                {/* <input
                  id={name}
                  name={name}
                  type={type}
                  autoComplete={autoComplete}
                  required={required}
                  defaultValue={defaultValue}
                  disabled={isPending}
                  {...register(name, { required: required })}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400  focus:ring-2 focus:ring-inset sm:w-full ${
                    errors[name]
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                /> */}
                <p
                  className="mt-2 text-sm text-red-600"
                  id="email-error"
                  hidden={!errors[name]}
                >
                  {errors[name]?.message}
                </p>
                {isPending && (
                  <div
                    className="absolute right-8  top-1.5 inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            )}
          </span>

          <span className="ml-4 flex-shrink-0 self-center">
            <button
              type="button"
              onClick={() => {
                setValue(name, defaultValue);
                setIsEditing(true);
              }}
              hidden={isEditing || isLoading || !canEdit}
              disabled={editDisabled}
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:hover:text-blue-600"
            >
              Change
            </button>
            <div className="flex space-x-2 ">
            <button
              hidden={!isEditing || isLoading || !canEdit}
              disabled={isPending}
              type="submit"
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save
            </button>
            <button
              type="button"
              hidden={!isEditing || isLoading || !canEdit}
              className="text-red-600 hover:text-red-900"
              onClick={() => {
                setIsEditing(false);
                clearErrors();
              }}
            >
              Cancel
            </button>
            </div>
          </span>
        </dd>
      </div>
    </form>
  );
}

function RoomsCombobox({ rooms, value, onChange, defaultRoom }) {
  const [query, setQuery] = useState("");
  const [selectedRoom, setSelectedRoom] = useState(defaultRoom);

  console.log("value", value);
  console.log("rooms", rooms);
  const filteredRooms =
    query === ""
      ? rooms
      : rooms.filter((room) => {
          return room.name.toLowerCase().includes(query.toLowerCase());
        });
  console.log("filteredRooms", filteredRooms);

  return (
    <Combobox
      as="div"
      value={selectedRoom}
      onChange={(room) => {
        console.log("roomCombobox", room);
        setQuery("");
        setSelectedRoom(room);
        onChange(room);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(room) => room?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredRooms.length > 0 && (
          <ComboboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredRooms.map((room) => (
              <ComboboxOption
                key={room.id}
                value={room}
                className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {room.name}
                </span>

                <span className="absolute inset-y-0 left-0 hidden items-center pl-1.5 text-blue-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
