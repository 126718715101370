import { useState, useEffect } from "react";
import { Field, Label, Switch } from "@headlessui/react";

export default function MeasurementSwitch() {
  const [isMetricEnabled, setIsMetricEnabled] = useState(true);

  useEffect(() => {
    const storedUnitSystem = localStorage.getItem("unitSystem");
    if (storedUnitSystem) {
      setIsMetricEnabled(storedUnitSystem === "metric");
    }
  }, []);

  const handleUnitSystemChange = (enabled) => {
    setIsMetricEnabled(enabled);
    localStorage.setItem("unitSystem", enabled ? "metric" : "imperial");
  };

  return (
    <Field className="grid grid-cols-2 py-4 pt-6 sm:gap-4">
      <Label
        as="dt"
        passive
        className="self-center text-sm font-medium text-gray-500"
      >
        Measurement System
      </Label>
      <dd className="flex flex-auto items-center justify-end">
        <span className="text-sm text-gray-900 mr-6">{isMetricEnabled ? "Metric" : "Imperial"} </span>
        <Switch
          checked={isMetricEnabled}
          onChange={handleUnitSystemChange}
          className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 data-[checked]:bg-blue-600"
        >
          <span
            aria-hidden="true"
            className="size-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
          />
        </Switch>
      </dd>
    </Field>
  );
}
