import { useMutation } from "@tanstack/react-query";
import { answerInvitation } from "../../api/invitation.service";

export const useAnswerInvitation = () => {
  return useMutation({
    mutationFn: (data) => {
      return answerInvitation(data.InvitationId, { answer: data.answer });
    },
  });
};
