import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { CircularProgress, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { Get_Keys_Display_Name } from "../../Models/ServerCommuncation/Settings";
import { DatedIotData } from "../../Models/ServerCommuncation/serverInterFace";
import { TelemtricView as TelemetricViewer } from "../uiElements/noninteractive/TelemtryView.tsx/telemtricview";
import { HasNoDetailedView, MapNameToUnit, ParseTelemtryNames, convertTelemtryNameToEncoding } from "./DashBoardParsing";
import { convertNameToEncoding, convertValueToEncoding, useEncodingState } from "pages/Navigator/Models/DataEncoding";
import { useWindowDimensions } from "pages/Navigator/Models/dashBoardUtilities";
import { TableContainer, Table, TableHeader, TableRow, TableCellHeader, TableCellBody, TableBody } from "./DashBoardTable/DashBoardTable";


export default function TelemtryDataView() {
  const Server = GetServer();

  const [telemetryData, setTelemetryData] = React.useState<DatedIotData[]>([]);
  const [currentTelemetryData, SetCurrentTelemtryData] = React.useState<DatedIotData | null>(null);

  const [searchText, SetSearchText] = React.useState<string>();
  const [keys, SetKeys] = React.useState<string[]>([]);

  const setData = (data: DatedIotData[]) => {
    setTelemetryData((_) => {
      SetCurrentTelemtryData(data[data.length - 1]);

      return data;
    });
  };
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);

    return () => {
      Server.off_telemtry_last_10_min(setData);
    };
  });
  useEffect(() => {
    if (telemetryData.length !== 0) {
      let keys = Get_Keys_Display_Name(telemetryData[0].data, searchText);
      SetKeys(keys);
    }
  }, [telemetryData, searchText]);
  if (currentTelemetryData === null) {
    return (
      <Grid item container direction={"column"} justifyContent={"center"} alignContent={"center"}  >

        <CircularProgress size={"5vmax"} />
      </Grid>
    );
  }
  return (
    <>


      <TableContainer>
        <Table>

          <TableHeader >
            <TableRow >
              <TableCellHeader colspan={4} >
                <TextField
                  size="small"
                  sx={{ bgcolor: "white" }}
                  label="Search"
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    SetSearchText(event.target.value);
                  }}
                ></TextField>
              </TableCellHeader>
            </TableRow>
            <TableRow >
              <TableCellHeader >

                <Typography variant="button" fontSize={"0.7vmax"}>Name</Typography>
              </TableCellHeader>
              <TableCellHeader  >
                <p className="text-black text-[1em] tracking-wide	 font-semibold justify-center flex">
                  Value
                </p>


              </TableCellHeader >
              <TableCellHeader  >

                <p className="text-black text-[1em] tracking-wide	 font-semibold justify-center flex">
                  Unit
                </p>
              </TableCellHeader>
              <TableCellHeader>
              </TableCellHeader>
            </TableRow>
          </TableHeader>

          <TableBody>
            {keys.map((key) => {
              return <TelemetricView telemetryKey={key} telemetryData={telemetryData} backgroundColor="white"></TelemetricView>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
function IsFlag(name: string): boolean {
  return name === "FlowSw"
}
function FlagView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const HasFlow = data >= 300;
  const unit = MapNameToUnit(telemetryKey);
  return (
    <>
      <TableCellBody>
        <p className="text-black text-[1em] tracking-wide	 font-semibold justify-start flex">
          {displayName}
        </p>
      </TableCellBody>
      <TableCellBody style={{ backgroundColor: HasFlow ? "#13fB04" : "#b91d1d" }}>
      </TableCellBody>
      <TableCellBody>
        <p className="text-black text-[1em] tracking-wide	 font-semibold justify-start flex">
          {unit}
        </p >
      </TableCellBody>
      <TableCellBody></TableCellBody>
    </>
  );
}


function TelemetricView({ telemetryKey, telemetryData, backgroundColor }: { telemetryKey: string; telemetryData: DatedIotData[]; backgroundColor: string }) {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  const displayName = ParseTelemtryNames(telemetryKey);
  const HasNoDetailed = HasNoDetailedView(telemetryKey);

  const flag = IsFlag(telemetryKey);
  if (displayName === undefined) {
    return null;
  }
  if (flag) {
    return (
      <TableRow>

        <FlagView telemetryKey={telemetryKey} telemetryData={telemetryData}></FlagView>
      </TableRow>
    );
  }

  if (HasNoDetailed) {
    return (
      <TableRow>

        <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>

      </TableRow>
    );
  }
  if (!ShowDetailed) {
    return (
      <>
        <TableRow>
          <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
          <TableCellBody  >
            <IconButton
              onClick={() => {
                SetShowDetailed((prev: boolean) => !prev);
              }}
            >
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </IconButton>
          </TableCellBody>
        </TableRow>
      </>

    );
  }

  return (<>
    <TableRow>
      <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
      <TableCellBody >
        <IconButton
          onClick={() => {
            SetShowDetailed((prev: boolean) => !prev);
          }}
        >
          <KeyboardArrowUpIcon></KeyboardArrowUpIcon>

        </IconButton>
      </TableCellBody>
    </TableRow>
    <TableRow>
      <DetailedView telemetryKey={telemetryKey} telemetryData={telemetryData}></DetailedView>
    </TableRow>
  </>

  );
}

function NormalView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const { encoding } = useEncodingState();
  const parsedValue_local = data;
  const local_unit = MapNameToUnit(telemetryKey);
  const displayName = convertTelemtryNameToEncoding(encoding, ParseTelemtryNames(telemetryKey));

  const unit = convertNameToEncoding(encoding, local_unit);
  const parsedValue = convertValueToEncoding(encoding, parsedValue_local, local_unit);

  return (<>
    <TableCellBody >

      <p className="text-black text-[1em] tracking-wide	 font-semibold justify-start flex">
        {displayName}
      </p>
    </TableCellBody>
    <TableCellBody>
      <p className="text-black text-[1em] tracking-wide	  font-semibold justify-center flex">
        {parsedValue}

      </p>

    </TableCellBody>
    <TableCellBody>
      <p className="text-black text-[1em] tracking-wide	  font-semibold justify-center flex">
        {unit}
      </p>
    </TableCellBody>
  </>
  );
}

function DetailedView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }): JSX.Element {
  return (<TableCellBody colspan={4} > <Table>
    <TableBody>
      <TableRow>
        <TableCellBody>
          <TelemetricViewer telemetryKey={telemetryKey} telemetryData={telemetryData}></TelemetricViewer>
        </TableCellBody>
      </TableRow>
    </TableBody>
  </Table>
  </TableCellBody>);
}
