import React from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TiInfoLarge } from "react-icons/ti";
import { VscDashboard } from "react-icons/vsc";
import { LuFileWarning } from "react-icons/lu";
import { LuHardDriveDownload } from "react-icons/lu";
import { GiFoam } from "react-icons/gi";
import { MdOutlineCast, MdWifiOff, MdWifi, MdEdit } from "react-icons/md";
import { usePingMachine } from "hooks/machine";

import Breadcrumbs from "components/Breadcrumbs";
import { useMachineBySNQuery } from "hooks/machine/useMachineBySNQuery";
import { useFactoryByIdQuery } from "hooks/factory";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewMachine(props) {
  let { factoryId, serialNumber } = useParams();
  factoryId = parseInt(factoryId);

  const location = useLocation();
  let navigate = useNavigate();

  const {
    data: machineData,
    isLoading: machineLoading,
    error: machineError,
  } = useMachineBySNQuery(serialNumber);
  const {
    data: pingData,
    error: pingError,
    isFetching: pingIsFetching,
  } = usePingMachine(serialNumber);
  console.log("machineData", machineData);

  let type = "none";
  if (machineData?.data?.machine[0]?.type.includes("FOAMATIC"))
    type = "foamatic";

  const tabs = [
    {
      name: "Details",
      url: "details",
      icon: TiInfoLarge,
      current: true,
      visible: true,
    },
    {
      name: "Telemetry",
      url: "telemetry",
      icon: VscDashboard,
      current: false,
      visible: true,
    },
    // { name: "Alerts", url: "alerts", icon: HiOutlineBellAlert, current: false },
    {
      name: "Error log",
      url: "error-log",
      icon: LuFileWarning,
      current: false,
      visible: true,
    },
    {
      name: "Software Update",
      url: "settings",
      icon: LuHardDriveDownload,
      current: false,
      visible: true,
    },
    {
      name: "Foamatic",
      url: "foamatic",
      icon: GiFoam,
      current: false,
      visible: type === "foamatic" ? true : false,
    },
    //   { name: "Rooms", href: "#", icon: CreditCardIcon, current: false },
  ].map((item) => ({
    ...item,
    current: location.pathname.includes(item.url),
  }));

  //check if any tabs are visible, if they are not, remove them from the tabs array

  const {
    data: factoryData,
    isLoading,
    error,
  } = useFactoryByIdQuery(factoryId);

  console.log("factoryData", factoryData);

  if (error) {
    navigate("/internal-server-error", { replace: true });
  }

  const pages = [
    { name: "Factories", url: "/commander", current: false },
    {
      name: factoryData?.data?.factory?.name,
      url: `/commander/${factoryId}/view/details`,
      current: false,
    },
    {
      name: machineData?.data?.machine[0]?.name,
      url: `/commander/${factoryId}/machine/${serialNumber}/details`,
      current: true,
    },
  ];
  return (
    <>
      <div className="mx-auto max-w-7xl">
        <Breadcrumbs pages={pages} />
        <div className="mx-2 mb-5 mt-5 sm:mb-4 sm:mt-4 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex items-center space-x-2">
              {pingIsFetching ? (
                <LoadingSpinner />
              ) : pingData?.data?.connection ? (
                <MdWifi className="text-green-500" size={28} />
              ) : (
                <MdWifiOff className="text-red-500" size={28} />
              )}
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                {machineLoading ? (
                  <div className="h-9 w-1/2 animate-pulse rounded bg-slate-200"></div>
                ) : (
                  machineData?.data?.machine[0]?.name
                )}
              </h2>
            </div>
          </div>
        </div>

        <div className=" mb-4">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-blue-400 focus:ring-blue-400"
              defaultValue={
                tabs.find((tab) => tab.current)?.name || tabs[0].name
              }
              onChange={(e) => {
                const selectedTab = tabs.find(
                  (tab) => tab.name === e.target.value,
                );
                if (selectedTab) {
                  navigate(selectedTab.url); // Navigate to the selected tab's URL
                }
              }}
            >
              {tabs.map(
                (tab) =>
                  tab.visible && <option key={tab.name}>{tab.name}</option>,
              )}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map(
                  (tab) =>
                    tab.visible && (
                      <Link
                        key={tab.name}
                        to={tab.url}
                        className={classNames(
                          tab.current
                            ? "border-blue-400 text-blue-500"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        <tab.icon
                          className={classNames(
                            tab.current
                              ? "text-blue-400"
                              : "text-gray-400 group-hover:text-gray-500",
                            "-ml-0.5 mr-2 h-5 w-5",
                          )}
                          aria-hidden="true"
                        />
                        <span>{tab.name}</span>
                      </Link>
                    ),
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Outlet
        context={{
          factoryDetails: factoryData?.factory,
          loading: isLoading,
          machineData: machineData?.data?.machine[0],
          machineLoading: machineLoading,
        }}
      />
    </>
  );
}

const LoadingSpinner = () => (
  <div
    className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
    role="status"
    aria-label="loading"
  >
    <span className="sr-only">Loading...</span>
  </div>
);
