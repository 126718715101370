import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import { BellIcon } from "@heroicons/react/24/outline";
import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useGetInvitationsQuery } from "hooks/invitation";
import { useAnswerInvitation } from "../hooks/invitation/useAnswerInvitation";
import { useQueryClient } from "@tanstack/react-query";
import {
  getSocket,
  joinRoom,
  leaveRoom,
} from "../shared/services/socket.service";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

export default function NotificationBellDropdown() {
  const queryClient = useQueryClient();
  const myAccount = useSelector((state) => state.user.user);
  const { data, isLoading } = useGetInvitationsQuery();

  const invitations = data?.data.invitations || [];

  React.useEffect(() => {
    joinRoom("invitation-" + myAccount.uuid);
    let socket = getSocket();
    socket.on("new-invitation", (data) => {
      queryClient.invalidateQueries({
        queryKey: ["invitations"],
      });

      enqueueSnackbar(
        <div>
          <p className="text-sm font-medium text-gray-900">New Invitation!</p>
          <p className="mt-1 text-sm text-gray-500">
            {data?.notification?.body || "You have received a new invitation"}
          </p>
        </div>,
        { variant: "invitation" },
      );
    });
    return () => {
      leaveRoom("invitation-" + myAccount.uuid);
    };
  }, []);

  return (
    <Popover className="relative">
      <PopoverButton
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: ["invitations"],
          });
         
        }}
        className="relative rounded-full   p-1 text-blue-200 hover:text-white focus:outline-none"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon aria-hidden="true" className="h-6 w-6" />
        {invitations && invitations.length > 0 && (
          <span className="absolute right-0 top-0 block h-2 w-2 rounded-full bg-red-600 ring-2 ring-white" />
        )}
      </PopoverButton>

      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <div className="p-4">
            {isLoading ? (
              <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
            ) : invitations && invitations.length > 0 ? (
              invitations.map((item) => (
                <Invitation key={item.id} invitation={item} />
              ))
            ) : (
              <div className="text-center text-gray-500">No invitations</div>
            )}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}

function Invitation({ invitation }) {
  const queryClient = useQueryClient();
  const { mutate: answerInvitation, isPending } = useAnswerInvitation();

  const handleAccept = () => {
    answerInvitation(
      {
        InvitationId: invitation.id,
        answer: "accept",
      },
      {
        onSuccess: (data) => {
          //remove the invitation from the list
           queryClient.setQueryData(
            ["invitations"],
            (oldData) => {
              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  invitations: oldData.data.invitations.filter(
                    (item) => item.id !== invitation.id,
                  ),
                },
              };
            },
            { exact: true },
          );
        },
      },
    );
  };
  const handleDecline = () => {
    answerInvitation(
      {
        InvitationId: invitation.id,
        answer: "decline",
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            ["invitations"],
            (oldData) => {
              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  invitations: oldData.data.invitations.filter(
                    (item) => item.id !== invitation.id,
                  ),
                },
              };
            },
            { exact: true },
          );
        },
      },
    );
  };

  console.log("invitation", invitation);
  return (
    <>
      <div className="p-4">
        <div className="flex items-start">
          <div className="ml-3 w-0 flex-1">
            <p className="text-sm font-medium text-gray-900">
              Invitation to join <b>{invitation?.Factory?.name || "-"}</b>
            </p>
            <p className="mt-1 text-sm text-gray-500">
              You have received an invitation to join{" "}
              <b>{invitation?.Factory?.name || "-"}</b> as a{" "}
              <b>{invitation?.role || "-"}</b>
            </p>
            <div className="mt-4 flex items-center">
              <button
                type="button"
                disabled={isPending}
                className="inline-flex items-center rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-70"
                onClick={() => handleAccept()}
              >
                Accept
              </button>
              <button
                type="button"
                disabled={isPending}
                className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-70"
                onClick={() => handleDecline()}
              >
                Decline
              </button>
              {isPending && (
                <div
                  className="ml-2 hidden size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 sm:inline-block dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
          {/* <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              onClick={() => {
                //     setShow(false);
              }}
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
