import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorAlert from "components/ErrorAlert";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useResetPassword } from "hooks/user";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

// const validationSchema = yup.object({
//   newPassword: yup.string("Enter new password").required("New password is required").min(6, "Password too short!").uppercase().lowercase().number().specialChar(),
//   repeatNewPassword: yup.string("Repeat the new password").required("Repeating the new password is required"),
// });

const ResetPassword = () => {
  const navigate = useNavigate();
  let params = useParams();

  const {
    mutate: resetPasswordMutation,
    isPending,
    isError,
    isSuccess,
    error,
  } = useResetPassword();
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setPasswordError(false);
    setConfirmPasswordError(false);

    const formData = new FormData(e.target);
    const password = formData.get("password");
    const repeatPassword = formData.get("repeat-password");

    const strongPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!strongPassword.test(password)) {
      setPasswordError(true);
      return;
    }

    if (password !== repeatPassword) {
      setConfirmPasswordError(true);
      return;
    }

    const values = {
      newPassword: password,
      repeatNewPassword: repeatPassword,
      token: params.token,
    };
    resetPasswordMutation(values);
  };

  if (isSuccess) {
    document.getElementById("reset-password").reset();
    navigate("/reset-password-successfully");
  }

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center bg-nilfisk py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Change your password
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img
            className="mx-auto mb-6 w-auto"
            src={nilfiskFoodLogo}
            alt="Nilfisk Food"
          />
          <form className="space-y-6" id="reset-password" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                {/* <!-- Strong Password --> */}
                <div class="max-w-sm">
                  <div class="flex">
                    <div class="relative flex-1">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className={`block w-full rounded-md px-4  py-3 text-sm  ${
                          passwordError
                            ? " border-red-500 focus:border-red-500 focus:ring-red-500"
                            : " border-gray-200 focus:border-blue-500 focus:ring-blue-500"
                        } disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 dark:focus:ring-gray-600`}
                        placeholder="Enter password"
                      />
                      <div
                        id="hs-strong-password-popover"
                        class="absolute z-10 hidden w-full rounded-lg bg-white p-4 shadow-md dark:divide-gray-700 dark:border dark:border-gray-700 dark:bg-gray-800"
                      >
                        <div
                          id="hs-strong-password-in-popover"
                          data-hs-strong-password='{
            "target": "#password",
            "hints": "#hs-strong-password-popover",
            "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1",
            "mode": "popover"
          }'
                          class="-mx-1 mt-2 flex"
                        ></div>

                        <h4 class="mt-3 text-sm font-semibold text-gray-800 dark:text-white">
                          Your password must contain:
                        </h4>

                        <ul class="space-y-1 text-sm text-gray-500">
                          <li
                            data-hs-strong-password-hints-rule-text="min-length"
                            class="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                          >
                            <span class="hidden" data-check>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Minimum number of characters is 6.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="lowercase"
                            class="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                          >
                            <span class="hidden" data-check>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain lowercase.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="uppercase"
                            class="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                          >
                            <span class="hidden" data-check>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain uppercase.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="numbers"
                            class="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                          >
                            <span class="hidden" data-check>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain numbers.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="special-characters"
                            class="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                          >
                            <span class="hidden" data-check>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                class="size-4 flex-shrink-0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain special characters.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Strong Password --> */}
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Repeat Password
              </label>
              <div className="mt-2">
                <input
                  id="repeat-password"
                  name="repeat-password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="Repeat password"
                  className={`block w-full rounded-md px-4  py-3 text-sm  ${
                    confirmPasswordError
                      ? " border-red-500 focus:border-red-500 focus:ring-red-500"
                      : " border-gray-200 focus:border-blue-500 focus:ring-blue-500"
                  } disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 dark:focus:ring-gray-600`}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                {isPending && (
                  <div className="">
                    <div className="mr-4 h-6 w-6 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-300 border-t-transparent"></div>
                  </div>
                )}
                Change password
              </button>
            </div>
            {confirmPasswordError ? (
              <ErrorAlert text="Passwords do not match!" />
            ) : null}
            {passwordError ? (
              <ErrorAlert text="Password is not strong enough!" />
            ) : null}
            {isError || error ? (
              <ErrorAlert text="Error changing password" />
            ) : null}
            {isSuccess && (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="size-5 text-green-400"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Password changed successfully!
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* {invalidEmailError ? <ErrorAlert text="Email is invalid!" /> : null}
            {hasPassError ? <ErrorAlert text="Email or password is incorrect!" /> : null}
            {hasConnError ? <ErrorAlert text="There is a connection error." /> : null} */}
          </form>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
