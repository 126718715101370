import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
import { ErrorPopUps } from "../../Models/Errorlog/ErrorPopUps";
import LeftButton from "../uiElements/interactive/LeftButton";
import RightButton from "../uiElements/interactive/RightButton";
import { GetServer } from "pages/Navigator/Models/ServerCommuncation/Server";

function getErrorEntryColor(type: string): string {
  if (type.startsWith("W")) {
    return "#f2a835";
  }
  return "#ea3323"
}
export default function ErrorPopUp({ errorPopUpModel, disabled }: { errorPopUpModel: ErrorPopUps, disabled: boolean }) {
  const Server = GetServer();
  function PopErrorOnMachine() {
    Server.SendCommand("SetButtonPopUp", null, () => {

    });
  }
  const [ErrorEntry, SetErrorEntry] = React.useState(errorPopUpModel.GetCurrentError());
  if (!ErrorEntry) {
    return <></>
  }
  return (

    <Grid container direction={"row"} bgcolor={getErrorEntryColor(ErrorEntry.ErrorType)} height={"20vmax"}>
      <Grid item xs={1} container alignContent={"center"}>

        <Grid item>
          {errorPopUpModel.CanGoLeft() ? <LeftButton disabled={disabled} onclickHandler={() => SetErrorEntry(errorPopUpModel.GetPrevErrorLog())}></LeftButton> : null}</Grid>
      </Grid>
      <Grid container direction={"column"} xs={10}>
        <Grid container direction={"column"} justifyContent="center" >
          <Grid item container borderRadius={"0.5vw"} paddingBottom={"2vmax"}>
            <ShowError error={ErrorEntry}></ShowError>

          </Grid>

          <ShowCauses error={ErrorEntry}></ShowCauses>
          <ShowRemedies error={ErrorEntry}></ShowRemedies>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center"   >
          <Button onClick={() => {
            errorPopUpModel.PopErrors()
            PopErrorOnMachine();
          }}><Typography color={"white"}>OK</Typography></Button>
        </Grid>
      </Grid>
      <Grid item xs={1} container alignContent={"center"}>

        <Grid item> {errorPopUpModel.CanGoRight() ? <RightButton disabled={disabled} onclickHandler={() => SetErrorEntry(errorPopUpModel.GetNextErrorLog())}></RightButton> : null}</Grid>
      </Grid>
    </Grid >
  );
}
function ShowError({ error }: { error: ErrorLogEntry }): JSX.Element {
  let date = error.date as Date;
  return (
    <>
      <Grid container item alignContent={"center"} justifyContent={"start"} xs={5} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" alignItems={"center"} color="white" overflow="hidden">
          {`${moment(date).format("hh:mm:ss")}`}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignContent={"center"}>
        <Typography variant="button" alignItems={"center"} textAlign={"end"} color="white" overflow="hidden">
          {`  ${error.errorMessage} `}
        </Typography>
      </Grid>

    </>
  );
}


function ShowCauses({ error }: { error: ErrorLogEntry }): JSX.Element | null {

  return (
    <Grid item container justifyContent="center" borderRadius={"0.5vw"} minWidth={"100%"} paddingBottom={"3vmax"}>
      <Grid item xs={2} container alignContent={"center"}>
        <Typography variant="button" color={"white"} fontSize={"1vmax"}>
          {error.causes.length > 1 ? "Causes" : "Cause"}
        </Typography>
      </Grid>
      <Grid item xs={10} container direction={"column"} justifyContent={"center"}>
        <MapMessages messages={error.causes}></MapMessages>

      </Grid>
    </Grid>
  );
}

function MapMessages({ messages }: { messages: string[] }): JSX.Element {

  if (messages.length === 0) {
    return <></>
  }
  if (messages.length === 1) {
    return <Grid item container sx={{ paddingLeft: "0.5vw" }} alignContent={"center"}>
      <Typography fontSize={"0.8vmax"}> {`${messages[0]}`}</Typography>
    </Grid>
  }
  return (<>
    {messages.map((message: string, index) => {

      if (message === "") {
        return <></>;
      }
      return (
        <Grid item container sx={{ paddingLeft: "0.5vw" }} alignContent={"center"} >
          <Typography fontSize={"0.8vmax"}> {`${index + 1}.  ${message}`} </Typography>
        </Grid>
      );
    })} </>);
}

function ShowRemedies({ error }: { error: ErrorLogEntry }): JSX.Element | null {


  return (

    <Grid item container justifyContent="center" borderRadius={"0.5vw"} minWidth={"100%"} paddingBottom={"3vmax"}>
      <Grid item xs={2} container alignContent={"center"}>
        <Typography fontSize={"1vmax"} variant="button" color={"white"}>
          {error.remedies.length > 1 ? "Remedies" : "Remedy"}
        </Typography>
      </Grid>
      <Grid item xs={10} container direction={"column"} justifyContent={"center"}>
        <MapMessages messages={error.remedies}></MapMessages>

      </Grid>
    </Grid>
  );
}
// cant remember use case :)

function parseMessage(val: string): JSX.Element {
  let values = val.split(/(?=\()/g);

  if (values.length === 0) {
    return (
      <Typography fontSize={"0.5vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
        {val}
      </Typography>
    );
  }
  return (
    <>
      {values.map((val, idx) => {
        if (idx === values.length - 1) {
          return (
            <Typography fontSize={"0.5vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
              {val}
            </Typography>
          );
        }
        return (
          <>
            <Typography fontSize={"0.5vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
              {val}
            </Typography>
            <br></br>
          </>
        );
      })}
    </>
  );
}