import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useRemoveUserFromFactory } from "hooks/factory";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useRemoveMachine } from "hooks/machine/useRemoveMachine";

export default function RemoveMachineDialog({
  open,
  setOpen,
  machineId,
  machineName,
  serialNumber,
  factoryId
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    mutate: removeMachine,
    isPending,
    isError,
    isSuccess,
  } = useRemoveMachine();

  const handleRemoveMachine = () => {
    console.log("machineId", machineId);
    removeMachine(
      { MachineId: machineId },
      {
        onSuccess: () => {
          enqueueSnackbar("Machine removed successfully", {
            variant: "success",
          });
          setOpen(false);
          navigate(`/commander/${factoryId}/view/machines`);
          
        },
        onError: (error) => {
          console.log("error", error.response);
          handleSnackbarError(error);
        },
      },
    );
  };

  const handleSnackbarError = (error) => {
    const errorMsg =
      error?.response?.data?.msg ||
      "An error occurred while removing the machine. Please try again later.";
      console.log("errorMsg", error?.response);
    enqueueSnackbar(
      <div>
        <p className="text-sm font-bold">Error</p>
        <p className="mt-1 text-sm">{errorMsg}</p>
      </div>,
      {
        variant: "error",
        autoHideDuration: 4000,
      },
    );
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-red-600"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Remove machine "{machineName}"
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to remove the machine with the serial
                    number: <span className="font-bold">"{serialNumber}"</span>?
                    The machine will no longer be available in the factory.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 items-center sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => {
                  handleRemoveMachine();
                }}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Remove
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
              {isPending && (
                <div
                  className="mx-3 hidden size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 sm:inline-block dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
