import { GetServer } from "./ServerCommuncation/Server";

interface Properties {
  display_name: string;
  type?: string;
  UpdateValue?: string;
  field_val?: string;
  gauge_type?: string;
  SettingValue?: string;
  image_file?: string;
  telemtry_field?: string;
  field_unit?: string;
  childType?: string;
  selected?: { val: number; telemtry_field: string };
  childCommand?: Command;
  metrics?: Metric[];
  command?: Command;
}

interface Command {
  name: string;
  arg_val?: string;
}

interface Metric {
  display_name: string;
  metric_values: MetricValue[];
}

interface MetricValue {
  measure_name: string;
  telemtry_field: string;
  unit: string;
}

interface Element {
  telemtry_field?: string;
  Uielements?: Element[];
  name: string;
  properties: Properties;
  type: string;
}

export class Menus {
  metaData: Record<string, Menu>;
  globalUiElements: Record<string, any>;
  menus: Record<string, any>;
  constructor(MenusObj: Record<string, any>) {
    this.metaData = MenusObj.metaData;
    this.globalUiElements = MenusObj.globalUiElements;
    this.menus = {};

    for (const prop in MenusObj.menus) {
      let menuObj = MenusObj.menus[prop];

      this.menus[prop] = new Menu(menuObj, menuObj.name, prop);
    }
  }

  GetMenu(menu_name: string): Menu {
    return this.menus[menu_name];
  }
  GetSubScreen(menu_name: string, sub_screen: string): SubScreen {
    return this.menus[menu_name].SubScreens[sub_screen];
  }
}

export class Menu {
  SubScreens: Record<string, SubScreen>;
  name: string;
  numOfSubScreens: number;
  subScreenKeys: number[];
  menuNo: number;
  constructor(menu: any, menuName: string, menuNO: string) {
    this.SubScreens = {};
    this.name = menuName;
    this.menuNo = parseInt(menuNO);
    this.subScreenKeys = [];
    for (const prop in menu) {
      if (prop !== "name") {
        this.SubScreens[prop] = new SubScreen(menu[prop], prop);
        this.subScreenKeys.push(parseInt(prop));
      }
    }
    this.numOfSubScreens = Object.keys(this.SubScreens).length;
  }
  GetSubScreen(sub_screen: string): SubScreen {
    return this.SubScreens[sub_screen];
  }
}

export class SubScreen {
  SubScreenName: number;
  UiElementGroups: UiElementGroups;
  constructor(SubScreenObj: Record<string, any>, SubScreenName: string) {
    this.SubScreenName = parseInt(SubScreenName);

    this.UiElementGroups = new UiElementGroups(SubScreenObj.ui_element_groups);
  }
}

export class UiElementGroups {
  elements: UiElement[][];
  layout: string;
  constructor(elements: Element[][]) {
    this.elements = [];
    this.layout = "";
    for (const ui_elements of elements) {
      let row_elements: UiElement[] = [];
      for (const element of ui_elements) {
        if (UiElementMap[element.type]) {
          row_elements.push(UiElementMap[element.type](element, element.name));
        }
      }
      this.elements.push(row_elements);
    }
  }
}

export abstract class UiElement {
  name: string;
  type: string;
  genericType: string;
  display_name: string;
  telemtryField?: string;
  constructor(element: Element, ElementName: string) {
    this.type = element.type;
    this.genericType = "";
    this.telemtryField = element.telemtry_field;
    this.name = ElementName;
    this.display_name = element.properties.display_name;
  }
}
export class BigInfofield extends UiElement {
  telemtryField: string;
  fieldUnit: string;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.telemtryField = element.properties.telemtry_field!;
    this.genericType = "biginfofield";
    this.fieldUnit = element.properties.field_unit!;
  }
}


export class InfoField extends UiElement {
  genericType: string;
  telemtryField?: string;
  fieldVal?: string;
  fieldUnit?: string;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "infofield";
    this.telemtryField = element.properties.telemtry_field;
    this.fieldVal = element.properties.field_val;
    this.fieldUnit = element.properties.field_unit;
  }
}

export class StateInfoField extends UiElement {
  genericType: string;
  telemtryField?: string;
  fieldVal?: string;
  fieldUnit?: string;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "stateinfofield";
    this.telemtryField = element.properties.telemtry_field;
    this.fieldVal = element.properties.field_val;
    this.fieldUnit = element.properties.field_unit;
  }
}

export class Button extends UiElement {
  genericType: string;
  imageFile?: string;
  telemtryField?: string;
  command?: Command;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "button";
    this.command = element.properties.command;
    this.imageFile = element.properties.image_file;
    this.telemtryField = element.properties.telemtry_field;
  }
}

export class RectangleButton extends UiElement {
  genericType: string;
  imageFile?: string;
  telemtryField?: string;
  selected?: { val: number; telemtry_field: string };
  command?: Command;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "rectanglebutton";
    this.command = element.properties.command;
    this.imageFile = element.properties.image_file;
    this.selected = element.properties.selected;
    this.telemtryField = element.properties.telemtry_field;
  }
}
export class ValueAdjuster extends UiElement {
  genericType: string;
  telemtryField: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "valueadjuster";
    this.type = element.type;
    this.telemtryField = element.properties.telemtry_field!;
  }
}

export class Diode extends UiElement {
  genericType: string;
  telemtryField: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "diode";
    this.telemtryField = element.properties.telemtry_field!;
  }
}

export class UiElementSlider extends UiElement {
  genericType: string;
  uiElements: UiElement[];
  index: number;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "diode";
    this.uiElements = [];
    this.index = 0;
    for (const subElement of element.Uielements!) {
      if (UiElementMap[element.type]) {
        this.uiElements.push(UiElementMap[subElement.type](subElement, subElement.name));
      }
    }
  }
}

export class UiElementsTelemtry extends UiElement {
  genericType: string;
  telemtryField: string;
  childType: string;
  childCommand: string;
  elements: UiElement[];
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "uielementsTelemtry";
    this.telemtryField = element.properties.telemtry_field!;
    this.childType = element.properties.childType!;
    this.childCommand = element.properties.childCommand!.name;

    this.elements = [];
  }

  async initTelemtryList(): Promise<UiElementsTelemtry> {
    const server = GetServer();
    const listfetched = new Promise((res, rej) =>
      server.on_telemtry((data) => {
        res(data.data[this.telemtryField]);
      })
    );
    let elements = (await listfetched) as string[];
    this.elements = this.parseElements(elements);
    return this;
  }

  parseElements(elements: string[]): UiElement[] {
    return elements.map((ele, idx) => {
      let command: Command = { name: this.childCommand, arg_val: idx.toString() };
      let element: Element = { type: this.childType, name: "", properties: { display_name: ele, command: command } };

      return UiElementMap[this.childType](element, ele);
    });
  }
}

export class Image extends UiElement {
  genericType: string;
  imageFile: string;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "image";
    this.imageFile = element.properties.image_file!;
  }
}
export class TextField extends UiElement {
  genericType: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "textfield";
  }
}

export class EmptyField extends UiElement {
  genericType: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.genericType = "emptyfield";
  }
}

export class Gauge extends UiElement {
  gauge_type: string;
  telemtryField: string;
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);

    this.gauge_type = element.properties.gauge_type!;
    this.telemtryField = element.properties.telemtry_field!;
  }
}

export class Switch extends UiElement {
  telemtryField: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.telemtryField = element.properties.telemtry_field!;
  }
}


export class Clock extends UiElement {
  telemtryField: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.telemtryField = element.properties.telemtry_field!;
  }
}



export class DataInfoField extends UiElement {
  telemtryField?: string;
  fieldVal?: string;

  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.telemtryField = element.properties.telemtry_field;
    this.fieldVal = element.properties.field_val;
    this.genericType = "datainfofield"
  }
}

export class Table extends UiElement {
  metrics: Metric[];
  constructor(element: Element, ElementName: string) {
    super(element, ElementName);
    this.metrics = element.properties.metrics!;
  }
  GetMetricsData() {
    // for
  }
}

// export class ErrorLogs extends UiElement {
//   constructor(element: Element, ElementName: string) {
//     super(element, ElementName);
//   }
// }

type CreateUiElement = (element: Element, ElementName: string) => UiElement;
const UiElementMap: Record<string, CreateUiElement> = {
  button: (element: Element, ElementName: string) => new Button(element, ElementName),
  infofield: (element: Element, ElementName: string) => new InfoField(element, ElementName),
  biginfofield: (element: Element, ElementName: string) => new BigInfofield(element, ElementName),
  valueadjuster: (element: Element, ElementName: string) => new ValueAdjuster(element, ElementName),
  emptyfield: (element: Element, ElementName: string) => new EmptyField(element, ElementName),
  textfield: (element: Element, ElementName: string) => new TextField(element, ElementName),
  diode: (element: Element, ElementName: string) => new Diode(element, ElementName),
  gauge: (element: Element, ElementName: string) => new Gauge(element, ElementName),
  switch: (element: Element, ElementName: string) => new Switch(element, ElementName),
  image: (element: Element, ElementName: string) => new Image(element, ElementName),
  clock: (element: Element, ElementName: string) => new Clock(element, ElementName),
  table: (element: Element, ElementName: string) => new Table(element, ElementName),
  errorlogs: (element: Element, ElementName: string) => new Table(element, ElementName),
  uielementslider: (element: Element, ElementName: string) => new UiElementSlider(element, ElementName),
  stateinfofield: (element: Element, ElementName: string) => new StateInfoField(element, ElementName),
  rectanglebutton: (element: Element, ElementName: string) => new RectangleButton(element, ElementName),
  datainfofield: (element: Element, ElementName: string) => new DataInfoField(element, ElementName),
  //keypad: (element: Element, ElementName: string) => new KeyPad(element, ElementName),

};
