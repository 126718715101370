import { ErrorLogEntry } from "./ErrorLogEntry";
import { ErrorTypes, convertToErrorType } from "./ErrorTypes";

type ErrorFormat = { ErrorLogType: Record<string, ErrorType> }
type ErrorType = { SlaveId: number, Time: string, ErrorOrWarning: string, ErrorOrWarningCode: number };
export default function GetErrors(Errors: ErrorFormat, config: Record<string, any>): ErrorLogEntry[] {

  let errorLogs = [];

  for (let error of Object.values(Errors.ErrorLogType)) {
    let errorEntry = ParseErrorLogString(error, config);

    if (errorEntry != null) {
      errorLogs.push(errorEntry);
    }
  }
  return errorLogs;
}


function ParseErrorLogString(errorEntry: ErrorType, config: Record<string, any>): ErrorLogEntry | null {
  let date = errorEntry.Time;
  let errorValue = errorEntry.ErrorOrWarningCode;
  let errorName = GetErrorName(errorEntry.ErrorOrWarning, errorValue);
  if (errorName == null) {
    return null;
  }
  let errorLogEntry = new ErrorLogEntry(date, config.ErrorDescription[errorName], [errorName, errorValue], errorEntry.ErrorOrWarning);
  return errorLogEntry;
}

function GetErrorName(key: string, errorValue: number): string | null {
  let errorkey = convertToErrorType[key];
  let errorType = ErrorTypes[errorkey];
  for (const property in errorType) {
    if ((errorValue & errorType[property]) != 0) {
      return property;
    }
  }

  return null;
}

// }
