import { Grid } from "@mui/material";

import React from "react";
import { UiElement, UiElementGroups } from "../Models/Menus";
import UiElementChooser from "../Views/uiElements/UiElementChooser";



export function SubScreen({ Subscreen, ScreenUpdate, disabled }: { Subscreen: any, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {
  let elements = Subscreen.UiElementGroups;
  return <RenderSubScreen uielement={elements} ScreenUpdate={ScreenUpdate} disabled={disabled}></RenderSubScreen>;
}

function RenderSubScreen({ uielement, ScreenUpdate, disabled }: { uielement: UiElementGroups, ScreenUpdate?: () => void, disabled: boolean }): any {
  return (
    <div className={`grid h-full flex-grow w-full grid-rows-${uielement.elements.length}`}>
      {uielement.elements?.map((element) => {
        let elements: UiElement[] = Object.values(element);
        return (<div className="flex w-full h-full text-[length:inherit]  justify-center ">
          <div className="text-[length:inherit]  flex  flex-grow " />

          <div className={`text-[length:inherit] grid gap-2 h-full  flex-wrap  min-w-0 items-start justify-evenly basis-11/12 flex-grow grid-cols-${elements.length}`}>
            {elements.map((element) => {

              return <div className={`text-[length:inherit] content-center min-w-0  flex justify-evenly flex-grow h-full  flex-wrap`}>
                <UiElementChooser element={element} ScreenUpdate={ScreenUpdate} disabled={disabled}></UiElementChooser>
              </div>
            })}

          </div>
          <div className="flex  flex-grow " />
        </div>
        );
      })}
    </div>);
}
