import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { DataInfoField as DataInfoFieldModel, UiElement, InfoField as infoFieldModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import { convertNameToEncoding, convertValueToEncoding, useEncodingState } from "pages/Navigator/Models/DataEncoding";


export default function DataInfoField({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return <InnerInfoField infoField={element}></InnerInfoField>
}

function InfoFieldWrapper({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
    return <div className=" flex-wrap flex flex-grow justify-center max-h-[3vmax]  text-[length:inherit] pb-1.5 pt-1.5  h-full ">{children}</div>
}

function InnerInfoField({ infoField }: { infoField: DataInfoFieldModel }) {
    if (infoField.telemtryField) {
        return <TelemetryInfoField infoField={infoField} telemtryField={infoField.telemtryField}></TelemetryInfoField>
    }


    return (
        <InfoFieldWrapper>
            <div className="flex basis-6/12 bottom-0 justify-center"><p className="text-[1em]  text-[white] text-center bottom-0">{infoField.display_name}</p></div>
            <div className="flex basis-6/12 justify-start  h-full bg-white bottom-0 auto-top  "><p className="text-[1em] text-black text-center bottom-0">{infoField.fieldVal}</p></div>
        </InfoFieldWrapper>);
    //default case
}



function TelemetryInfoField({ infoField, telemtryField }: { infoField: infoFieldModel, telemtryField: string }) {
    const Server = GetServer();
    const LatestData = Server.getLatestData();
    let [val, setVal] = React.useState<number>(LatestData.data[infoField.telemtryField!]);
    const { encoding } = useEncodingState();
    useEffect(() => {
        function SetTelemetry(data: DatedIotData) {
            if (infoField.fieldUnit !== undefined) {
                setVal(convertValueToEncoding(encoding, data.data[telemtryField], infoField.fieldUnit));
                return;
            }
            setVal(data.data[telemtryField]);
        }
        Server.on_telemtry(SetTelemetry);

        return () => {

            Server.off_telemtry(SetTelemetry);
        }
    }, [Server, infoField, telemtryField]);
    if (infoField.fieldUnit) {
        return (<InfoFieldWrapper>
            <div className="flex basis-6/12   bottom-0"> <p className="text-[1.2em] text-white h-full flex items-center bottom-0 ">{infoField.display_name} </p></div>
            <div className="flex basis-3/12  bottom-0 justify-center  h-full bg-white top-auto"> <p className="text-[1em] h-full flex items-center text-[darkblue]  text-center bottom-0 ">{val} </p></div>
            <div className="flex basis-3/12  bottom-0 justify-start  top-auto pl-[0.5vmax] "> <p className="text-[1em] text-white h-full flex items-center bottom-0 ">{convertNameToEncoding(encoding, infoField.fieldUnit)} </p></div>



        </InfoFieldWrapper >);
    }
    return (
        <InfoFieldWrapper >
            <div className="flex basis-6/12 h-full bottom-0 justify-start items-center"><p className="text-[1.2em] text-center bottom-0">{infoField.display_name}</p></div>
            <div className="flex basis-6/12 justify-center h-full bg-white bottom-0 auto-top items-center "><p className="text-[1em] text-black text-center bottom-0">{val}</p></div>


        </InfoFieldWrapper >);


}




