import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
var format = require("date-format");

export default function LineChartNew({
  telemetryData,
  loading,
  noDataOrFound,
  type,
}) {
  const COLORS = [
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e74c3c",
    "#7f8c8d",
    "#107C10",
    "#E81123",
    "#FF8C00",
    "#0099BC",
  ];
  const tickFormatter = (tick) => format("hh:mm:ss", new Date(tick));

  var domain = [0, 120];
  if (type === "pressure") {
    domain = [0, 40];
  }

  return (
    <div>
      {loading ? (
        <div className="h-80 w-auto animate-pulse rounded bg-slate-200"></div>
      ) : noDataOrFound ? (
        <div>
          <div className="flex flex-row items-center justify-center">
            <div className="text-base text-gray-700">
              We could not receive data from the machines <br />
              The machine might be turned off or the internet connection is
              lost.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart
                label={<CustomLabel />}
                data={telemetryData}
                margin={{ top: 0, right: -10, left: 0, bottom: 0 }}
                syncId={"telemetry-page"}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="EventEnqueuedUtcTime"
                  tickFormatter={tickFormatter}
                  angle={30}
                  dx={20}
                  tickMargin={15}
                  height={50}
                  type="category"
                ></XAxis>
                {/* <YAxis yAxisId="left" unit=' Bar' domain={[0, 40]} />  label={{ value: "Bar", position: 'insideLeft' }} domain={['dataMin', 'dataMax']} label={{ value: this.state.widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  unit="°C"
                  domain={[0, 120]}
                  allowDecimals={true}
                />{" "}
                {/* label={{ value: "°C", position: 'insideRight' }}*/}
                <YAxis yAxisId="left" unit=" Bar" domain={[0, 40]} />
                {/*label={{ value: "Bar", position: 'insideLeft' }}  domain={['dataMin', 'dataMax']} label={{ value: widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}
                <Tooltip
                  // position={{ x: -20 }}

                  // allowEscapeViewBox={{ x: false, y: true }}
                  labelFormatter={(t) => new Date(t).toLocaleString()}
                  isAnimationActive={false}
                />
                <Legend
                  wrapperStyle={{ paddingTop: 15 }}
                  iconType="plainline"
                />
                {type === "press-temp" ? (
                  <Line
                    unit={" Bar"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="PressureOUT"
                    stroke={COLORS[0]}
                    name="Pressure Out"
                    dot={false}
                    yAxisId="left"
                  />
                ) : null}
                {type === "press-temp" ? (
                  <Line
                    unit={" Bar"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="PressureIN"
                    stroke={COLORS[1]}
                    name="Pressure In"
                    dot={false}
                    yAxisId="left"
                  />
                ) : null}
                {type === "press-temp" ? (
                  <Line
                    unit={" °C"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="Temperature_Water"
                    stroke={COLORS[2]}
                    name="Temperature Water"
                    dot={false}
                    yAxisId="right"
                  />
                ) : null}
                {type === "press-temp" ? (
                  <Line
                    unit={" °C"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="Temperature_igbt"
                    stroke={COLORS[3]}
                    name="Temperature Module"
                    dot={false}
                    yAxisId="right"
                  />
                ) : null}
                {type === "press-temp" ? (
                  <Line
                    unit={" °C"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="Temperature_ctrl_board"
                    stroke={COLORS[4]}
                    name="Temperature Board"
                    dot={false}
                    yAxisId="right"
                  />
                ) : null}
                {type === "other" ? (
                  <Line
                    unit={" kW"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="InverterFreqReadout"
                    stroke={COLORS[6]}
                    name="Inverter Frequency"
                    dot={false}
                  />
                ) : null}
                {type === "other" ? (
                  <Line
                    unit={" kW"}
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="Inverter_loadPowP"
                    stroke={COLORS[7]}
                    name="Power"
                    dot={false}
                  />
                ) : null}
                {type === "other" ? (
                  <Line
                    strokeWidth={2}
                    legendType="plainline"
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="FlowSw"
                    stroke={COLORS[5]}
                    name="Flow Switch"
                    dot={false}
                    formatter={(value) => (value >= 10 ? "CLOSE" : "OPEN")}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div id="html-dist"></div>
        </div>
      )}
    </div>
  );
}
function CustomLabel({ x, y, stroke, value, width }) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}
