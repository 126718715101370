import React from "react";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useNavigate, useParams } from "react-router-dom";
import { useForgotPassword } from "hooks/user";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

//TODO make it so after the account is confirmed to redirect to mobile app
const ForgotPassword = () => {
  const navigate = useNavigate();
  let params = useParams();
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);

  const {
    mutate: forgotPassword,
    isPending,
    isError,
    isSuccess,
    error,
    status,
  } = useForgotPassword();
  console.log("isLoading", status);
  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const email = formData.get("email");

    const data = { email: email };

    forgotPassword(data);
  };

  if (isSuccess) {
    document.getElementById("myform").reset();
  }

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center bg-nilfisk py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Reset your password
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img
            className="mx-auto mb-6 w-auto"
            src={nilfiskFoodLogo}
            alt="Nilfisk Food"
          />
          <form className="space-y-6" id="myform" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    invalidEmailError
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            {isSuccess ? (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="size-5 text-green-400"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      We've sent you an email with a link to reset your
                      password.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {isError || error ? (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <XCircleIcon
                      aria-hidden="true"
                      className="size-5 text-red-400"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      Error sending an email with reset instructions.
                    </h3>
                    
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div>
              <button
                type="submit"
                disabled={isPending}
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
              >
                {isPending && (
                  <div className="">
                    <div className="mr-4 h-6 w-6 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-300 border-t-transparent"></div>
                  </div>
                )}
                Send reset instructions
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
