import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";
import { useUserByIdQuery } from "hooks/user";
import { useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { useChangeFactoryRole } from "hooks/user/useChangeFactoryRole";
import RemoveUserDialog from "./RemoveUserDialog";
import { useOutletContext } from "react-router-dom";

//TODO finish remove user from factory using useRemoveUserFromFactory hook
export default function UserProfileDrawer() {
  const navigate = useNavigate();
  const { factoryDetails } = useOutletContext();
  // console.log("factoryDetails", factoryDetails);
  const myAccount = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(false);
  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [defaultRole, setDefaultRole] = useState("Operator");
  const [isEditing, setIsEditing] = useState(false);
  const { userId, factoryId } = useParams();

  const { data, isLoading, error } = useUserByIdQuery(userId);
  console.log("userrrr", data);
  const { mutate: changeFactoryRole, isPending } = useChangeFactoryRole();

  const userData = data?.data.user;

  // console.log("userData******* ", userData);

  let canEditFactoryRole =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === parseInt(factoryId),
  )?.FactoryUser?.factoryRole;

  if (factoryRole === "Manager") {
    canEditFactoryRole = true;
  }

  // if (myAccount && myAccount.Factories) {
  //   const factory = myAccount.Factories.find(
  //     (factory) => factory.id === parseInt(factoryId),
  //   );
  //   if (factory) {
  //     canEditFactoryRole = true;
  //   }
  // }

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const currentRole = useWatch({ control, name: "factory-role" });

  useEffect(() => {
    if (data && data?.data?.user?.Factories) {
      const factory = data.data.user.Factories.find(
        (factory) => factory.id === parseInt(factoryId),
      );
      if (factory) {
        setDefaultRole(factory.FactoryUser.factoryRole);
        setValue("factory-role", factory.FactoryUser.factoryRole);
      }
    }
  }, [data, factoryId, setValue]);

  useEffect(() => {
    if (userId) {
      setOpen(true);
    } else {
      setOpen(false);
      reset();
    }
  }, [userId, reset]);

  useEffect(() => {
    setIsEditing(currentRole !== defaultRole);
  }, [currentRole, defaultRole]);

  const handleClose = () => {
    setOpen(false);
    //add a 1 second waiting for navigate
    setTimeout(() => {
      navigate(`/commander/${factoryId}/view/users`);
    }, 500);
  };

  const onSubmit = (data) => {
    // console.log("data", data);

    changeFactoryRole(
      {
        role: data["factory-role"],
        UserId: userId,
        FactoryId: factoryId,
      },
      {
        onSuccess: () => {
          setDefaultRole(data["factory-role"]);
          // setValue("factory-role", data["factory-role"]);
          // queryClient.invalidateQueries({
          //   queryKey: ["factory", factoryId, "rooms"],
          // });
          // setOpen(false);
        },
        onError: (error) => {
          console.log("error", error.response);
          setError("factory-role", {
            type: "manual",
            message: error?.response?.data?.msg || "Error changing role",
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setValue("factory-role", defaultRole);
  };
  return (
    <>
      <RemoveUserDialog
        open={openRemoveUser}
        setOpen={setOpenRemoveUser}
        userId={userId}
        factoryId={factoryId}
        userName={`${userData?.firstName} ${userData?.lastName}`}
        factoryName={factoryDetails?.name}
      />
      <Dialog open={open} onClose={handleClose} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        Profile
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => handleClose()}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div className="divide-y divide-gray-200">
                    <div className="pb-6">
                      <div className="h-24 bg-blue-700 sm:h-20 lg:h-28" />
                      <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                        <div>
                          <div className="-m-1 flex">
                            <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                              <span className="inline-block h-24 w-24 flex-shrink-0 overflow-hidden bg-gray-100 sm:h-40 sm:w-40 lg:h-48 lg:w-48">
                                <svg
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  className="h-full w-full text-gray-300"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 sm:ml-6 sm:flex-1">
                          <div>
                            <div className="flex items-center">
                              <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                {userData?.firstName} {userData?.lastName}
                              </h3>
                              <span className="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                                <span className="sr-only">Online</span>
                              </span>
                            </div>
                            <p className="text-sm text-gray-500">
                              @{userData?.username}
                            </p>
                          </div>
                          <div className="mt-5 flex flex-wrap justify-end space-y-3 sm:space-x-3 sm:space-y-0">
                            {/* 
                            remove justify-end when you use these buttons
                            <button
                              type="button"
                              className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:flex-1"
                            >
                              View Profile
                            </button>
                            <button
                              type="button"
                              className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Call
                            </button> */}
                            <div className="ml-3 inline-flex sm:ml-0">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <MenuButton className="relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                  <span className="absolute -inset-1" />
                                  <span className="sr-only">
                                    Open options menu
                                  </span>
                                  <EllipsisVerticalIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                </MenuButton>
                                <MenuItems
                                  transition
                                  className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                  <div className="py-1">
                                    <MenuItem>
                                      <button
                                        className="group flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        onClick={() => setOpenRemoveUser(true)}
                                      >
                                        <TrashIcon
                                          aria-hidden="true"
                                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        />
                                        Remove from factory
                                      </button>
                                    </MenuItem>
                                    {/* <MenuItem>
                                    <a
                                      href="#"
                                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                      View profile
                                    </a>
                                  </MenuItem> */}
                                    {/* <MenuItem>
                                    <a
                                      href="#"
                                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    >
                                      Copy profile link
                                    </a>
                                  </MenuItem> */}
                                  </div>
                                </MenuItems>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Email
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {userData?.email}
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Phone
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {userData?.phone}
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Factory role
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {/* <div className="space-y-5 sm:col-span-2"> */}
                              <div className="space-y-5 sm:mt-0">
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-6 items-center">
                                    <input
                                      // defaultChecked={defaultRole == "Manager"}
                                      defaultValue={"Manager"}
                                      id="Manager"
                                      value={"Manager"}
                                      name="factory-role"
                                      type="radio"
                                      disabled={!canEditFactoryRole}
                                      aria-describedby="Manager-description"
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 disabled:opacity-60"
                                      {...register("factory-role")}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm leading-6">
                                    <label
                                      htmlFor="Manager"
                                      className="font-medium text-gray-900"
                                    >
                                      Administrator
                                    </label>
                                    <p
                                      id="Manager-description"
                                      className="text-gray-500"
                                    >
                                      Has access to all the features of the
                                      factory and can manage users, settings and
                                      machines of the factory.
                                    </p>
                                  </div>
                                </div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-6 items-center">
                                    <input
                                      // defaultChecked={defaultRole == "Technician"}
                                      defaultValue={"Technician"}
                                      id="Technician"
                                      name="factory-role"
                                      value={"Technician"}
                                      type="radio"
                                      disabled={!canEditFactoryRole}
                                      aria-describedby="restricted-access-description"
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 disabled:opacity-60"
                                      {...register("factory-role")}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm leading-6">
                                    <label
                                      htmlFor="restricted-access"
                                      className="font-medium text-gray-900"
                                    >
                                      Technician
                                    </label>
                                    <p
                                      id="restricted-access-description"
                                      className="text-gray-500"
                                    >
                                      Can only access the machines of the
                                      factory, he can update different settings
                                      and configurations of the machines.
                                    </p>
                                  </div>
                                </div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-6 items-center">
                                    <input
                                      // defaultChecked={defaultRole == "Operator"}
                                      defaultValue={"Operator"}
                                      id="Operator"
                                      name="factory-role"
                                      value={"Operator"}
                                      type="radio"
                                      disabled={!canEditFactoryRole}
                                      aria-describedby="private-access-description"
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 disabled:opacity-60"
                                      {...register("factory-role")}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm leading-6">
                                    <label
                                      htmlFor="private-access"
                                      className="font-medium text-gray-900"
                                    >
                                      Operator
                                    </label>
                                    <p
                                      id="private-access-description"
                                      className="text-gray-500"
                                    >
                                      Can only access the machines of the
                                      factory, he can only view the status of
                                      the machines and the data generated by the
                                      machines.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </dd>
                          </div>
                        </dl>
                        <p
                          className="mt-2 text-right text-sm text-red-600"
                          hidden={!errors["factory-role"]}
                        >
                          {errors["factory-role"]?.message}
                        </p>
                        {isEditing && (
                          <div className="flex flex-shrink-0 items-center justify-end gap-x-4 px-4 py-4">
                            {/* {isPending ? (
                        <div
                          className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                          role="status"
                          aria-label="loading"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null} */}
                            {isPending ? (
                              <div
                                className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                                role="status"
                                aria-label="loading"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}
                            <button
                              type="button"
                              disabled={isPending}
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-blue-500 disabled:opacity-50 disabled:hover:bg-white"
                              onClick={() => handleCancel()}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              disabled={isPending}
                              className="inline-flex justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:opacity-50 disabled:hover:bg-blue-500"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
