import { TableCell, Typography } from "@mui/material";
import React from "react";

export function InfoElement({ name, value }: { name: string; value: string }): JSX.Element {
    return (
      <>
        <TableCell  sx={{ paddingLeft: "0.3 vmax", }} size="small">
          <Typography variant="button" color={"black"} fontSize={"0.6vmax"}  >
            {name}
          </Typography>
        </TableCell>
  
        <TableCell  sx={{  paddingLeft: "0.4vmax", }} size="small">
          <Typography variant="button" color={"black"} fontSize={"0.6vmax"} >
            {value}
          </Typography>
        </TableCell>
        </> 
         );
  }