import SwitchMui from "@mui/material/Switch"; //TODO Chanded to mui from material-ui
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Switch as SwitchModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function Switch({
  element,
  ScreenUpdate,
  disabled,
}: {
  element: UiElement;
  ScreenUpdate?: () => void;
  disabled: boolean;
}): JSX.Element {
  return <InnerSwitch switchModel={element as SwitchModel}></InnerSwitch>;
}
function InnerSwitch({ switchModel }: { switchModel: SwitchModel }) {
  const Server = GetServer();

  let [val, setVal] = React.useState<number>(0);
  function SetTelemetry(data: DatedIotData) {
    setVal(data.data[switchModel.telemtryField]);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);
  return (
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item>
        {" "}
        <Typography fontSize={"0.70em"}>
          {switchModel.display_name + ":"}
        </Typography>{" "}
      </Grid>
      <Grid item xs={1}>
        {" "}
        <SwitchMui color="default" defaultChecked={!val}></SwitchMui>
      </Grid>
    </Grid>
  );
}
