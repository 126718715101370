import React from "react";
import { useOutletContext } from "react-router-dom";
import ChartsWrapper from "./components/ChartsWrapper";
import ChartsWrapperMultibooster from "./components/ChartsWrapperMultibooster";
import { useMachineConsumptionDataQuery } from "hooks/machine/useMachineConsumptionDataQuery";
import BarChartConsumption from "pages/Dashboard/Chart/BarChartConsumption";
import BarChartPower from "pages/Dashboard/Chart/BarChartPower";
import MixStation from "shared/utils/images/Mix25.png";
import MixStation3D from "shared/utils/images/Machines/MIX3D.png";
import BF32_3D from "shared/utils/images/Machines/BF32_3D.png";
import BF32_LEFT from "shared/utils/images/Machines/BF32_LEFT.png";
import BF32_RIGHT from "shared/utils/images/Machines/BF32_RIGHT.png";
import MA from "shared/utils/images/Machines/MA.png";
import {
  decryptErrorCodes,
  decryptMixInputs,
  decryptMixOutputs,
  decryptWarningCodes,
} from "../../shared/utils/decriptErrors";
import { getSocket } from "../../shared/services/socket.service";
import { useMachineErrorLogQuery } from "hooks/machine";
import ErrorLogListSkeleton from "./components/ErrorLogListSkeleton";
import ErrorLogList from "./components/ErrorLogList";
import ChartsWrapperV2 from "./components/ChartsWrapperV2";
export default function ViewMachineTelemetry() {
  const { machineData } = useOutletContext();

  let type = "none";
  if (machineData?.type.toLowerCase().includes("mix")) type = "mix";
  if (machineData?.type.toLowerCase().includes("multibooster"))
    type = "multibooster";

  // type = "mix";
  // const {data, isLoading, error} = useMachinePerformanceDataQuery(machineData?.id);
  const { data, isLoading, error } = useMachineConsumptionDataQuery(
    machineData?.serialNumber,
  );

  const runtimeData = data?.data?.result?.RunningHours;
  var runtimeToday = 0;
  var runtimeLast7Days = 0;
  var runtimeMin = 0;
  var runtimeMax = 0;

  if (typeof runtimeData !== "undefined") {
    runtimeData.forEach((element, index) => {
      if (index === 0) {
        runtimeToday = element;
      }
      if (index < 7) {
        runtimeLast7Days += element;
      }
      if (runtimeMin > element) {
        runtimeMin = element;
      }
      if (runtimeMax < element) {
        runtimeMax = element;
      }
    });
  }

  const waterTempData = data?.data?.result?.WaterTempAvg;

  var waterTempToday = 0;
  var waterTempLast7Days = 0;
  var waterTempMin = 0;
  var waterTempMax = 0;

  if (typeof waterTempData !== "undefined") {
    waterTempData.forEach((element, index) => {
      if (index === 0) {
        waterTempToday = element;
      }
      if (index < 7) {
        waterTempLast7Days += element;
      }
      if (waterTempMin > element) {
        waterTempMin = element;
      }
      if (waterTempMax < element) {
        waterTempMax = element;
      }
    });

    waterTempLast7Days = waterTempLast7Days / 7;
  }

  var powerData = data?.data?.result?.Power;
  var powerToday = 0;
  var powerLast7Days = 0;
  var powerMin = 0;
  var powerMax = 0;

  if (typeof powerData !== "undefined") {
    powerData.forEach((element, index) => {
      if (index === 0) {
        powerToday = element;
      }
      if (index < 7) {
        powerLast7Days += element;
      }
      if (powerMin > element) {
        powerMin = element;
      }
      if (powerMax < element) {
        powerMax = element;
      }
    });
  }

  //Error log
  const {
    data: errorLog,
    isLoading: errorLogIsLoading,
    error: errorLogError,
  } = useMachineErrorLogQuery(machineData?.serialNumber);
  var logList = [];
  if (errorLog?.data !== undefined) {
    logList = convertLogList(errorLog?.data);
  }
  return (
    <>
      {/* {type === "multibooster" ? ( */}
      {type === "multibooster" ? (
        <ChartsWrapperMultibooster machineData={machineData} />
      ) : (
        <ChartsWrapperV2 machineData={machineData} />
      )}
      {/* <ChartsWrapper machineData={machineData} /> */}

      <div className="justify-between gap-4 grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 2xl:grid-cols-4">
        <div className="w-full ">
          <div className="mt-4 bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Performance Data
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Performance data from the last 7 days.
              </p>
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="grid grid-cols-4 px-4 py-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-900"></div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Today
                    </p>
                  </div>

                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days <br /> (min/max)
                    </p>
                  </div>
                </div>
                {/* ============= Run time ============= */}
                <div className="grid grid-cols-4 px-4 py-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-900">
                    Run time
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeToday} h
                  </div>

                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeLast7Days} h
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeMin}/{runtimeMax} h
                  </div>
                </div>

                {/* =============  Water temp. ============= */}
                <div className="grid grid-cols-4 px-4 py-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-900">
                    Water temp.
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {waterTempToday.toFixed(1)} °C
                  </div>

                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {waterTempLast7Days.toFixed(1)} °C
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {waterTempMin}/{waterTempMax} °C
                  </div>
                </div>

                {/* =============  Power ============= */}
                <div className="grid grid-cols-4 px-4 py-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-900">Power</div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerToday} kWh
                  </div>

                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerLast7Days} kWh
                  </div>
                  <div className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerMin}/{powerMax} kWh
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full ">
          <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Running Hours
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <div className=" min-w-80 px-4 py-6 sm:px-6">
                <BarChartConsumption
                  data={data?.data?.result}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
          <div className=" mt-4 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Power Consumption
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <div className=" min-w-80 px-4 py-6 sm:px-6">
                <BarChartPower data={data?.data?.result} loading={isLoading} />
              </div>
            </div>
          </div>

          {/* <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Power Consumption
              </h3>
              <div className="border-t border-gray-100">
                <div className="min-h-80 min-w-80 px-4 py-6 sm:px-6">
                  <BarChartPower
                    data={data?.data?.result}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="mt-4 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl 2xl:mt-4">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Error Log
            </h3>
            {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
          </div>
          <div className="max-h-[650px] overflow-y-auto  border-t border-gray-100">
            <div className=" min-w-80 px-4 py-6 sm:px-6">
              {errorLogIsLoading ? (
                <ErrorLogListSkeleton logList={logList} />
              ) : logList.length === 0 ? (
                <p className=" py-2 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
                  No errors found
                </p>
              ) : (
                <ErrorLogList
                  logList={logList}
                  startDate={null}
                  endDate={null}
                />
              )}
            </div>
          </div>
        </div>
        {type === "mix" && (
          <MixStationCard serialNumber={machineData?.serialNumber} />
        )}
        {type === "multibooster" && (
          <MultiBoosterCard serialNumber={machineData?.serialNumber} />
        )}
        {type === "none" && (
          <MainStationCard serialNumber={machineData?.serialNumber} />
        )}
      </div>
    </>
  );
}

const SensorOverlay = ({ status, x, y }) => (
  <div
    style={{
      position: "absolute",
      top: `${y}%`,
      left: `${x}%`,
      width: "20px",
      height: "20px",
      backgroundColor: status === "error" ? "#d3d3d3" : "#04f404",
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
    }}
  />
);

const MixStationCard = ({ serialNumber }) => {
  console.log("serialNumber", serialNumber);
  const [InvPortInputState, setInvPortInputState] = React.useState(0);
  const [InvPortOutputState, setInvPortOutputState] = React.useState(0);
  const [FlowSw, setFlowSw] = React.useState(false);

  React.useEffect(() => {
    let socket = getSocket();
    console.log("sockeet", socket);
    if (socket)
      socket.on("telemetry-" + serialNumber, (data) => {
        const messageData = JSON.parse(data);
        let device = messageData.IotData;
        setInvPortInputState(device.InvPortInputState);
        setInvPortOutputState(device.InvPortOutputState);
        if (device.FlowSw >= 1000) {
          setFlowSw(true);
        } else {
          setFlowSw(false);
        }
      });
  }, [serialNumber]);
  // Example sensor data
  const sensors = [
    {
      id: 1,
      status: getWaterValveStatus(InvPortOutputState) ? "ok" : "error",
      x: 69,
      y: 20,
    },
    { id: 2, status: FlowSw ? "ok" : "error", x: 88, y: 55 },
  ];

  return (
    <div className="col-span-1 mt-4 w-full ">
      <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl ">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Mix Station
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="border-t border-gray-100 ">
          <div className=" px-4 py-6 sm:px-6 ">
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={MixStation3D}
                alt="Pump"
                style={{ height: "100%", maxHeight: 595 }}
              />
              {sensors.map((sensor) => (
                <SensorOverlay
                  key={sensor.id}
                  status={sensor.status}
                  x={sensor.x}
                  y={sensor.y}
                />
              ))}
              <WaterLevelOverlay InvPortInputState={InvPortInputState} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getWaterLevel(binaryValue) {
  // if (binaryValue > 15) return 5;
  const values = decryptMixInputs(binaryValue);
  if (values.highLimitTank) return 51;
  else if (values.tankControlHigh) return 45;
  else if (values.lowLevelTank) return 24;
  else if (values.lowLevelAlarmTank) return 10;
  else return 5;
}

function getWaterValveStatus(binaryValue) {
  const values = decryptMixOutputs(binaryValue);
  if (values.waterValve) return true;
  else return false;
}

const WaterLevelOverlay = ({ InvPortInputState }) => (
  <div
    style={{
      position: "absolute",
      width: "33%",
      height: `${getWaterLevel(InvPortInputState)}%`,
      left: "18%",
      bottom: "15%",
      backgroundColor: "#00BFFF70",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      overflow: "hidden",
      borderRadius: "8px",
    }}
  />
);

// multibooster
const MultiBoosterCard = ({ serialNumber }) => {
  console.log("serialNumber", serialNumber);
  const [InvPortInputState, setInvPortInputState] = React.useState(0);
  const [InvPortOutputState, setInvPortOutputState] = React.useState(0);
  const [FlowSw, setFlowSw] = React.useState(false);

  React.useEffect(() => {
    let socket = getSocket();
    console.log("sockeet", socket);
    if (socket)
      socket.on("telemetry-" + serialNumber, (data) => {
        const messageData = JSON.parse(data);
        let device = messageData.IotData;
        setInvPortInputState(device.InvPortInputState);
        setInvPortOutputState(device.InvPortOutputState);
        if (device.FlowSw >= 1000) {
          setFlowSw(true);
        } else {
          setFlowSw(false);
        }
      });
  }, [serialNumber]);
  // Example sensor data
  const sensors = [
    {
      id: 1,
      status: getWaterValveStatus(InvPortOutputState) ? "ok" : "error",
      x: 69,
      y: 20,
    },
    { id: 2, status: FlowSw ? "ok" : "error", x: 88, y: 55 },
  ];

  return (
    <div className="col-span-1 mt-4 w-full">
      <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            MultiBooster
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="border-t border-gray-100">
          <div className=" px-4 py-6 sm:px-6">
            <div style={{ position: "relative", display: "inline-block" }}>
              <img src={BF32_LEFT} alt="Pump" style={{ width: "100%" }} />
              <img src={BF32_RIGHT} alt="Pump" style={{ width: "100%" }} />
              {/* {sensors.map((sensor) => (
                <SensorOverlay
                  key={sensor.id}
                  status={sensor.status}
                  x={sensor.x}
                  y={sensor.y}
                />
              ))}
              <WaterLevelOverlay InvPortInputState={InvPortInputState} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainStationCard = ({ serialNumber }) => {
  console.log("serialNumber", serialNumber);
  const [InvPortInputState, setInvPortInputState] = React.useState(0);
  const [InvPortOutputState, setInvPortOutputState] = React.useState(0);
  const [FlowSw, setFlowSw] = React.useState(false);

  React.useEffect(() => {
    let socket = getSocket();
    console.log("sockeet", socket);
    if (socket)
      socket.on("telemetry-" + serialNumber, (data) => {
        const messageData = JSON.parse(data);
        let device = messageData.IotData;
        setInvPortInputState(device.InvPortInputState);
        setInvPortOutputState(device.InvPortOutputState);
        if (device.FlowSw >= 1000) {
          setFlowSw(true);
        } else {
          setFlowSw(false);
        }
      });
  }, [serialNumber]);
  // Example sensor data
  const sensors = [
    {
      id: 1,
      status: getWaterValveStatus(InvPortOutputState) ? "ok" : "error",
      x: 69,
      y: 20,
    },
    { id: 2, status: FlowSw ? "ok" : "error", x: 88, y: 55 },
  ];

  return (
    <div className="col-span-1 mt-4 w-full">
      <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-2xl">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            MainStation
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="border-t border-gray-100">
          <div className=" px-4 py-6 sm:px-6">
            <div
              className="flex items-center justify-center"
              style={{ position: "relative", display: "inline-block" }}
            >
              <img
                src={MA}
                alt="Pump"
                style={{ height: "100%", maxHeight: 595 }}
              />
              {/* {sensors.map((sensor) => (
                <SensorOverlay
                  key={sensor.id}
                  status={sensor.status}
                  x={sensor.x}
                  y={sensor.y}
                />
              ))}
              <WaterLevelOverlay InvPortInputState={InvPortInputState} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//TODO fix error log using the json command in the backend, it cannot convert properly the json from michael
const convertLogList = (logList) => {
  var errors = [];
  var warnings = [];
  for (let i = 0; i < logList.length; i++) {
    const dates = logList[i].date;
    const ErrorCode1 = logList[i].ErrorCode1;
    const ErrorCode2 = logList[i].ErrorCode2;
    const ErrorCode3 = logList[i].ErrorCode3;
    const GUIError1 = logList[i].GUIError1;
    const GUIError2 = logList[i].GUIError2;
    const WarningCode1 = logList[i].WarningCode1;
    const WarningCode2 = logList[i].WarningCode2;
    const GUIWarning1 = logList[i].GUIWarning1;
    const SlaveId = logList[i]?.SlaveId || "0";
    const errorsList = decryptErrorCodes(
      ErrorCode1,
      ErrorCode2,
      ErrorCode3,
      GUIError1,
      GUIError2,
    );
    errorsList.map((error) => {
      error.SlaveId = SlaveId;
      error.date = dates;
    });
    const warningsList = decryptWarningCodes(
      WarningCode1,
      WarningCode2,
      GUIWarning1,
    );
    warningsList.map((warning) => {
      warning.SlaveId = SlaveId;
      warning.date = dates;
    });
    errors = errors.concat(errorsList);
    warnings = warnings.concat(warningsList);
  }
  logList = errors.concat(warnings);
  //sort by date
  logList.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return logList;
};
