import { Box, Button, CircularProgress, Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { DatedIotData, IotData } from "../../Models/ServerCommuncation/serverInterFace";

const DIGITAL_DIODE_INPUT_NAMES = ["DI1", "DI2", "DI3", "DI4", "DI5", "DI6"];
const ANALOG_INPUT_DIODE = ["AI1", "AI2"];

const DIODE_OUTPUT_NAMES = ["DO1", "DO2", "DO3", "DO4", "DO5", "DO6", "DO7", "DO8", "DO9", "RL1", "RL2"];

const INVERTER_INPUT_NAMES = ["PFI1", "PFI2", "PFI3", "PFI4", "GFI1", "GFI2", "GFI3", "GFI4"];
const INVERTER_OUTPUT_NAMES = ["RL1", "RL2", "DO1", "DO2"];



type Diode<T> = { diode_value: T; diode_name: string };



type DiodeSourceType = "Display" | "Inverter";

type DiodeType = "Output" | "Input";
type DiodeViews = () => JSX.Element;

const DiodesDisplayViews: Record<DiodeType, DiodeViews> = {
  Output: DisplayOutputDiodes,
  Input: DisplayInputDiodes,
};


const DiodesInverterViews: Record<DiodeType, DiodeViews> = {
  Output: DisplayInverterOutput,
  Input: DisplayInverterInput,
};


const DiodeSourceTypeViews: Record<DiodeSourceType, Record<DiodeType, DiodeViews>> = {
  "Display": DiodesDisplayViews,
  "Inverter": DiodesInverterViews
};

function GreenLED(): JSX.Element {
  return <Box height={"1.2vmax"} width={"1.2vmax"} className={"green led"} borderColor={"silver"} borderRadius={"50%"} border={"0.2vmax solid #cecccc"}></Box>

}

function GrayLED(): JSX.Element {
  return <Box height={"1.2vmax"} width={"1.2vmax"} className={"gray led"} borderColor={"silver"} borderRadius={"50%"} border={"0.2vw solid #cecccc"}></Box>

}




export default function DiodeBox(): JSX.Element {
  const [SourceViewChosen, SetSourceViewChosen] = React.useState<DiodeSourceType>("Display");

  const [diodeViewChosen, SetDiodeViewChosen] = React.useState<DiodeType>("Output");

  const DiodeView = DiodeSourceTypeViews[SourceViewChosen][diodeViewChosen];
  return (
    <div className="flex text-[length:inherit] flex-col  shadow bg-white overflow-hidden rounded-[0.5vmax] " >
      <DiodeMenuSelector diodeViewChosen={SourceViewChosen} SetDiodeViewChosen={SetSourceViewChosen} Choices={["Display", "Inverter"]}></DiodeMenuSelector>
      <div className="hidden lg:block w-full p-[0.1em]" aria-hidden="true" />

      <DiodeMenuSelector diodeViewChosen={diodeViewChosen} SetDiodeViewChosen={SetDiodeViewChosen} Choices={["Output", "Input"]}></DiodeMenuSelector>
      <div className="hidden lg:block w-full p-[0.1em]" aria-hidden="true" />

      <div className="flex flex-grow h-[15em] " >
        <DiodeView></DiodeView>
      </div>
    </div>
  );
}



function DiodeMenuSelector<T>({ diodeViewChosen, SetDiodeViewChosen, Choices }: { Choices: [T, T], diodeViewChosen: T, SetDiodeViewChosen: React.Dispatch<React.SetStateAction<T>> }): JSX.Element {
  return (<div className=" flex-col   h-full ">

    <div className="flex h-full">
      <div className="  flex flex-grow w-full">
        <DiodeMenuButton
          name={Choices[0]}
          DiodeMenu={diodeViewChosen}
          onClick={() => {
            SetDiodeViewChosen(Choices[0]);
          }}
        ></DiodeMenuButton>
      </div>
      <div className="flex basis-1  lg:block  p-[0.1em] bg-gray-400 ml-1 mr-1" aria-hidden="true" />
      <div className="flex flex-grow w-full">
        <DiodeMenuButton
          name={Choices[1]}
          DiodeMenu={diodeViewChosen}
          onClick={() => {
            SetDiodeViewChosen(Choices[1]);
          }}
        ></DiodeMenuButton>
      </div>
    </div>
  </div >);
}



function DiodeMenuButton<T>({ name, DiodeMenu, onClick }: { name: T; DiodeMenu: T; onClick: () => void }): JSX.Element {
  if (DiodeMenu === name) {
    return (
      <Grid item xs bgcolor={"#001b54"} >
        <Button fullWidth onClick={onClick}>
          <Typography color={"white"} variant="button">
            {name}
          </Typography>
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item xs>
      <Button fullWidth onClick={onClick}>
        {name}
      </Button>
    </Grid>
  );
}

function ParseOutPutDiodes(data: IotData): [Diode<boolean>, Diode<boolean>?][] {

  return ParseDiodes(data.DiodesState, 16, DIODE_OUTPUT_NAMES, (state, idx, shift) => {
    return [!!((state >> (idx + shift)) & 1), !!((state >> (idx + shift + 1)) & 1)];
  });
}


function ParseAnalogInPutDiodes(data: IotData): [Diode<number>, Diode<number>?][] {
  const ToVoltValue = 0.0030625;
  return ParseDiodes(data.AnalogState, 16, ANALOG_INPUT_DIODE, (state, idx, shift) => {
    return [(state & 0xFF) * ToVoltValue, ((state >> shift) & 0xFF) * ToVoltValue];
  });

}

function ParseInPutDiodes(data: IotData): [Diode<boolean>, Diode<boolean>?][] {
  let inputShift = 0;
  return ParseDiodes(data.DiodesState, inputShift, DIGITAL_DIODE_INPUT_NAMES, (state, idx, shift) => {
    return [!!((state >> (idx + shift)) & 1), !!((state >> (idx + shift + 1)) & 1)];
  });
}


function ParseInverterInput(data: IotData): [Diode<boolean>, Diode<boolean>?][] {

  let first_part = ParseDiodes(data.InvPortInputState, 0, INVERTER_INPUT_NAMES.slice(0, 4), (state, idx, shift) => {
    return [!((state >> (idx + shift)) & 1), !((state >> (idx + shift + 1)) & 1)];
  });
  let second_part = ParseDiodes(data.InvPortInputState, 4, INVERTER_INPUT_NAMES.slice(4, INVERTER_INPUT_NAMES.length), (state, idx, shift) => {
    return [!!((state >> (idx + shift)) & 1), !!((state >> (idx + shift + 1)) & 1)];
  });
  return first_part.concat(second_part);
}
function ParseInverterOutput(data: IotData): [Diode<boolean>, Diode<boolean>?][] {

  return ParseDiodes(data.InvPortOutputState, 0, INVERTER_OUTPUT_NAMES, (state, idx, shift) => {
    return [!!((state >> (idx + shift)) & 1), !!((state >> (idx + shift + 1)) & 1)];
  });

}


function ParseDiodes<T>(value: number, shift: number, diode_names: string[], calcValueFn: (state: number, idx: number, shift: number) => [T, T]): [Diode<T>, Diode<T>?][] {
  let diodes: [Diode<T>, Diode<T>?][] = [];

  let idx = 0;


  for (; idx <= diode_names.length - 2; idx += 2) {
    let [diode_val1, diode_val2] = calcValueFn(value, idx, shift);
    let diode1: Diode<T> = { diode_value: diode_val1, diode_name: diode_names[idx] };
    let diode2: Diode<T> = { diode_value: diode_val2, diode_name: diode_names[idx + 1] };

    diodes.push([diode1, diode2]);
  }

  if ((diode_names.length & 1) === 1) {
    let [diode_val,] = calcValueFn(value, idx, shift);
    let diode: Diode<T> = { diode_value: diode_val, diode_name: diode_names[idx] };
    diodes.push([diode, undefined]);
  }

  return diodes;
}


function DisplayOutputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode<boolean>, Diode<boolean>?][]>([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseOutPutDiodes(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"26vh"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (<DiodeListView diodes={Diodes}></DiodeListView>);
}


function DiodeListView({ diodes }: { diodes: [Diode<boolean>, Diode<boolean>?][] }): JSX.Element {
  return <div className="overflow-y-scroll scroll    grid-cols-2  space-y-3 flex-grow flex-col flex min-w-full "
  >
    {diodes.map((diode) => {
      return (
        <div className=" grid-cols-2 flex  ">
          <DiodeView diode={diode[0]}></DiodeView>
          <DiodeView diode={diode[1]}></DiodeView>
        </div>
      );
    })}
  </div>
}

function DisplayInverterOutput(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode<boolean>, Diode<boolean>?][]>([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseInverterOutput(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"26vh"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (<DiodeListView diodes={Diodes}></DiodeListView>);
}

function DisplayInverterInput(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode<boolean>, Diode<boolean>?][]>([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    console.log(data);

    let diodes = ParseInverterInput(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);

  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"26vh"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (<DiodeListView diodes={Diodes}></DiodeListView>);
}


function DisplayInputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode<boolean>, Diode<boolean>?][]>([]);
  const [analogDiodes, SetAnalogDiodes] = React.useState<[Diode<number>, Diode<number>?][]>([]);
  const Server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseInPutDiodes(data.data);
    let analogDiodes = ParseAnalogInPutDiodes(data.data);
    SetDiodes(diodes);
    SetAnalogDiodes(analogDiodes);
  };
  useEffect(() => {
    Server.on_telemtry(ParseAndSetdiodes);
    return () => Server.off_telemtry(ParseAndSetdiodes);
  }, [Server]);
  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"26vh"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (
    <div className="overflow-y-scroll scroll h-[26vh]  grid-cols-2 justify-between flex-col flex  min-w-full ">
      {Diodes.map((diode) => {
        return (
          <div className=" grid-cols-2 flex justify-between ">

            <DiodeView diode={diode[0]}></DiodeView>
            <DiodeView diode={diode[1]}></DiodeView>
          </div >

        );
      })}
      {analogDiodes.map((diode) => {

        return (<div className=" grid-cols-2 flex justify-between ">

          <AnalogDiodeView analogDiode={diode[0]}></AnalogDiodeView>

          <AnalogDiodeView analogDiode={diode[1]}></AnalogDiodeView>
        </div >

        )
      })

      }


    </div>
  );
}
function AnalogDiodeView({ analogDiode }: { analogDiode?: Diode<number> }): JSX.Element | null {
  if (analogDiode === undefined) {
    return null;
  }

  return (
    <div className="flex flex-grow grid-cols-3 justify-center">
      <div className="flex justify-center items-center">
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          {analogDiode.diode_name}
        </Typography>
      </div >

      <div className="flex justify-center items-center">
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          {analogDiode.diode_value}

        </Typography>
      </div >
      <div className="flex justify-center items-center">
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          V
        </Typography>
      </div>
    </div>
  );
}




function DiodeView({ diode }: { diode?: Diode<boolean> }): JSX.Element | null {
  if (diode === undefined) {
    return <div className="flex grid-cols-2 flex-grow basis-1/2">
      <div className="flex  justify-center items-center">
      </div >
      <div className="flex flex-grow  justify-center items-center">

      </div>

    </div>;
  }

  return (
    <div className="flex grid-cols-2 flex-grow basis-1/2">
      <div className="flex  justify-center items-center basis-1/2">
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          {diode.diode_name}
        </Typography>
      </div >

      <div className="flex flex-grow  justify-center items-center basis-1/2">
        {diode.diode_value ? <GreenLED></GreenLED> : <GrayLED></GrayLED>}
      </div>
    </div>
  );
}
