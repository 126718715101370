
import { Grid } from "@mui/material";
import { Image as ImageModel, UiElement } from "../../../Models/Menus"
import React from "react";


export default function Image({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return (<InnerImage image={element as ImageModel}></InnerImage>)
}

function InnerImage({ image }: { image: ImageModel }) {
    return (<div className="flex flex-grow h-full justify-center" ><img src={require("../../../../../shared/utils/images/" + image.imageFile)} alt={image.display_name}></img></div>)
}