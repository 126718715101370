import React, { useContext } from "react";
import { Dispatch, SetStateAction, createContext, useState } from "react";



export type Encoding = "Metric" | "Imperial"



const GlobalStateContext = createContext({
    encoding: {} as Partial<Encoding>,
    setEncoding: {} as Dispatch<SetStateAction<Partial<Encoding>>>,
  });
  

  export const EncodingProvider = ({
    children,
    value = "Metric" ,
  }: {
    children: React.ReactNode;
    value?: Partial<Encoding>;
  }) => {
    const [encoding, setEncoding] = useState(value);
    console.log(encoding);
    return (
      <GlobalStateContext.Provider value={{ encoding, setEncoding }}>
        {children}
      </GlobalStateContext.Provider>
    );
  };
  
  export const useEncodingState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
      throw new Error("useGlobalState must be used within a GlobalStateContext");
    }
    return context;
  };
  





const MetricToImperialName : Record<string,string> = {
    "bar" : "PSI",
    "°c" : "°F",
    "bar/sec": "PSI/sec",
}


export function convertNameToEncoding (currentEncoding : Encoding,name ?: string  | null ) : string {
    if (!name) {
        return "";
    }
    if (currentEncoding === "Metric") {
        return name;
    }
    let imperial_name = MetricToImperialName[name.toLowerCase()];
    if (imperial_name === undefined) {
        return name;
    }
    return imperial_name;
    

}


const BAR_TO_PSI_RATIO = 14.5037738;
const CELCIUS_TO_FAHRENHEIT_RATION = 1.8;

const MetricToImperialValue : Record<string,(val : number) => number > = {
    "bar" : (val : number ) => val*BAR_TO_PSI_RATIO,
    "bar/sec": (val : number ) => val*BAR_TO_PSI_RATIO,

    "°c" : (val : number) => (val*CELCIUS_TO_FAHRENHEIT_RATION)+32,


}

export function convertValueToEncoding(currentEncoding : Encoding, value : number ,name ?: string | null) : number {
    if (!name) {
        return value;
    }
    if (currentEncoding === "Metric") {
        return value;
    }
    let imperal_converter = MetricToImperialValue[name.toLowerCase()];

    if (imperal_converter === undefined) {
        return value;
    }
    return  Math.trunc(imperal_converter(value));
    

}