import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  changeOwnRoomName,
  changeRoomName,
  deleteOwnRoom,
  deleteRoom,
} from "../../api/room.service";

export const useDeleteRoom = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return deleteRoom(data);
      else return deleteOwnRoom(data);
    },
  });
};
