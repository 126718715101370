import { Fragment, useState } from "react";
import MachineRow from "./MachineRow";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useChangeRoomName } from "hooks/room";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
export default function RoomRow({ room, machines }) {
  console.log("room", room);

  return (
    <Fragment key={room.name}>
      <RoomRowEdit data={room} />

      {machines.map((machine, machineIdx) => (
        <MachineRow
          key={machineIdx}
          machine={machine}
          machineIdx={machineIdx}
        />
      ))}
    </Fragment>
  );
}

const RoomRowEdit = ({ data }) => {
  console.log("%%%%%%%%datA", data);
  let { factoryId } = useParams();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  // const isPending = false;
  const {
    mutate: changeRoomName,
    isPending,
    isError,
    isSuccess,
  } = useChangeRoomName();
  const onSubmit = (newData) => {
    console.log(newData);
    clearErrors();
    if (errors["roomName"]) {
      setError("roomName", {
        type: "required",
        message: "Room name is required",
      });
      return;
    }
    changeRoomName(
      { RoomId: data.RoomId, name: newData.roomName },
      {
        onSuccess: (res) => {
          // console.log("factoryId", parseInt(factoryId));
          queryClient.setQueryData(
            ["factory", parseInt(factoryId), "rooms"],
            (oldData) => {
              if (!oldData) return oldData; // Prevent undefined errors

              // Assuming `oldData` contains a list of rooms, update the specific room
              const updatedData = {
                ...oldData, // Shallow copy oldData
                data: {
                  ...oldData.data, // Shallow copy oldData.data
                  rooms: oldData.data.rooms.map((room) => {
                    if (room.id === data.RoomId) {
                      return { ...room, name: newData.roomName }; // Shallow copy room and update name
                    }
                    return room; // Return room unchanged if it doesn't match
                  }),
                },
              };

              // Return the updated data to replace the cache
              return updatedData;
            },
          );

          // Update the machines cache similarly
          queryClient.setQueryData(
            ["machines", parseInt(factoryId)],
            (oldData) => {
              if (!oldData) return oldData; // Prevent undefined errors

              // Assuming `oldData` contains a list of machines, update the relevant machine's room
              const updatedMachines = {
                ...oldData, // Shallow copy oldData
                data: {
                  ...oldData.data, // Shallow copy oldData.data
                  machines: oldData.data.machines.map((machine) => {
                    if (machine.Room && machine.Room.id === data.RoomId) {
                      // If the RoomId matches, update the room name immutably
                      return {
                        ...machine, // Copy the machine object
                        Room: { ...machine.Room, name: newData.roomName }, // Update the Room's name
                      };
                    }
                    return machine; // Return the machine unchanged if no match
                  }),
                },
              };

              // Return the updated machines data to replace the cache
              return updatedMachines;
            },
          );

          setIsEditing(false);
        },
        onError: () => {
          setError("roomName", {
            type: "required",
            message: "Room name is required",
          });
        },
      },
    );
  };
  return (
    <tr key={data.name} className="border-t border-gray-200">
      <td
        colSpan={4}
        scope="colgroup"
        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
      >
        <form>
          {isEditing ? (
            <div className="flex items-center">
              <input
                id={data.RoomId}
                name={data.name}
                type={"text"}
                required={true}
                defaultValue={data.name}
                disabled={isPending}
                {...register("roomName", { required: true })}
                className={`block w-1/2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-60 sm:w-64 ${
                  errors["roomName"]
                    ? "ring-red-500 focus:ring-red-500"
                    : "ring-gray-300 focus:ring-blue-500"
                } sm:text-sm sm:leading-6`}
              />
              {isPending && (
                <div
                  className="top-1.5 ml-2 inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          ) : (
            data?.name + " Room"
          )}
        </form>
      </td>
      <td
        colSpan={1}
        className="relative w-32  whitespace-nowrap bg-gray-50 p-2 text-right text-sm font-medium sm:p-4"
      >
        <div className="flex space-x-2 ">
          <button
            className="ml-auto text-sky-600 hover:text-sky-900"
            onClick={() => {
              if (isEditing) {
                handleSubmit(onSubmit)();
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </button>
          {isEditing && (
            <button
              type="button"
              className="text-red-600 hover:text-red-900"
              onClick={() => {
                setIsEditing(false);
                setValue("roomName", data.name);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

// function DropDownMenu() {
//   return (
//     <Menu as="div" className="relative inline-block text-left">
//       <div>
//         <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
//           <span className="sr-only">Open options</span>
//           <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
//         </MenuButton>
//       </div>

//       <MenuItems
//         transition
//         className="absolute right-0 sm:right-14 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
//       >
//         <div className="py-1">
//           <MenuItem>
//             <a
//               href="#"
//               className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
//             >
//               Delete
//             </a>
//           </MenuItem>
//           <MenuItem>
//             <a
//               href="#"
//               className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
//             >
//               Edit
//             </a>
//           </MenuItem>
//         </div>
//       </MenuItems>
//     </Menu>
//   );
// }
