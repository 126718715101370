import { ErrorLogEntry } from './ErrorLogEntry';
import { ErrorTypes } from './ErrorTypes';



export type ErrorDescription = {
    Causes: string[],
    Remedies: string[],
    ErrorMessage: string
}


export function CreateErrorEntries(errorLogs: Record<string, number>, config: Record<string, any>): ErrorLogEntry[] {
    if (Object.keys(config).length == 0) {
        return [];
    }

    let parsedErrors: ErrorLogEntry[] = [];
    for (const errorKey in errorLogs) {
        let Errors = GetErrors(errorKey, errorLogs);
        for (let error of Errors) {
            let errorName = error[0];
            let errorDescription = new ErrorLogEntry(Date.now(), config.ErrorDescription[errorName], [errorKey, error[1]], errorKey);
            parsedErrors.push(errorDescription);
        }

    }
    return parsedErrors;



}
function GetErrors(errorKey: string, errorLogs: Record<string, number>): [string, number][] {
    //checks all the different bitfields in the enum to get 
    let errorKeys: [string, number][] = [];
    let errorType = ErrorTypes[errorKey];
    let errorValue = errorLogs[errorKey];

    for (let property in errorType) {

        if ((errorValue & errorType[property]) !== 0)
            errorKeys.push([property, errorValue]);
    }
    return errorKeys;
}


