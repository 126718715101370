import Grid from "@mui/material/Grid";
import React, { useEffect, } from "react";
import { GetPopUpBarListener, PopUpBarType } from "../Models/HistoricViewlistener";
import { Menu as MenuModel, Menus, SubScreen as SubScreenModel, UiElement } from "../Models/Menus";
import { GetServer } from "../Models/ServerCommuncation/Server";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import DashBoard from "./DashBoard/Dashboard";
import DiodeBox from "./DashBoard/diodebox";
import InfoBox from "./DashBoard/infobox";
import ShowTimeErrorBarView from "./HistoricView/ErrorHistoryViewAlternative";
import HistoricUpTimeView from "./HistoricView/UpTimeHistoryView";
import MenuView from "./Menu";
import { TelemtricBottomView } from "./uiElements/noninteractive/TelemtryView.tsx/telemtricview";
//@ts-ignore
import displayImage from "shared/utils/images/DisplayBorder.png";
import { ErrorLogEntry } from "../Models/Errorlog/ErrorLogEntry";
import { DisplayInverters } from "./DashBoard/TripCounterBox";




function ScreenWrapper({ children }: { children?: React.ReactNode | React.ReactNode[] }) {
  return <div className="flex flex-grow  sm:text-[6px]  md:text-[8px] lg:text-[9px] xl:text-[10px]  900p:text-[13px]  1080p:text-[15px] overflow-auto  grid-row-2 min-h-[800px]  min-w-full  flex-col    sm:w-[640px]      md:w-[768px]        xl:w-[100%]  h-full  ">
    {children}
  </div>
}
export default function Screen({ machineConfig }: { machineConfig: Record<string, any> }) {



  return (
    <ScreenWrapper>

      <div className=" text-[length:inherit] flex min-h-0 flex-grow basis-9/12 pl-[1vh] pb-[1vh] pr-[1vh]  gap-x-1  ">
        <div className=" text-[length:inherit]  flex grid-cols-2 flex-grow  flex-col justify-between content-center" >
          <Grid item xs container direction={"row"} height={"100%"} >
            <Grid item xs container direction="column" height={"100%"} justifyContent={"space-evenly"}>
              <Grid item xs={4} container justifyContent={"start"} height={"50%"} alignContent={"start"}>
                <Grid item xs={10} container >
                  <InfoBox></InfoBox>
                </Grid>
              </Grid>

              <Grid item xs={4} container justifyContent={"start"} height={"50%"} alignContent={"start"}>
                <Grid item xs={10} container >
                  <DisplayInverters></DisplayInverters>
                </Grid>
              </Grid>


            </Grid>

            <div className=" text-[length:inherit]  flex justify-center basis-6/12 relative h-full items-center aspect-h-6 "  ><DeviceView machineConfig={machineConfig}></DeviceView> </div>
            <Grid item xs container direction="column" spacing={1} sx={{ paddingLeft: "1vmax" }} justifyContent={"center"}>

              <Grid item xs={3} container justifyContent={"center"} alignContent={"start"} >
                <Grid item xs={10}>
                  <DiodeBox></DiodeBox>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
          <div className="flex items-start justify-center space-x-2 basis-4/12 "  >
            <div className="flex basis-1/2 h-full text-[length:inherit] ">
              <TelemtricBottomView defaultKey={"PressureOUT"}></TelemtricBottomView>
            </div>
            <div className="flex basis-1/2 h-full text-[length:inherit]">
              <TelemtricBottomView defaultKey={"PressureIN"}></TelemtricBottomView>
            </div>
          </div>
        </div>
        <div className="flex basis-3/12 text-[length:inherit] "><DashBoardView ShowTelemetry={true} ></DashBoardView></div>
      </div>
      <div className="  flex  bg-[#001b54]  " >
        <BarTimeView></BarTimeView>
      </div>
    </ScreenWrapper>
  );
}


function DeviceView({ machineConfig }: { machineConfig: Record<string, any> }) {

  const Server = GetServer();
  const [menuData,] = React.useState(new Menus(machineConfig));
  const [Menu, SetMenu] = React.useState<null | MenuModel>(null);
  const [subScreen, SetSubScreen] = React.useState<null | SubScreenModel>(null);
  const [IsUpdating, SetIsUpdating] = React.useState<boolean>(false);
  let prevTelemtry = React.useRef<null | DatedIotData>(null);
  let currTelemetry = React.useRef<null | DatedIotData>(null);
  let isUpdatingRef = React.useRef<boolean>(IsUpdating);



  function GetUiElements(): UiElement[] {
    return machineConfig.globalUiElements as UiElement[];
  }

  function SetScreenUpdating() {
    SetIsUpdating(true);
    prevTelemtry.current = currTelemetry.current;
    isUpdatingRef.current = true;

    setTimeout(() => {
      if (isUpdatingRef.current === true) {

        GetServer().SendCommand("SetNavigatorButton", "GoBack", () => { });

      }
    }, 35000)
  }
  function CheckTelemtryData(curr: DatedIotData): boolean {
    if (prevTelemtry.current == null) {
      return false;
    }

    const keys = ["CurrentSubMenuNo", "CurrentMenuNo", "PressureAtButton", "MachineStandby", "BarButtonSelected"];

    for (const key of keys) {

      if (curr.data[key] !== prevTelemtry.current!.data[key]) {

        return true;
      }
    }
    return false;
  }

  function TelemtryCallBack(telemtryData: DatedIotData) {

    currTelemetry.current = telemtryData;

    if (subScreen?.SubScreenName !== telemtryData.data.CurrentSubMenuNo || Menu?.menuNo !== telemtryData.data.CurrentMenuNo || !subScreen) {
      let menu = menuData?.GetMenu(telemtryData.data.CurrentMenuNo.toString());
      if (!menu) {
        SetMenu(null);
        return;
      }
      let subscreen = menu.GetSubScreen(telemtryData.data.CurrentSubMenuNo.toString());
      SetMenu(menu);

      SetSubScreen(subscreen);
    }
    if (IsUpdating && CheckTelemtryData(telemtryData)) {
      SetIsUpdating(false);
      isUpdatingRef.current = false;
    }
  }

  useEffect(() => {
    Server.on_telemtry(TelemtryCallBack);

    return () => {
      Server.off_telemtry(TelemtryCallBack);
    };
  });


  return (<>
    <img className="text-[length:inherit]  flex w-full  border-none absolute" src={displayImage} alt="NilfiskFood" />
    <MenuView menu={Menu} subScreen={subScreen} screenUpdating={IsUpdating} globalUiElements={GetUiElements()} ScreenUpdate={SetScreenUpdating}></MenuView>
  </>)
}

type BarUpView = (arg?: string[], errologEntries?: ErrorLogEntry[]) => JSX.Element;
function BarTimeView(): JSX.Element {
  const BarMap: Record<PopUpBarType, BarUpView> = {
    ErrorTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return <ShowTimeErrorBarView displayname={arg![0]} errorName={arg![1]} ErrorLogsList={errologEntries!}></ShowTimeErrorBarView>;
    },
    UpTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return <HistoricUpTimeView></HistoricUpTimeView>;
    }
  };
  const [BarView, SetBarView] = React.useState<JSX.Element>(<HistoricUpTimeView></HistoricUpTimeView>);
  function ChangeBarUpView(type: PopUpBarType, val?: string[], errologEntries?: ErrorLogEntry[]) {
    const Component = BarMap[type](val, errologEntries);
    SetBarView(Component);
  }
  useEffect(() => {
    let listener = GetPopUpBarListener();
    listener.addListener(ChangeBarUpView);
    return () => {
      listener.removeListener(ChangeBarUpView);
    };
  }, []);
  return BarView;
}

function DashBoardView({ ShowTelemetry }: { ShowTelemetry: boolean; }) {
  if (!ShowTelemetry) {
    return null;
  }

  return (

    <DashBoard></DashBoard>
  );
}
