function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}
const decryptErrorCodes = (errorCode1, errorCode2, errorCode3, guiErrors1, guiErrors2) => {
  const arrayOfErrors = [];

  // Define the error code checks for errorCode1
  const errorDefinitions1 = [
    { bit: 1, name: "Motor temperature high", codeName: "Pt1HighTempBit", description: "1.Insufficient cooling of motor\n2. The ambient temperature is above 40°C" },
    { bit: 2, name: "Water temperature high", codeName: "Pt2HighTempBit", description: "1. Water pump top temperature is above 70°C\n2. Water consumption is too low (pump column has been overheated)" },
    { bit: 4, name: "Pt3HighTempBit", description: "Default not used" },
    { bit: 8, name: "Pt1LowTempBit", description: "Default not used" },
    { bit: 16, name: "Water temperature low", codeName: "Pt2LowTempBit", description: "1. Water supply temperature is below limit" },
    { bit: 32, name: "Pt3LowTempBit", description: "Default not used" },
    { bit: 64, name: "Tank empty (Inverter)", codeName: "TankControlLow", description: "1. Insufficient water supply\n2. Water supply valve not open" },
    { bit: 128, name: "Suction Lance 1, empty (Inverter)", codeName: "SuctionLance1Empty", description: "Level of product in chemistry canister for product 1 is insufficient" },
    { bit: 256, name: "Suction Lance 2, empty (Inverter)", codeName: "SuctionLance2Empty", description: "Level of product in chemistry canister for product 2 is insufficient" },
    { bit: 512, name: "No Communication", codeName: "MbAliveError", description: "No Communication between Display and Inverter" }
  ];

  // Check each bit for errorCode1
  errorDefinitions1.forEach(def => {
    if (errorCode1 & def.bit) {
      arrayOfErrors.push({ type: "error", name: def.name, codeName: def.codeName, description: def.description });
    }
  });

  // Define and check error code checks for errorCode2
  const errorDefinitions2 = [
    { bit: 1, name: "Low Supply pressure", codeName: "P1_PresssureLowBit", description: "Insufficient water supply" },
    { bit: 2, name: "Low Sensor Signal inlet sensor", codeName: "P1_SignalLowBit", description: "1. Pressure signal from inlet sensor, out of range" },
    { bit: 4, name: "Sensor Load high Inlet Sensor", codeName: "P1_HighLoad", description: "Inlet sensor using too much current" },
    { bit: 8, name: "Inlet sensor load low", codeName: "P1_LowLoad", description: "Current consumption from inlet sensor low" },
    { bit: 16, name: "P2_PresssureLowBit", description: "1. Water consumption too high\n2. Inverter is running at max" },
    { bit: 32, name: "Low Sensor Signal outlet sensor", codeName: "P2_SignalLowBit", description: "1. Pressure signal from outlet sensor, out of range" },
    { bit: 64, name: "Sensor Load high Outlet Sensor", codeName: "P2_HighLoad", description: "Outlet sensor using too much current" },
    { bit: 128, name: "Outlet sensor load low", codeName: "P2_LowLoad", description: "Current consumption from Outlet sensor low" },
    { bit: 256, name: "P3_PresssureLowBit", description: "-" },
    { bit: 512, name: "P3_SignalLowBit", description: "-" },
    { bit: 1024, name: "P3_HighLoad", description: "-" },
    { bit: 2048, name: "P3_LowLoad", description: "-" },
    { bit: 4096, name: "Flowswitch using too much current", codeName: "Flow_HighLoad", description: "Flowswitch using too much current" },
    { bit: 8192, name: "Flowswitch Load low", codeName: "Flow_LowLoad", description: "Flowswitch current consumption is under limit" }
  ];

  // Check each bit for errorCode2
  errorDefinitions2.forEach(def => {
    if (errorCode2 & def.bit) {
      arrayOfErrors.push({ type: "error", name: def.name, codeName: def.codeName, description: def.description });
    }
  });

  // Define and check error code checks for errorCode3
  const errorDefinitions3 = [
    { bit: 1, name: "Current phase 1", codeName: "currErrPhase1", description: "Overcurrent on motor phase 1" },
    { bit: 2, name: "Current phase 2", codeName: "currErrPhase2", description: "Overcurrent on motor phase 2" },
    { bit: 4, name: "Current phase 3", codeName: "currErrPhase3", description: "Overcurrent on motor phase 3" },
    { bit: 8, name: "Instantaneous motor sum current has been too high", codeName: "sumCurrErr", description: "1. In-balance between motor-currents\n2. Current leaking to Earth, Ground Fault." },
    { bit: 16, name: "Supply low. Weak supply", codeName: "dcLowVoltErr", description: "Power to inverter too low" },
    { bit: 32, name: "Supply too high", codeName: "HighVoltErr", description: "Supply out of range" },
    { bit: 64, name: "Current too high", codeName: "hwOverCurrErr", description: "Short circuit between motor-current cable and earth or other motor-current cable." },
    { bit: 128, name: "Safe Torque off", codeName: "safeTorqueOff", description: "Emergency circuit has been opened/broken" },
    { bit: 256, name: "Inverter Module Temperature too high", codeName: "moduleTempToHigh", description: "1. Water consumption too high\n2. Ambient temperature too high" },
    { bit: 512, name: "moduleTempHigh (not shown)", description: "not used" },
    { bit: 1024, name: "Powerlimit (not shown)", description: "not used" },
    { bit: 2048, name: "Serial no. or Rev. no. not correct", codeName: "hwRevError", description: "1. No.'s has not beed read correct\n2.No. not written to memory " }
  ];

  // Check each bit for errorCode3
  errorDefinitions3.forEach(def => {
    if (errorCode3 & def.bit) {
      arrayOfErrors.push({ type: "error", name: def.name, codeName: def.codeName, description: def.description });
    }
  });

  const guiErrorDefinitions1 = [
    { bit: 1, name: "SuctionLance1_Empty", codeName: "SuctionLance1_Empty", description: "" },
    { bit: 2, name: "SuctionLance2_Empty", codeName: "SuctionLance2_Empty", description: "-" },
    { bit: 4, name: "TankEmpty", codeName: "TankEmpty", description: "-" },
    { bit: 8, name: "SuctionLance3_Empty", codeName: "SuctionLance3_Empty", description: "-" },
    { bit: 16, name: "ExternalError", codeName: "ExternalError", description: "-" },
    { bit: 32, name: "SuctionLanceGlobal_Empty", codeName: "SuctionLanceGlobal_Empty", description: "-" },
    { bit: 64, name: "SafetyActive", codeName: "SafetyActive", description: "-" },
    { bit: 128, name: "JsonError", codeName: "JsonError", description: "-" },
    { bit: 256, name: "ComErrorIOBox_Box1", codeName: "ComErrorIOBox_Box1", description: "-" },
    { bit: 512, name: "ComErrorIOBox_Box2", codeName: "ComErrorIOBox_Box2", description: "-" },
    { bit: 1024, name: "ComErrorIOBox_Box3", codeName: "ComErrorIOBox_Box3", description: "-" },
    { bit: 2048, name: "AreaValveActivatedTimeout", codeName: "AreaValveActivatedTimeout", description: "-" },
    { bit: 4096, name: "ReleaseMachine", codeName: "ReleaseMachine", description: "-" },
    { bit: 8192, name: "BlockMachine", codeName: "BlockMachine", description: "-" },
    { bit: 16384, name: "ExpectedFlow", codeName: "ExpectedFlow", description: "-" },
    { bit: 32768, name: "MinPortCurrent", codeName: "MinPortCurrent", description: "-"}

  ];

  // Check each bit for errorCode3
  guiErrorDefinitions1.forEach(def => {
    if (guiErrors1 & def.bit) {
      arrayOfErrors.push({ type: "error", name: def.name, codeName: def.codeName, description: def.description });
    }
  });


  const guiErrorDefinitions2 = [
    { bit: 1, name: "MaxPortCurrent", codeName: "MaxPortCurrent", description: "-" },
    { bit: 2, name: "MachineLocked", codeName: "MachineLocked", description: "-" },

  ];

  // Check each bit for errorCode3
  guiErrorDefinitions2.forEach(def => {
    if (guiErrors2 & def.bit) {
      arrayOfErrors.push({ type: "error", name: def.name, codeName: def.codeName, description: def.description });
    }
  });
  return arrayOfErrors;
};

const decryptWarningCodes = (warningCode1, warningCode2, guiWarningCode1) => {
  const arrayOfWarnings = [];

  const warningDefinitions1 = [
    { bit: 1, name: "Inlet Pressure Low", codeName: "P1_LowWarningBit", description: "Insufficient water supply. The machine will keep on working, but the maximum speed of the pump will be reduced until sufficient inlet pressure is present" },
    { bit: 2, name: "P1_HighLimitUpBit", description: "-" },
    { bit: 4, name: "P1_HighLimitLowBit", description: "-" },
    { bit: 8, name: "Not used", description: "Not used" },
    { bit: 16, name: "P2_HighLimitUpBit", description: "-" },
    { bit: 32, name: "P2_HighLimitLowBit", description: "Outlet pressure low" },
    { bit: 64, name: "P3_LowWarningBit", description: "-" },
    { bit: 128, name: "P3_HighLimitUpBit", description: "-" },
    { bit: 256, name: "P3_HighLimitLowBit", description: "-" },
    { bit: 512, name: "High Tank Level (Inverter)", codeName: "TankControlHigh", description: "Water supply valve not closed" },
    { bit: 1024, name: "Suction Lance 1, Low (Inverter)", codeName: "SuctionLance1Low", description: "Level of product in chemistry canister for product 1 is low" },
    { bit: 2048, name: "Suction Lance 2, low (Inverter)", codeName: "SuctionLance2Low", description: "Level of product in chemistry canister for product 2 is low" },
    { bit: 4096, name: "Inverter Module Temperature High", codeName: "ModuleHighTempBit", description: "1. Water consumption too high\n2. Ambient temperature too high" },
    { bit: 8192, name: "Inverter Board Temperature High", codeName: "BoardHighTempBit", description: "1. Water consumption too high\n2. Ambient temperature too high\n3. Load at Board high" },
    { bit: 16384, name: "Powerlimit", description: "Max power for inverter, power limit activated" },
    { bit: 32768, name: "Leakage start", codeName: "Leakage start", description: "1. Booster start condition changed to flow, due to leakage in pipe" }
  ];

  // Check warningCode1 bits
  warningDefinitions1.forEach(def => {
    if (warningCode1 & def.bit) {
      arrayOfWarnings.push({ type: "warning", name: def.name, codeName: def.codeName, description: def.description });
    }
  });

  // Check warningCode2 bits (only one warning in this case)
  if (warningCode2 & 1) {
    arrayOfWarnings.push({
      type: "warning",
      name: "Critical inlet pressure",
      codeName: "Dry_run_reset",
      description: "1. Insufficient water supply\n2. Booster will return to standby mode after 20 sec. If this error occurs 3 times within 20 min. Pump will stop."
    });
  }


  const guiWarningDefinitions1 = [
    { bit: 1, name: "SuctionLance1_Low", codeName: "SuctionLance1_Empty", description: "-" },
    { bit: 2, name: "SuctionLance2_Low", codeName: "SuctionLance2_Empty", description: "-" },
    { bit: 4, name: "TankHighLevel", codeName: "TankEmpty", description: "-" },
    { bit: 8, name: "SuctionLance3_Low", codeName: "SuctionLance3_Empty", description: "-" },
    { bit: 16, name: "ReleaseHoldWashProgram", codeName: "ExternalError", description: "-" },
    { bit: 32, name: "ReleaseWashProgram", codeName: "SuctionLanceGlobal_E", description: "-" },
    { bit: 64, name: "SuctionLanceGlobal_Low", codeName: "SafetyActive", description: "-" },
    { bit: 128, name: "JSON_BackToMem", codeName: "JsonError", description: "-" },
    { bit: 256, name: "JSON_BackToDef", codeName: "ComErrorIOBox_Box1", description: "-" },
    { bit: 512, name: "PauseTimeOut", codeName: "ComErrorIOBox_Box2", description: "-" },
    { bit: 1024, name: "AzureKeyError", codeName: "ComErrorIOBox_Box3", description: "-" },
  ];

  // Check warningCode1 bits
  guiWarningDefinitions1.forEach(def => {
    if (guiWarningCode1 & def.bit) {
      arrayOfWarnings.push({ type: "warning", name: def.name, codeName: def.codeName, description: def.description });
    }
  });
  return arrayOfWarnings;
};

const decryptMixInputs = (mixInput) => ({
  highLimitTank: !!(mixInput & 1),         // First bit
  tankControlHigh: !!(mixInput & 2),       // Second bit
  lowLevelTank: !!(mixInput & 4),          // Third bit
  lowLevelAlarmTank: !!(mixInput & 8)      // Fourth bit
});

const decryptMixOutputs = (mixOutput) => ({
  unknown1: !!(mixOutput & 1),        // First bit
  unknown2: !!(mixOutput & 2),        // Second bit
  waterValve: !!(mixOutput & 4)       // Third bit
});

module.exports = {
  decryptErrorCodes,
  decryptWarningCodes,
  decryptMixOutputs,
  decryptMixInputs,
};
