import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings() {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { name: "General", url: "general", current: true },
    { name: "Password", url: "password", current: false },
    { name: "Notifications", url: "notifications", current: false },
    // { name: "Plan", url: "plan", current: false },
    // { name: "Billing", url: "billing", current: false },
  ].map((item) => ({
    ...item,
    current: location.pathname.includes(item.url),
  }));

  return (
    <>
      <div>
        <div className=" ">
          <div className="mx-auto bg-white sm:rounded-2xl flex flex-col lg:max-w-4xl ring-1 ring-gray-900/5 ">
            <main className="flex-1">
              <div className="relative mx-auto max-w-4xl">
                <div className="pb-16">
                  {/* <div className="px-4 sm:px-6">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Settings</h1>
                  </div> */}
                  <div className="px-4 sm:px-6 ">
                    <div className="py-2">
                      {/* Tabs */}
                      <div className="lg:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                          defaultValue={tabs.find((tab) => tab.current)?.name}
                          onChange={(e) => {
                            const selectedTab = tabs.find((tab) => tab.name === e.target.value);
                            if (selectedTab) {
                              navigate(selectedTab.url);
                            }
                          }}
                        >
                          {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden lg:block">
                        <div className="border-b border-gray-200">
                          <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab) => (
                              <Link
                                key={tab.name}
                                to={tab.url}
                                className={classNames(
                                  tab.current
                                    ? "border-blue-500 text-blue-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                                )}
                              >
                                {tab.name}
                              </Link>
                            ))}
                          </nav>
                        </div>
                      </div>

                      {/* the content inside settings */}
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
