import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Button as ButtonModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";
import { convertNameToEncoding, convertValueToEncoding, useEncodingState } from "pages/Navigator/Models/DataEncoding";

export default function Button({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {

  return <InnerButton button={element as ButtonModel} ScreenUpdate={ScreenUpdate} disabled={disabled}></InnerButton>;
}


function ButtonWrapper({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
  return <div className={`flex justify-center  text-[1em] content-center bg-[darkblue]    h-[7em] w-[7em] border-[white] sm:rounded sm:border md:border-2 lg:border-2 xl:border-2 2xl:border-2`} >{children}</div>

}

function InnerButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  if (button.imageFile) return <ImgButton button={button}></ImgButton>;
  if (button.telemtryField) return <TelemtryButton button={button}></TelemtryButton>;
  if (button.command) return <CommandButton button={button} ScreenUpdate={ScreenUpdate} disabled={disabled}></CommandButton>;
  //default case

  return (
    <ButtonWrapper>
      <button >
        <p className="text-[1em] text-white cursor-default" >{button.display_name}</p>

      </button>
    </ButtonWrapper>
  );
}

function ImgButton({ button }: { button: ButtonModel }) {
  return (
    <button>
      <img src={"shared/uitls/images/" + button!.imageFile} alt={button.display_name} />
      {button.display_name}
    </button>
  );
}


function TelemtryButton({ button }: { button: ButtonModel }) {
  const Server = GetServer();

  const LatestData = Server.getLatestData();
  console.log(LatestData);
  let [val, setVal] = React.useState<number>(LatestData.data[button.telemtryField!]);

  const { encoding } = useEncodingState();
  const unit = convertNameToEncoding(encoding, button.display_name);
  function SetTelemetry(data: DatedIotData) {

    let local_val = data.data[button.telemtryField!] ? data.data[button.telemtryField!] : 0;
    let val = convertValueToEncoding(encoding, local_val, button.display_name);
    setVal(val);
  }
  useEffect(() => {

    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  // if (button.highlightable) {

  // }

  return (
    <ButtonWrapper>
      <button>
        <p className="text-[1em]" >{button.display_name}</p>
      </button>
    </ButtonWrapper>
  );
}

function CommandButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  const server = GetServer();
  function sendCommand() {
    if (disabled) {
      return;
    }
    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    if (arg_val) {
      server.SendCommand(`${name}`, `${arg_val}`, () => { });

      return;
    }

    server.SendCommand(name, null, () => { });
  }
  return (

    <ButtonWrapper>
      <div className="flex justify-center content-center" style={{ cursor: disabled ? "default" : "pointer" }}>
        <button onClick={() => {
          if (disabled) {
            return;
          }
          sendCommand();
          ScreenUpdate?.();
        }} className=" text-white">
          <p className="text-[1em]" >{button.display_name}</p>
        </button>
      </div>
    </ButtonWrapper>
  );
}

