export default function ErrorLogListSkeleton() {
  return (
    <div className="justify-between gap-x-4 ">
      <div className="w-full ">
        <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
          {/* <dl className="divide-y divide-gray-100"> */}
          <ul role="list" className="divide-y divide-gray-100 ">
            {[1, 2, 3, 4, 5].map((logItem, index) => (
              <li
                key={index}
                className="relative flex cursor-pointer items-center space-x-4 px-4 py-4 hover:bg-gray-50"
              >
                <div className="min-w-0 flex-auto">
                  <div className="flex items-center gap-x-3">
                    <div className="flex-none rounded-full p-1">
                      <span class="block size-3 rounded-full bg-gray-200 "></span>
                    </div>
                    <h2 className="min-w-0 text-sm font-semibold leading-6 ">
                      <a className="flex gap-x-2">
                        {/* {console.log("logItem", logItem)} */}
                        <span className="truncate">
                          <p className="h-4 w-40 rounded-full bg-gray-200 "></p>
                        </span>
                        <span className="absolute inset-0" />
                      </a>
                    </h2>
                  </div>
                  <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-500">
                    <p className="h-3 w-40 rounded-full bg-gray-200 "></p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
