import React from "react";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmAccount } from "hooks/user";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

//TODO make it so after the account is confirmed to redirect to mobile app
const ConfirmingAccount = () => {
  const navigate = useNavigate();
  let params = useParams();

  const {
    mutate: confirmAccount,
    isPending,
    isError,
    isSuccess,
    error,
  } = useConfirmAccount();

  React.useEffect(() => {
    confirmAccount(params);
  }, []);

  //TODO check if the methods bellow work
  function isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  function handleButtonClick() {
    if (isMobileDevice()) {
      // Try to open the mobile app using a deep link
      window.location.href = "nilfiskfoodhybrid://login"; // Replace 'yourappname' with your actual app scheme

      // If the app is not installed or doesn't handle the deep link, you can add a fallback
      // after a short delay (e.g., redirecting to the app store or showing an informative message).
      setTimeout(() => {
        // Redirect to app store or show a message
      }, 500);
    } else {
      // Redirect to login page for computer users
      window.location.href = "/login"; // Replace with your actual login URL
    }
  }
  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center bg-nilfisk py-12 sm:px-6 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img
            className="mx-auto mb-6 w-auto"
            src={nilfiskFoodLogo}
            alt="Nilfisk Food"
          />
          <div className="space-y-6">
            {isSuccess ? (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="size-5 text-green-400"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Account Confirmed, you can now log in
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {isError || error ? (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <XCircleIcon
                      aria-hidden="true"
                      className="size-5 text-red-400"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      The account could not be confirmed, the link might have
                      expired
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div>
              <button
                type="submit"
                onClick={() => navigate("/login")}
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Go to login
              </button>
              {isPending ? (
                <div className="flex justify-center">
                  <div className="mt-4 h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-300 border-t-transparent"></div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmingAccount;
