import React, { useState } from "react";
import Breadcrumbs from "components/Breadcrumbs";
import CreateFactorySlidePanel from "../create/CreateFactorySlidePanel";
import FactoriesTable from "pages/Factory/Components/TableFactories/FactoriesTable";
import { useSearchOwnFactoriesQuery } from "hooks/factory";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { debounce } from "lodash";

export default function ViewFactories(props) {
  const [createFactoryOpen, setCreateFactoryOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchData, setSearchData] = useState({
    searchText: "",
    page,
    pageSize,
    countries: [],
    errorFilter: [
      { name: "Errors", selected: false },
      { name: "New Errors", selected: false },
      { name: "Warnings", selected: false },
      { name: "New Warnings", selected: false },
    ],
  });

  const { data, isLoading, isFetching, isPlaceholderData } =
    useSearchOwnFactoriesQuery(searchData);
  console.log("isPlaceholderData", isPlaceholderData);
  const debouncedHandleSearchTextChange = debounce((text) => {
    handleSearchTextChange(text);
  }, 500);

  const handleSearchTextChange = (text) => {
    console.log("searchText", text);
    const newData = {
      searchText: text,
      page,
      pageSize,
      countries: [],
      errorFilter: [
        { name: "Errors", selected: false },
        { name: "New Errors", selected: false },
        { name: "Warnings", selected: false },
        { name: "New Warnings", selected: false },
      ],
    };
    setSearchData(newData);
  };

  const onInputChange = (event) => {
    const text = event.target.value;
    debouncedHandleSearchTextChange(text);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSearchData((prevSearchData) => ({ ...prevSearchData, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      pageSize: newPageSize,
    }));
  };

  const pages = [{ name: "Factories", url: "factories", current: true }];
  return (
    <div className="mx-auto max-w-7xl">
      <CreateFactorySlidePanel
        createFactoryOpen={createFactoryOpen}
        setCreateFactoryOpen={setCreateFactoryOpen}
      />
      <Breadcrumbs pages={pages} />
      <div className="mx-2 mb-4 mt-4 flex items-center justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Factories
          </h2>
        </div>
        <div className="flex flex-shrink-0">
          <button //TODO create the new method for creating factories
            type="button"
            onClick={() => setCreateFactoryOpen(true)}
            className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
          >
            Create Factory
          </button>
        </div>
      </div>
      <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5  sm:rounded-2xl">
        <div className="px-4 pt-6  sm:px-0">
          <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 sm:px-6 lg:grid-cols-7 xl:grid-cols-9">
            <div className="sm:col-span-3">
              <div className="relative mb-4 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  onChange={onInputChange}
                  className="block w-full rounded-xl border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="search..."
                />
              </div>
            </div>
          </div>
          <FactoriesTable
            data={data?.data?.factories}
            isLoading={isLoading}
            isFetching={isFetching}
            isPlaceholderData={isPlaceholderData}
            page={page}
            setPage={handlePageChange}
            pageSize={pageSize}
            setPageSize={handlePageSizeChange}
            totalRows={data?.data?.count || 0}
          />
        </div>
      </div>
    </div>
  );
}
