import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { changeOwnRoomName, changeRoomName } from "../../api/room.service";

export const useChangeRoomName = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return changeRoomName(data?.RoomId, data?.name);
      else return changeOwnRoomName(data?.RoomId, data?.name);
    },
  });
};
