//this is the next task.

import React, { useEffect } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import ErrorLogView from "./ErrorLogView";
import SettingsView from "./Settings";
import TelemtryDataView from "./TelemetryView";
import { useWindowDimensions } from "pages/Navigator/Models/dashBoardUtilities";
type dashBoardMenuType = "telemetry" | "errorlog" | "settings";

type dashBoardMenuView = () => JSX.Element | null;

const DashBoardMenuViews: Record<dashBoardMenuType, dashBoardMenuView> = {
  telemetry: TelemtryDataView,
  errorlog: ErrorLogView,
  settings: SettingsView,
};
export default function DashBoard() {
  const [dashBoardMenu, setDashBoardMenu] = React.useState<dashBoardMenuType>("telemetry");
  let DashBoardMenuView = DashBoardMenuViews[dashBoardMenu];
  const server = GetServer();
  const listener = GetPopUpBarListener();


  return (
    <div className="grid-cols-2 flex-col flex min-h-0 flex-grow  text-[length:inherit]">
      <div className="grid grid-cols-3  text-[length:inherit] ">
        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="telemetry"

          onClick={() => {
            if (!server.machineIsRebooting()) {
              listener.callListeners("UpTime");
              setDashBoardMenu("telemetry");
            }
          }}
        ></DashBoardMenuButton>

        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="errorlog"
          onClick={() => {
            if (!server.machineIsRebooting()) {
              setDashBoardMenu("errorlog");
            }
          }}
        ></DashBoardMenuButton>
        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="settings"
          onClick={() => {
            if (!server.machineIsRebooting()) {
              listener.callListeners("UpTime");
              setDashBoardMenu("settings");

            }
          }}
        ></DashBoardMenuButton>
      </div>
      <div className="flex min-h-0 w-[25vw] h-full text-[length:inherit]">
        <DashBoardMenuView></DashBoardMenuView>
      </div>
    </div>
  );
}

function DashBoardMenuButton({ name, dashBoardMenu, onClick }: { name: dashBoardMenuType; dashBoardMenu: dashBoardMenuType; onClick: () => void }): JSX.Element {
  if (dashBoardMenu === name) {
    return (
      <div className="flex flew-grow bg-[#001b54]" >
        <Button fullWidth onClick={onClick}>
          <Typography color={"white"} variant="button">
            {name}
          </Typography>
        </Button>
      </div>
    );

  }

  //focus-visible:outline-blue-500
  return (
    <div className="flex w-full h-full items-center">
      <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

      <div className="  flex h-full  flew-grow w-full text-[100%]   items-center border-l text-sm font-semibold   hover:bg-[#42a5f5] hover:bg-opacity-10  ">
        <p className="flex h-full items-center w-full cursor-pointer justify-center uppercase  text-sm font-semibold text-[#1976d2] text-[1em] " onClick={onClick}>
          {name}
        </p>
      </div>
    </div>

  );
}
