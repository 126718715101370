import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { UiElement, StateInfoField as infoFieldModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function StateInfoField({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return <InnerStateInfoField stateInfoField={element}></InnerStateInfoField>;
}
function InfoFieldWrapper({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
  return <div className=" flex flex-grow min-w-0 h-full pb-1.5 pt-1.5 ">{children}</div>
}
function InnerStateInfoField({ stateInfoField }: { stateInfoField: infoFieldModel }) {
  if (stateInfoField.telemtryField) {
    return <TelemetryInfoField stateInfoField={stateInfoField} ></TelemetryInfoField>;
  }

  return (
    <InfoFieldWrapper>
      <div className="basis-6/12 flex justify-start bg-white bottom-0 top-auto rounded-tl-[2vmax] rounded-bl-[2vmax] ">
        <p className="text-[1em] text-black flex h-full items-center bottom-0">
          {stateInfoField.display_name}

        </p>
      </div>

      <div className="basis-6/12 flex justify-start border-l border-black bg-white bottom-0 top-auto rounded-tl-[2vmax] rounded-bl-[2vmax] ">
        <p className="text-[1em] text-black flex h-full items-center bottom-0">
          {stateInfoField.fieldVal}

        </p>
      </div>

    </InfoFieldWrapper>
  );
  //default case
}

enum ColorState {
  Green,
  Red,
}

function GetColor(isOn: boolean): ColorState {
  return isOn ? ColorState.Green : ColorState.Red;
}
function TelemetryInfoField({ stateInfoField }: { stateInfoField: infoFieldModel; }) {
  const Server = GetServer();
  const LatestData = Server.getLatestData();
  let val = LatestData.data[stateInfoField.telemtryField!] as number > 300;
  let [color, setColor] = React.useState<ColorState>(GetColor(val));

  useEffect(() => {
    function SetTelemetry(data: DatedIotData) {
      let isOn = data.data[stateInfoField.telemtryField!] as number > 300;

      setColor(GetColor(isOn));
    }

    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, [Server, stateInfoField]);
  //   if (infoField.fieldUnit) {
  //     return (
  //       <Grid item container>
  //         <Grid item xs={12} container justifyContent="center" bgcolor="white" bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", borderBottomLeftRadius: "2vw", borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }}>
  //           <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>
  //             {val + " " + infoField.fieldUnit}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     );
  //   }
  if (color === ColorState.Green) {
    return (
      <InfoFieldWrapper>
        <div className="flex basis-6/12  h-full content-center bottom-0 top-auto ">
          <p className="text-[1em] flex items-center text-white  bottom-0">{stateInfoField.display_name} </p>
        </div>
        <div className={`flex basis-3/12 bg-[#13fB04] h-full border-black`}>

        </div>

      </InfoFieldWrapper>
    );
  }

  return (
    <InfoFieldWrapper>
      <div className="flex basis-6/12 h-[1.5vmax] content-center bottom-0 top-auto">
        <p className="text-[1em] text-white  bottom-0">{stateInfoField.display_name} </p>
      </div>
      <div className={`flex basis-3/12 bg-[#b91d1d] h-[1.5vmax] border-black`}>

      </div>

    </InfoFieldWrapper>
  );

}
