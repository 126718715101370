import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import {
  decryptErrorCodes,
  decryptWarningCodes,
} from "shared/utils/decriptErrors";
import ErrorLogDrawer from "./ErrorLogDrawer";

const statuses = {
  error: "text-red-500 bg-red-500/10",
  warning: "text-orange-400 bg-orange-400/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ErrorLogList({ logList, startDate, endDate }) {
  const [logInfoOpen, setLogInfoOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [filteredLogList, setFilteredLogList] = useState([]);
  const [selectedIndex , setSelectedIndex] = useState(null);

  const dateFormat = "YYYY-MM-DD";
  // useEffect(() => {
  let filtered = logList;

  if (startDate && endDate) {
    const start = moment(startDate, dateFormat).startOf("day");
    const end = moment(endDate, dateFormat).endOf("day");

    filtered = logList.filter((log) => {
      const logDate = moment(log.date, "HH:mm:ss DD.MM.YYYY");
      return logDate.isBetween(start, end, null, "[]");
    });
  }
  const { machineData } = useOutletContext();
  let type = "none";
  if (machineData?.type.toLowerCase().includes("mix")) type = "mix";
  if (machineData?.type.toLowerCase().includes("multibooster"))
    type = "multibooster";

  //   setFilteredLogList(filtered);
  // }, [logList, startDate, endDate]);

  const closeSidePanel = () => {
    setLogInfoOpen(false);
    setSelectedIndex(null);
  }

  return (
    <div className="justify-between gap-x-4 ">
      <ErrorLogDrawer
        logInfoOpen={logInfoOpen}
        setLogInfoOpen={closeSidePanel}
        selectedLog={selectedLog}
      />
      <div className="w-full ">
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl ">
          <ul role="list" className="divide-y divide-gray-100">
            {filtered.map((logItem, index) => (
              <li
                key={index}
                className={`relative flex cursor-pointer items-center space-x-4 px-4 py-4 hover:bg-gray-50 ${selectedIndex === index ? 'bg-gray-200' : ''}`}
                onClick={() => {
                  setSelectedLog(logItem);
                  setLogInfoOpen(true);
                  setSelectedIndex(index);
                }}
              >
                <div className="min-w-0 flex-auto">
                  <div className="flex items-center gap-x-3">
                    <div
                      className={classNames(
                        statuses[logItem.type],
                        "flex-none rounded-full p-1",
                      )}
                    >
                      <div className="h-2 w-2 rounded-full bg-current" />
                    </div>
                    <h2 className="min-w-0 text-sm font-semibold leading-6 ">
                      <div className="flex gap-x-2">
                        <span className="truncate">{logItem.name}</span>
                        <span className="absolute inset-0" />
                      </div>
                    </h2>
                  </div>
                  <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-500">
                    <CalendarIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <p className="truncate">
                      {moment(logItem.date, "HH:mm:ss DD.MM.YYYY").format(
                        "MMMM Do YYYY, HH:mm:ss",
                      )}
                    </p>
                  </div>
                </div>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-gray-500"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
