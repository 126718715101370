import { Button, CircularProgress, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { GetServer } from "../../../../Models/ServerCommuncation/Server";
import { Get_Keys_No_Search } from "../../../../Models/ServerCommuncation/Settings";
import { filterData } from "../../../../Models/ServerCommuncation/Socket";
import { DatedHistoricIotData, DatedIotData } from "../../../../Models/ServerCommuncation/serverInterFace";
import { useBoundingClientRect, useWindowDimensions } from "../../../../Models/dashBoardUtilities";
import { MapNameToUnit, ParseTelemtryNames, convertTelemtryNameToEncoding } from "../../../../Views/DashBoard/DashBoardParsing";
import { convertNameToEncoding, convertValueToEncoding, useEncodingState } from "pages/Navigator/Models/DataEncoding";

export function TelemtricBottomView({ defaultKey }: { defaultKey: string }): JSX.Element | null {
  const Server = GetServer();
  const TelemtryData = Server.getDataForLast10Min();
  const [key, SetKey] = React.useState<[string, string] | null>(null);

  const [Keys, SetKeys] = React.useState<[string, string][]>([]);
  useEffect(() => {
    if (TelemtryData.length !== 0 && (Keys.length === 0 || key == null)) {
      let keys: [string, string][] = Get_Keys_No_Search(TelemtryData[0].data)
        .filter((key) => {
          return ParseTelemtryNames(key) !== undefined;
        })
        .map((key) => {
          return [key, ParseTelemtryNames(key)!];
        });

      SetKeys(keys);

      let idx = keys.findIndex((key) => {
        return key[0] == defaultKey;
      });
      SetKey(keys[idx]);
    }
  }, []);

  const { height } = useWindowDimensions();

  if (key === null) {

    return <Grid item xs container justifyContent="center" direction={"column"} className="shadow" bgcolor={"white"} borderRadius={"0.5vw"}>
      <CircularProgress size={"5vmax"}></CircularProgress>
    </Grid>
  }
  return (
    <>
      <div className=" flex justify-center flex-col shadow bg-white rounded-[0.5vw] h-full min-w-full text-[length:inherit] ">
        <div className="flex justify-center content-center   basis-2/12 text-[length:inherit]  "  >
          <SelecItem Key={key!} keys={Keys} setKey={SetKey}></SelecItem>
        </div>

        <div className=" flex bg-white justify-center rounded-[0.5vw]  min-h-0  basis-10/12 flex-grow  flex-shrink-0 text-[length:inherit] " >
          <TelemetricViewInner telemetryKey={key![0]} telemetryData10Min={TelemtryData} showUnit={true}></TelemetricViewInner>
        </div>
      </div>
    </>
  );
}
function SelecItem({ Key, keys, setKey }: { Key: [string, string]; keys: [string, string][]; setKey: React.Dispatch<React.SetStateAction<[string, string] | null>> }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { encoding } = useEncodingState();
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose(key: string, displayKey: string) {
    setKey([key, displayKey]);

    setAnchorEl(null);
  }
  return (
    <div>
      <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        {convertTelemtryNameToEncoding(encoding, Key[1])}
      </Button>
      <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" sx={{ bgcolor: "white", position: "absolute", overflow: "auto", maxHeight: "30vh" }} className="scroll shadow">
                {keys.map(([key, displaykey]) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose(key, displaykey);
                      }}
                    >
                      {displaykey}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
export function TelemtricView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedHistoricIotData[] }) {
  const Server = GetServer();
  let TelemtryDataWithLast10Min = [...Server.getDataForLast10Min()];
  return (

    <TelemetricViewInner telemetryKey={telemetryKey} telemetryData10Min={TelemtryDataWithLast10Min} showUnit={false}></TelemetricViewInner>

  );
}
type Values = {
  val: number;
  date: number;
  display_key: string;
}[];
function GetValues(telemetryData: DatedHistoricIotData[], telemetryKey: string): Values {
  return telemetryData.map((data) => {
    return { val: data.data[telemetryKey], date: data.date, display_key: telemetryKey };
  });
}
function TelemetricViewInner({ telemetryKey, telemetryData10Min, showUnit }: { telemetryKey: string; telemetryData10Min: DatedHistoricIotData[], showUnit: boolean }) {

  const [telemetryData, setTelemetryData] = React.useState<Values>(GetValues(telemetryData10Min, telemetryKey));
  const { encoding } = useEncodingState();
  const Server = GetServer();
  let local_unit = MapNameToUnit(telemetryKey);
  let unit = convertNameToEncoding(encoding, local_unit!);
  const setData = (data: DatedIotData[]) => {
    let change_data = data.map((data) => {

      return { val: convertValueToEncoding(encoding, data.data[telemetryKey], local_unit,), date: data.date, display_key: telemetryKey };
    });

    setTelemetryData([...change_data]);
  };
  useEffect(() => {
    setTelemetryData(GetValues(telemetryData10Min, telemetryKey));
  }, [telemetryKey])
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);
    const interval = window.setInterval(() => {
      setTelemetryData((prev) => {
        let new_data = prev[prev.length - 1];
        new_data.date = Date.now()

        return filterData([...prev, new_data]);
      });
    }, 1000);
    return () => {
      Server.off_telemtry_last_10_min(setData);
      clearInterval(interval);
    };
  });

  let { height, width } = useWindowDimensions();
  if (telemetryData.length === 0) {
    return <></>;
  }
  let ticks = get_tick(telemetryData[telemetryData.length - 1].date - 1000 * 60 * 10, telemetryData[telemetryData.length - 1].date, 10);
  return (
    <ResponsiveContainer className="text-[length:inherit] " width={"100%"} height={"100%"}>
      <LineChart
        data={[...telemetryData]}
        margin={{
          top: 10,
          right: width / 55,
          bottom: 10,
          left: 0,
        }}
      >
        <CartesianGrid vertical={false} color="#000" strokeDasharray={"3 3"}></CartesianGrid>

        <XAxis
          label={{ value: "time", offset: -4, style: { fontSize: "1.2em", textAnchor: "middle" }, position: "insideBottom" }}
          tickLine={false}
          axisLine={false}
          dataKey="date"
          type="number"
          ticks={ticks}
          interval={0}
          tick={{ fontSize: "1em" }}
          domain={[ticks[0], ticks[ticks.length - 1] + 10]}
          tickFormatter={(time, idx) => {
            if (idx !== ticks.length - 1) {
              return (-(ticks.length - idx - 1)).toString();
            }
            return moment(time).format("HH:mm:ss");
          }}
        />
        <Tooltip content={<ShowDataAndValue />}></Tooltip>

        <YAxis label={showUnit ? { value: unit ? unit : "", position: "insideLeft", style: { fontSize: "1.2em" } } : {}} dx={11} allowDataOverflow={true} axisLine={false} tickLine={false} />

        <Line isAnimationActive={false} type="monotone" dataKey="val" strokeWidth={3} stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}



function get_tick(start: number, end: number, times: number): number[] {
  let step = (end - start) / (times - 1);

  let base = start;
  let ticks = [];
  for (let i = 0; i < times; i++) {
    ticks.push(base);
    base += step;
  }
  return ticks;
}

function ShowDataAndValue({ active, payload, label }: TooltipProps<number, NameType>): JSX.Element | null {
  if (payload && payload?.length !== 0 && active) {
    let date = moment(payload![0].payload.date).format("HH:mm:ss");
    let data = payload[0].payload.val;
    let display_key = payload[0].payload.display_key;

    return (
      <Grid container direction={"column"} rowGap={3} fontSize={"1vw"} borderRadius={"2vw"} minHeight={"15vh"} minWidth={"20vw"}>
        <Grid item xs={7} bgcolor={"black"} fontSize={"1vw"} borderRadius={"2vw"} sx={{ opacity: "0.6" }} justifyContent={"start"} alignItems={"start"}>
          <Grid item>
            <p className="text-white justify-center flex">{`${display_key}: ${data}`}</p>
          </Grid>
        </Grid>
        <Grid item xs={5} bgcolor={"black"} fontSize={"1vw"} borderRadius={"2vw"} sx={{ opacity: "0.6" }} justifyContent={"start"} alignContent={"end"}>
          <p className="text-white justify-center flex">{`time: ${date}`}</p>
        </Grid>
      </Grid>
    );
  }
  return null;
}
