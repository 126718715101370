import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FactoryRow({ factory, factoryIdx }) {
  const navigate = useNavigate();
  return (
    <tr
      className={classNames(
        factoryIdx === 0 ? "border-gray-300" : "border-gray-200",
        "border-t cursor-pointer hover:bg-gray-50"
      )}
      onClick={() => {
        console.log("clicked");
        navigate("/commander/" + factory.id + "/view/machines");
      }}
    >
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-center space-x-2">
        <MdErrorOutline size={22} className={`${factory?.hasError ? "text-red-500" : "text-gray-300"} `} />
        <IoWarningOutline size={22} className={`${factory?.hasWarning ? "text-orange-400" : "text-gray-300"} `} />
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{factory.name}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{factory.address}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{factory.city}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{factory.country} </td>
    </tr>
  );
}
