import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  changeMachineName,
  changeMachineNameAdmin,
} from "../../api/machine.service";

export const useChangeMachineName = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return changeMachineNameAdmin(data?.MachineId, data?.name);
      else return changeMachineName(data?.MachineId, data?.name);
    },
  });
};
