import React from "react";
import NavigatorHeaderBar from "./NavigatorHeaderBar";
import { Outlet } from "react-router-dom";
const NavigatorLayout = () => {
    return (
        <div className="flex flex-col  w-full overflow-hidden">
            {/* <SidebarNavTesting sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
            {/* <SidebarNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
            <NavigatorHeaderBar />
            <div className="lg:pl-0 overflow-hidden overflow-x-hidden  flex w-full flex-shrink h-[95vh]">
                <main className="px-0 overflow-hidden flex w-full flex-shrink h-full">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default NavigatorLayout;
