import apiClient from "./apiClient";

/* ----- Get Methods ----- */
export const searchOwnFactories = (data) => {
  return apiClient.post("/v2/factories/own/filter", data);
};

export const searchFactories = (data) => {
  return apiClient.post("/v2/factories/filter", data);
};

export const createFactory = (data) => {
  return apiClient.post("/factories/own", data);
};

export const getOwnFactoryById = (id) => {
  return apiClient.get(`/factories/own/${id}`);
};

export const getFactoryById = (id) => {
  return apiClient.get(`/factories/${id}`);
};

export const getFactoryRooms = (id) => {
  return apiClient.get(`rooms/only/factory/${id}`);
};
export const getOwnFactoryRooms = (id) => {
  return apiClient.get(`rooms/only/own/factory/${id}`);
};

/* ----- Put Methods ----- */
export const updateFactoryOwn = ({ id, data }) => {
  return apiClient.put(`/factories/own/${id}`, data);
};
export const updateFactory = ({ id, data }) => {
  return apiClient.put(`/factories/${id}`, data);
};
/* ----- Delete Methods ----- */
export const deleteOwnFactory = (id) => {
  return apiClient.delete(`/factories/own/${id}`);
};

export const deleteFactory = (id) => {
  return apiClient.delete(`/factories/${id}`);
};

/* ########### Factory User ########### */

/* ----- Delete Methods ----- */
export const deleteUserFromFactory = (UserId, FactoryId) => {
  return apiClient.delete(`/factory-user/delete/${UserId}/${FactoryId}`);
};

export const deleteUserFromOwnFactory = (UserId, FactoryId) => {
  return apiClient.delete(`/factory-user/delete/own/${UserId}/${FactoryId}`);
};
