//@ts-ignore
import React, { useEffect, useState } from "react";
//@ts-ignore
import { userService } from "shared/services/authentication.service";
import {
  DeIntializeServer,
  GetServer,
  ServerExist,
} from "./Models/ServerCommuncation/Server";
import { Encoding, useEncodingState } from "./Models/DataEncoding";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BsBootstrapReboot } from "react-icons/bs";
import { VscDebugDisconnect } from "react-icons/vsc";
import { AiOutlineDisconnect } from "react-icons/ai";

const userNavigation = [
  { name: "Sign out", href: "#", onClick: () => userService.logout() },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
const selectedStyling =
  "ml-3 flex-col inline-flex items-center rounded-md bg-[#001b54] px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500";
const notSelectedStyling =
  "ml-3 inline-flex flex-col items-center rounded-md outline-1 outline text-[#1976d2]  px-3 py-2 text-sm font-semibold  shadow-sm  hover:bg-[#42a5f5] hover:bg-opacity-10  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500";

const encodingToStyling: Record<Encoding, [string, string]> = {
  Metric: [selectedStyling, notSelectedStyling],
  Imperial: [notSelectedStyling, selectedStyling],
};

export default function NavigatorHeaderBar() {
  const navigate = useNavigate();
  let { encoding, setEncoding } = useEncodingState();
  const [styling, setStyling] = React.useState<[string, string]>(
    encodingToStyling["Metric"],
  );
  const myAccount = useSelector((state: any) => state.user.user);

  const [unitSystem, setUnitSystem] = useState("metric");

  // Load saved preference from local storage
  useEffect(() => {
    const storedUnitSystem = localStorage.getItem("unitSystem") || "metric";
    setUnitSystem(storedUnitSystem);
  }, []);
  useEffect(() => {
    setStyling(encodingToStyling[encoding]);
    setEncoding(encoding);
  }, [setEncoding, encoding]);

  // Handle switch change
  const handleToggle = () => {
    const newUnitSystem = unitSystem === "metric" ? "imperial" : "metric";
    setUnitSystem(newUnitSystem);
    const newUnitSystemNav = unitSystem === "metric" ? "Imperial" : "Metric";
    setEncoding(newUnitSystemNav);
    localStorage.setItem("unitSystem", newUnitSystem); // Save preference
  };

  function Disconnect() {
    if (ServerExist()) {
      GetServer().SendCommand("SetNavigatorDisconnect", "", () => {});
      navigate("/navigator/connect");

      DeIntializeServer();

      return;
    }

    navigate("/navigator/connect", {
      state: {
        errorMessage:
          "Didnt Disconnect display. Please ask user of machine to exit navigator mode",
      },
    });
  }

  function RebootSystem() {
    if (ServerExist()) {
      navigate("/navigator/connect");
      GetServer().SendCommand("RebootSystem", null, () => {});
      GetServer().disconnectNoError();

      return;
    }

    navigate("/navigator/connect", {
      state: {
        errorMessage:
          "Didnt Reboot display. Please ask user of machine to exit navigator mode and reboot",
      },
    });
  }
  return (
    <Disclosure as="nav" className="z-60 bg-white shadow">
      <div className="mx-auto  px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block size-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden size-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="flex shrink-0 items-center">
              <img alt="Your Company" src={require("./logo.png")} className="h-8 w-auto" />
            </div>
            <div className="flex items-center md:ml-6 md:flex md:space-x-8">
              <button
                type="button"
                onClick={() => {
                  Disconnect();
                }}
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                {/* VscDebugDisconnect */}
                <AiOutlineDisconnect
                  aria-hidden="true"
                  className="-ml-0.5 size-5"
                />
                Disconnect
              </button>
              <button
                type="button"
                onClick={() => {
                  RebootSystem();
                }}
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <BsBootstrapReboot
                  aria-hidden="true"
                  className="-ml-0.5 size-5"
                />
                Reboot System
              </button>
              {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
            </div>
          </div>
          <div className="flex items-center">
            <div className="shrink-0">
              {/* <div className="flex items-center justify-center space-x-4">
                <div
                  className="relative flex h-10 w-40 cursor-pointer items-center rounded-full border-2 border-gray-300 bg-gray-200"
                  onClick={handleToggle}
                >
                  <div
                    className={`absolute inset-0 flex items-center justify-center rounded-full transition-transform duration-300 ease-in-out ${
                      unitSystem === "metric"
                        ? "translate-x-[-36px]"
                        : "translate-x-[37px]"
                    }`}
                  >
                    <div className="h-8 w-1/2 rounded-full bg-blue-600" />
                  </div>

                  <span
                    className={`absolute left-0 w-1/2 text-center text-sm font-semibold uppercase text-white transition-all duration-300 ease-in-out ${
                      unitSystem === "metric"
                        ? "translate-x-0"
                        : "-translate-x-full"
                    }`}
                  >
                    Metric
                  </span>

                  <span
                    className={`absolute right-0 w-1/2 text-center text-sm font-semibold uppercase text-white transition-all duration-300 ease-in-out ${
                      unitSystem === "imperial"
                        ? "translate-x-0"
                        : "translate-x-full"
                    }`}
                  >
                    Imperial
                  </span>
                </div>
              </div> */}

              <div className="flex items-center justify-center">
                <div
                  className="relative flex h-10 w-44 cursor-pointer items-center rounded-full border-2 border-gray-300 bg-gray-200"
                  onClick={handleToggle}
                >
                  {/* Moving toggle */}
                  <div
                    className={`absolute h-8 w-20 rounded-full bg-blue-600 transition-transform duration-300 ${
                      unitSystem === "metric"
                        ? "translate-x-[4px]"
                        : "translate-x-[88px]"
                    }`}
                  />

                  {/* Metric Label */}
                  <span
                    className={`absolute left-0.5 w-1/2 text-center text-sm font-semibold uppercase transition-colors duration-300 ${
                      unitSystem === "metric" ? "text-white" : "text-gray-600"
                    }`}
                  >
                    Metric
                  </span>

                  {/* Imperial Label */}
                  <span
                    className={`absolute right-0 w-1/2 text-center text-sm font-semibold uppercase transition-colors duration-300 ${
                      unitSystem === "imperial" ? "text-white" : "text-gray-600"
                    }`}
                  >
                    Imperial
                  </span>
                </div>
              </div>
              {/* <button
                type="button"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PlusIcon aria-hidden="true" className="-ml-0.5 size-5" />
                New Job
              </button> */}
            </div>
            <div className="hidden md:ml-4 md:flex md:shrink-0 md:items-center">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex max-w-xs items-center rounded-full  text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex size-[32px] items-center justify-center rounded-full bg-blue-100 text-sm font-semibold leading-none text-blue-800 ">
                      {myAccount?.firstName.charAt(0)}
                      {myAccount?.lastName.charAt(0)}
                    </span>

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-black"
                        aria-hidden="true"
                      >
                        {myAccount?.firstName} {myAccount?.lastName}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 mr-2 h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {userNavigation.map((item) => (
                    <MenuItem key={item.name}>
                      <a
                        href={item.href}
                        onClick={(event) => {
                          event.preventDefault();
                          if (item.name === "Sign out") {
                            item.onClick();
                          }
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                      >
                        {item.name}
                      </a>
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
          <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-blue-500 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700 sm:pl-5 sm:pr-6"
          >
            Dashboard
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          >
            Team
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          >
            Projects
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="#"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          >
            Calendar
          </DisclosureButton>
        </div>
        <div className="border-t border-gray-200 pb-3 pt-4">
          <div className="flex items-center px-4 sm:px-6">
            <div className="shrink-0">
              <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-sm font-semibold leading-none text-blue-800 ">
                {myAccount?.firstName.charAt(0)}
                {myAccount?.lastName.charAt(0)}
              </span>
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {myAccount?.firstName} {myAccount?.lastName}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {myAccount?.email}
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            {userNavigation.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
              >
                {item.name}
              </DisclosureButton>
            ))}
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}

export function NavigatorHeaderBar2() {
  let { encoding, setEncoding } = useEncodingState();

  const [styling, setStyling] = React.useState<[string, string]>(
    encodingToStyling["Metric"],
  );

  const navigate = useNavigate();
  const myAccount = useSelector((state: any) => state.user.user);

  function Disconnect() {
    if (ServerExist()) {
      GetServer().SendCommand("SetNavigatorDisconnect", "", () => {});
      navigate("/navigator/connect");

      DeIntializeServer();

      return;
    }

    navigate("/navigator/connect", {
      state: {
        errorMessage:
          "Didnt Disconnect display. Please ask user of machine to exit navigator mode",
      },
    });
  }
  useEffect(() => {
    setStyling(encodingToStyling[encoding]);
    setEncoding(encoding);
  }, [setEncoding, encoding]);

  function RebootSystem() {
    if (ServerExist()) {
      navigate("/navigator/connect");
      GetServer().SendCommand("RebootSystem", null, () => {});
      GetServer().disconnectNoError();

      return;
    }

    navigate("/navigator/connect", {
      state: {
        errorMessage:
          "Didnt Reboot display. Please ask user of machine to exit navigator mode and reboot",
      },
    });
  }

  return (
    <div>
      <div className="lg:pl-100  basis-1/11 overflow-hidden ">
        <div className="sticky top-0 z-40 flex h-12	 w-full items-center gap-x-4 overflow-hidden border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1  gap-x-4   self-stretch lg:gap-x-1">
            <div className="relative flex flex-1 items-center">
              <div className="relative flex  items-center justify-start	">
                <button
                  type="button"
                  onClick={() => {
                    Disconnect();
                  }}
                  className="ml-3 inline-flex items-center rounded-md bg-[#001b54] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Disconnect
                </button>
              </div>
              <div className="relative flex flex-1 items-center justify-start	">
                <button
                  type="button"
                  onClick={() => {
                    RebootSystem();
                  }}
                  className="ml-3 inline-flex items-center rounded-md bg-[#001b54] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Reboot System
                </button>
              </div>
              <div className="relative flex flex-1 items-center">
                <h1 className="text-lg font-semibold leading-6 text-nilfisk">
                  Navigator Tool
                </h1>
              </div>
            </div>
            {/* <form className="relative flex flex-1">
              //TODO when implementing search use this form
               <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </form> */}

            <div className=" flex items-center gap-x-3 lg:gap-x-6">
              <button
                type="button"
                className={"w-full " + styling[0]}
                onClick={() => {
                  setEncoding("Metric");
                }}
              >
                {" "}
                SI
              </button>
              <button
                type="button"
                className={"w-full " + styling[1]}
                onClick={() => {
                  setEncoding("Imperial");
                }}
              >
                {" "}
                Imperial
              </button>
              {/* Separator */}

              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                aria-hidden="true"
              />

              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                aria-hidden="true"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5 ">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-flex size-[32px] items-center justify-center rounded-full bg-blue-100 text-sm font-semibold leading-none text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                    {myAccount.firstName.charAt(0) +
                      myAccount.lastName.charAt(0)}
                  </span>
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true"
                    >
                      {myAccount.firstName} {myAccount.lastName}
                    </span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  //   as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block w-full px-4 py-2 text-left text-sm  text-gray-900",
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
