import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Grid, IconButton, Typography, } from "@mui/material";
import { Table, TableBody, TableCellBody, TableCellHeader, TableContainer, TableHeader, TableRow } from "./DashBoardTable/DashBoardTable";
import moment from "moment";
import React, { useEffect } from "react";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
import GetErrors from "../../Models/Errorlog/ErrorLogHandler";
import ErrorLogs from "../../Models/Errorlog/ErrorLogs";
import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { WarningAndErrorIconView } from "../uiElements/ErrorAndWarningIcons";
import { concat, keys } from "lodash";
import { useWindowDimensions } from "pages/Navigator/Models/dashBoardUtilities";

export default function ErrorLogView() {
  const server = GetServer();
  const listener = GetPopUpBarListener();

  const [ErrorLog, SetErrorLog] = React.useState<ErrorLogs>(new ErrorLogs([]));
  const [ErrorConfig, SetErrorConfig] = React.useState<Record<string, any>>({});
  const [ErrorLogsList, SetErrorLogsList] = React.useState<ErrorLogEntry[] | null>(null);
  const [ErrorChosen, SetErrorChosen] = React.useState<ErrorLogEntry | null>(null);
  useEffect(() => {
    async function getConfig() {
      const machineConfig = await GetServer().GetErrorFile();
      SetErrorConfig(machineConfig);
    }
    getConfig();
  }, []);
  function setErrors(data: Record<string, string>, errorConfig: Record<string, any>) {
    let new_error_log = new ErrorLogs(GetErrors(data as any, ErrorConfig));
    let error_logs = new_error_log.GetAllErrors();
    SetErrorLog(new_error_log);
    error_logs.sort((a, b) => (b.date as Date).valueOf() - (a.date as Date).valueOf());

    SetErrorLogsList(error_logs);
    listener.callListeners("ErrorTime", ["all", ""], error_logs);

  }
  useEffect(() => {

    if (!!ErrorConfig && Object.keys(ErrorConfig).length !== 0) {
      server.SendCommand("GetErrorLogJSON", null, (data, err) => {

        if (!err) {

          setErrors(data!, ErrorConfig);

        }
      });
      const interval = setInterval(() => {
        server.SendCommand("GetErrorLogJSON", null, (data, err) => {

          if (!err) {

            setErrors(data!, ErrorConfig);

          }
        });
      }, 10000);
      return () => {
        clearInterval(interval);
      }

    }
  }, [ErrorConfig]);

  useEffect(() => {
    if (ErrorChosen && ErrorLogsList) {
      listener.callListeners("ErrorTime", [ErrorChosen.errorMessage, ErrorChosen.ErrorName], ErrorLogsList);
    } else if (ErrorLogsList) {
      listener.callListeners("ErrorTime", ["all", ""], ErrorLogsList);

    }
  }, [ErrorChosen, ErrorLogsList]);
  if (!ErrorLogsList) {
    return <Grid item xs container>
      <Grid item xs container direction={"column"} justifyContent={"center"} alignContent={"center"}>

        <CircularProgress size={"5vmax"} />
      </Grid>
    </Grid>

  }
  return (
    <CreateErorsByYearByMonthByDay setChosenError={SetErrorChosen} Errorlogs={ErrorLogsList}></CreateErorsByYearByMonthByDay>
  );
}

// function ErrorView({ error, setError }: { error: ErrorLogEntry; setError: (val: ErrorLogEntry | null) => void }): JSX.Element {
//   const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
//   console.log(error);
//   if (!ShowDetailed) {
//     return (
//       <TableRow >
//         <TableCell>
//           <Grid item xs container bgcolor={"white"} borderRadius={"0.5vw"}>

//             <ShowError error={error}></ShowError>
//             <Grid item container xs={2} justifyContent={"end"}>
//               <IconButton
//                 onClick={() => {
//                   SetShowDetailed((prev: boolean) => !prev);
//                   setError(error);
//                 }}
//               >
//                 <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
//               </IconButton>
//             </Grid>
//           </Grid>
//         </TableCell>

//       </TableRow>
//     );
//   }
//   if (ShowDetailed) {
//     return (
//       <>
//         <TableRow >
//           <TableCell>
//             <Grid item xs container bgcolor={"white"} borderRadius={"0.5vw"}>

//               <ShowError error={error}></ShowError>
//               <Grid item container xs={2} justifyContent={"end"}>
//                 <IconButton
//                   onClick={() => {
//                     SetShowDetailed((prev: boolean) => !prev);
//                     setError(null);
//                   }}
//                 >
//                   <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </TableCell>

//         </TableRow>
//         <TableRow>
//           <TableCell>
//             <ShowCauses error={error}></ShowCauses>
//           </TableCell>
//         </TableRow>
//         <TableCell>
//           <ShowRemedies error={error}></ShowRemedies>
//         </TableCell>
//       </>
//     )
//   }
//   return <></>
// }

/* */
function parseMessage(val: string): JSX.Element {
  let values = val.split(/(?=\()/g);

  if (values.length === 0) {
    return (
      <Typography variant="button" alignItems={"start"} color="black" overflow="hidden">
        {val}
      </Typography>
    );
  }
  return (
    <>
      {values.map((val, idx) => {
        if (idx === values.length - 1) {
          return (
            <Typography variant="button" alignItems={"start"} color="black" overflow="hidden">
              {val}
            </Typography>
          );
        }
        return (
          <>
            <Typography variant="button" alignItems={"start"} color="black" overflow="hidden">
              {val}
            </Typography>
            <br></br>
          </>
        );
      })}
    </>
  );
}


// function DisplayInformation({ error }: { error: ErrorLogEntry }): JSX.Element | null {

//   return (<Paper>
//     <TableContainer sx={{ overflowX: "hidden", }} className="scroll shadow" >
//       <Table stickyHeader>
//         <TableHead >
//           <TableRow >

//             <TableCell>
//               <Grid item container xs={12} justifyContent="center" alignContent={"center"} alignItems={"center"}>

//                 <Typography alignItems={"center"} variant="button" fontSize={"0.7vmax"}>Causes</Typography>
//               </Grid>
//             </TableCell>
//           </TableRow>
//         </TableHead>

//         <TableBody>

//           {error.causes.map((cause, index) => {

//             if (cause === "") {
//               return <></>;
//             }
//             return (
//               <TableRow>
//                 <TableCell>
//                   {parseMessage(cause)}
//                 </TableCell>
//               </TableRow>
//             );
//           })}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   </Paper>)
// }
// function ShowCauses({ error }: { error: ErrorLogEntry }): JSX.Element | null {
//   if (error.causes.length === 0) {
//     return null;
//   }


//   return (
//     <Paper>
//       <TableContainer sx={{ overflowX: "hidden", }} className="scroll shadow" >
//         <Table stickyHeader>
//           <TableHead >
//             <TableRow >

//               <TableCell>
//                 <Grid item container xs={12} justifyContent="center" alignContent={"center"} alignItems={"center"}>

//                   <Typography alignItems={"center"} variant="button" fontSize={"0.7vmax"}>Causes</Typography>
//                 </Grid>
//               </TableCell>
//             </TableRow>
//           </TableHead>

//           <TableBody>
//             {error.causes.map((cause, index) => {

//               if (cause === "") {
//                 return <></>;
//               }
//               return (
//                 <TableRow>
//                   <TableCell>
//                     {parseMessage(cause)}
//                   </TableCell>
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//   );
// }

// function ShowRemedies({ error }: { error: ErrorLogEntry }): JSX.Element | null {
//   if (error.remedies.length === 0) {
//     return null;
//   }
//   return (
//     <Paper>
//       <TableContainer sx={{ overflowX: "hidden", }} className="scroll shadow" >
//         <Table stickyHeader>
//           <TableHead >
//             <TableRow >

//               <TableCell>
//                 <Grid item container xs={12} justifyContent="center" alignContent={"center"} alignItems={"center"}>

//                   <Typography alignItems={"center"} variant="button" fontSize={"0.7vmax"}>Remedies</Typography>
//                 </Grid>
//               </TableCell>
//             </TableRow>
//           </TableHead>

//           <TableBody>
//             {error.remedies.map((remedy, index) => {

//               if (remedy === "") {
//                 return <></>;
//               }
//               return (
//                 <TableRow>
//                   <TableCell>
//                     {parseMessage(remedy)}
//                   </TableCell>
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//   );
// }


function ShowError({ error }: { error: ErrorLogEntry }): JSX.Element {
  let date = error.date as Date;
  console.log("showing error");
  return (
    <>
      <Grid item xs={6} container direction={"column"}>

        <Grid item container justifyContent="start" alignContent={"center"}>
          <Typography variant="button" alignItems={"center"} textAlign={"end"} color="black" overflow="hidden">
            {`${error.errorMessage}`}
          </Typography>
        </Grid>
        <Grid container item alignContent={"start"} justifyContent={"start"} xs={5} >
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
            {`${moment(date).format("yyyy/MM HH:mm:ss")}`}
          </Typography>
        </Grid>
      </Grid>


      <Grid item xs={3} container justifyContent="center" sx={{ paddingTop: "0.2vmax", paddingBottom: "0.2vmax" }}>
        <WarningAndErrorIconView type={error.ErrorType}></WarningAndErrorIconView>
      </Grid>
    </>
  );
}
function ErrorsByYearByMonthByDayModel(Errorlogs: ErrorLogEntry[]): Map<number, Map<number, Map<number, ErrorLogEntry[]>>> {
  let val = Errorlogs.reduce((result: Map<number, Map<number, Map<number, ErrorLogEntry[]>>>, currentObject) => {
    const date = new Date(currentObject.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    if (!result.get(year)) {
      result.set(year, new Map());
    }

    if (!result.get(year)?.get(month)) {
      result.get(year)?.set(month, new Map());
    }

    if (!result.get(year)?.get(month)?.get(day)) {
      result.get(year)?.get(month)?.set(day, []);
    }

    result.get(year)?.get(month)?.get(day)?.push(currentObject);

    return result;
  }, new Map());

  return val;
}

function CreateErorsByYearByMonthByDay({ Errorlogs, setChosenError }: { Errorlogs: ErrorLogEntry[]; setChosenError: (val: ErrorLogEntry | null) => void }): JSX.Element {


  if (Errorlogs.length === 0) {
    return <Grid item xs sx={{ flex: "1", minHeight: 0, overflowX: "hidden", }} container direction={"column"} justifyContent={"center"} alignContent={"center"}>
      <Typography color={"black"} variant="button">The machine has no recorded errors</Typography>
    </Grid>
  }
  return (<TailWindTable Errorlogs={Errorlogs} setChosenError={setChosenError}></TailWindTable>);

}

function TailWindTable({ Errorlogs, setChosenError }: { Errorlogs: ErrorLogEntry[]; setChosenError: (val: ErrorLogEntry | null) => void }): JSX.Element {
  let OrderedErrors = ErrorsByYearByMonthByDayModel(Errorlogs);

  return <TableContainer>
    <Table >
      <TableHeader >
        <TableRow >
          <TableCellHeader> Errors</TableCellHeader>
        </TableRow>
      </TableHeader>
      <TableBody>

        {Array.from(OrderedErrors).map(([Year, ByMonth]) => {
          return Array.from(ByMonth).map(([month, ByDay]) => {
            return Array.from(ByDay).map(([Day, Days]) => {
              return Days.map((day) => {
                return <TailWindErrorView setError={setChosenError} error={day}></TailWindErrorView>;

              })
            });
          })
        })}
      </TableBody>
    </Table>
  </TableContainer>
}



function TailWindErrorView({ error, setError }: { error: ErrorLogEntry; setError: (val: ErrorLogEntry | null) => void }): JSX.Element {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  console.log(error);
  if (!ShowDetailed) {
    return (
      <TableRow>
        <TableCellBody>
          <Grid item xs container bgcolor={"white"} >

            <ShowError error={error}></ShowError>
            <Grid item container xs={2} justifyContent={"end"}>
              <IconButton
                onClick={() => {
                  SetShowDetailed((prev: boolean) => !prev);
                  setError(error);
                }}
              >
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              </IconButton>
            </Grid>
          </Grid>
        </TableCellBody>

      </TableRow>
    );
  }
  if (ShowDetailed) {
    return (
      <>
        <TableRow >
          <TableCellBody>
            <Grid item xs container bgcolor={"white"} borderRadius={"0.5vw"}>

              <ShowError error={error}></ShowError>
              <Grid item container xs={2} justifyContent={"end"}>
                <IconButton
                  onClick={() => {
                    SetShowDetailed((prev: boolean) => !prev);
                    setError(null);
                  }}
                >
                  <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                </IconButton>
              </Grid>
            </Grid>
          </TableCellBody>

        </TableRow>
        <TableRow>
          <TableCellBody>
            <ShowCausesTailWind error={error}></ShowCausesTailWind>
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellBody>
            <ShowRemediesTailWind error={error}></ShowRemediesTailWind>
          </TableCellBody>
        </TableRow>
      </>
    )
  }
  return <></>
}

function ShowCausesTailWind({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.causes.length === 0) {
    return null;
  }


  return (
    <Table >
      <TableHeader >
        <TableRow >

          <TableCellHeader sticky={false}>
            <Grid item container xs={12} justifyContent="center" alignContent={"center"} alignItems={"center"}>

              <Typography alignItems={"center"} variant="button" fontSize={"0.7vmax"}>Causes</Typography>
            </Grid>
          </  TableCellHeader>
        </TableRow>
      </TableHeader>

      <TableBody>
        {error.causes.map((cause, index) => {

          if (cause === "") {
            return <></>;
          }
          return (
            <TableRow>
              <TableCellBody>
                {parseMessage(cause)}
              </TableCellBody>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function ShowRemediesTailWind({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.remedies.length === 0) {
    return null;
  }
  return (
    <Table>
      <TableHeader>
        <TableRow >

          <TableCellHeader sticky={false}>
            <Grid item container xs={12} justifyContent="center" alignContent={"center"} alignItems={"center"}>

              <Typography alignItems={"center"} variant="button" fontSize={"0.7vmax"}>Remedies</Typography>
            </Grid>
          </TableCellHeader>
        </TableRow>
      </TableHeader>

      <TableBody>
        {error.remedies.map((remedy, index) => {

          if (remedy === "") {
            return <></>;
          }
          return (
            <TableRow>
              <TableCellBody>
                {parseMessage(remedy)}
              </TableCellBody>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}