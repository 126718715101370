import React from "react";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useDispatch } from "react-redux";
import { setUser } from "shared/store/user/actions/userActions";
import ErrorAlert from "components/ErrorAlert";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { registerUser } from "api/user.service";

const validationSchema = yup.object({
  firstName: yup.string("Enter first name").required("First name is required"),
  lastName: yup.string("Enter last name").required("Last name is required"),
  username: yup.string("Enter username").required("Username is required"),
  email: yup
    .string("Enter email")
    .email("Must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/,
      "Password must contain at least one special character",
    ),
  repeatPassword: yup
    .string("Enter repeat password")
    .required("Repeat password required"),
  acceptedTOS: yup
    .boolean()
    .oneOf([true], "Accepting Terms and Conditions is required"),
});

const Login = () => {
  const dispatch = useDispatch();

  const [accountBanned, setAccountBanned] = React.useState(false);
  const [hasPassError, setHasPassError] = React.useState(false);
  const [hasConnError, setHasConnError] = React.useState(false);
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // const history = createBrowserHistory({ forceRefresh: true });

  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      password: "",
      repeatPassword: "",
      acceptedTOS: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setFieldError }) => {
      setLoading(true);
      setError(false);
      if (values.password !== values.repeatPassword) {
        setFieldError("repeatPassword", "Passwords do not match");
        setLoading(false);
        return;
      }

      registerUser(values)
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          navigate("/register-successfully/" + values.email);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (
            err?.response?.data?.msg?.includes(
              "Validation isEmail on email failed",
            )
          ) {
            setFieldError("email", "Email is invalid");
          } else if (
            err?.response?.data?.msg?.includes("Email already exists!")
          ) {
            setFieldError("email", "Email is already used");
          } else if (
            err?.response?.data?.msg?.includes("This username is already used!")
          ) {
            setFieldError("username", "Username is already used");
          }
          // Alert.alert("Error", "There was a problem with the server. Please try again later. ", [{ text: "Okay" }]);
          else setError(true);
        });
    },
  });

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center bg-nilfisk py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Set up your Nilfisk Food account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img
            className="mx-auto mb-6 w-auto"
            src={nilfiskFoodLogo}
            alt="Nilfisk Food"
          />
          <form
            className="space-y-6"
            id="signup"
            onSubmit={formik.handleSubmit}
          >
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First name
              </label>
              <div className="mt-2">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last name
              </label>
              <div className="mt-2">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.lastName && formik.errors.lastName
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="LastName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Username
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.username && Boolean(formik.errors.username)
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.email && Boolean(formik.errors.email)
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              {/* <!-- Strong Password --> */}
              <div className="mt-2">
                <div className="flex">
                  <div className="relative flex-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                          ? "ring-red-500 focus:ring-red-500"
                          : "ring-gray-300 focus:ring-blue-500"
                      }  sm:text-sm sm:leading-6`}
                    />
                    <div
                      id="hs-strong-password-popover"
                      className="absolute z-10 hidden w-full rounded-lg bg-white p-4 shadow-md dark:divide-gray-700 dark:border dark:border-gray-700 dark:bg-gray-800"
                    >
                      <div
                        id="hs-strong-password-in-popover"
                        data-hs-strong-password='{
                          "target": "#password",
                          "hints": "#hs-strong-password-popover",
                          "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1",
                          "mode": "popover"
                        }'
                        className="-mx-1 mt-2 flex"
                      ></div>

                      <h4 className="mt-3 text-sm font-semibold text-gray-800 dark:text-white">
                        Your password must contain:
                      </h4>

                      <ul className="space-y-1 text-sm text-gray-500">
                        <li
                          data-hs-strong-password-hints-rule-text="min-length"
                          className="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Minimum number of characters is 6.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="lowercase"
                          className="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain lowercase.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="uppercase"
                          className="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain uppercase.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="numbers"
                          className="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain numbers.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="special-characters"
                          className="flex items-center gap-x-2 hs-strong-password-active:text-teal-500"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="size-4 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain special characters.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Strong Password --> */}
            </div>

            <div>
              <label
                htmlFor="repeatPassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Repeat Password
              </label>
              <div className="mt-2">
                <input
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.repeatPassword &&
                    Boolean(formik.errors.repeatPassword)
                      ? "ring-red-500 focus:ring-red-500"
                      : "ring-gray-300 focus:ring-blue-500"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="acceptedTOS"
                  name="acceptedTOS"
                  type="checkbox"
                  value={formik.values.acceptedTOS}
                  onChange={formik.handleChange}
                  className={`h-4 w-4 rounded  text-blue-500 ${
                    formik.touched.acceptedTOS && formik.errors.acceptedTOS
                      ? "border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:ring-blue-500"
                  }`}
                />
                <label
                  htmlFor="acceptedTOS"
                  className="ml-3 block text-sm leading-6 text-gray-900"
                >
                  I accept the{" "}
                  <button
                    type="button"
                    class="font-semibold leading-6 text-sky-500 hover:text-sky-400 focus:text-sky-400 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    aria-controls="hs-vertically-centered-scrollable-modal"
                    data-hs-overlay="#hs-vertically-centered-scrollable-modal"
                  >
                    Terms and Conditions
                  </button>
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Sign Up
              </button>
              {loading ? (
                <div className="flex justify-center">
                  <div className="mt-4 h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-300 border-t-transparent"></div>
                </div>
              ) : null}
            </div>
            {formik.touched.lastName && formik.errors.lastName ? (
              <ErrorAlert
                text={formik.touched.lastName && formik.errors.lastName}
              />
            ) : null}
            {formik.submitCount > 0 && formik.errors.firstName ? (
              <ErrorAlert text={formik.errors.firstName} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.lastName ? (
              <ErrorAlert text={formik.errors.lastName} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.username ? (
              <ErrorAlert text={formik.errors.username} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.email ? (
              <ErrorAlert text={formik.errors.email} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.password ? (
              <ErrorAlert text={formik.errors.password} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.repeatPassword ? (
              <ErrorAlert text={formik.errors.repeatPassword} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.acceptedTOS ? (
              <ErrorAlert text={formik.errors.acceptedTOS} />
            ) : null}
            {error ? (
              <ErrorAlert text="There was an error with the server. Please try again later." />
            ) : null}
            {/* {error ? <ErrorAlert text="There was a problem. Please check the fields and try again." /> : null} */}
            {hasConnError ? (
              <ErrorAlert text="There is a connection error." />
            ) : null}
          </form>
        </div>
      </div>
      <TOSModal />
    </div>
  );
};
export default Login;

function TOSModal() {
  return (
    <div
      id="hs-vertically-centered-scrollable-modal"
      class="hs-overlay pointer-events-none fixed start-0 top-0 z-[80] hidden size-full overflow-y-auto overflow-x-hidden"
      role="dialog"
      tabindex="-1"
      aria-labelledby="hs-vertically-centered-scrollable-modal-label"
    >
      <div class="m-3 mt-0 flex h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] items-center opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
        <div class="pointer-events-auto flex max-h-full w-full flex-col overflow-hidden rounded-xl border bg-white shadow-sm dark:border-neutral-700 dark:bg-neutral-800 dark:shadow-neutral-700/70">
          <div class="flex items-center justify-between border-b px-4 py-3 dark:border-neutral-700">
            <h3
              id="hs-vertically-centered-scrollable-modal-label"
              class="font-bold text-gray-800 dark:text-white"
            >
              Terms And Conditions
            </h3>
            <button
              type="button"
              class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:bg-neutral-700 dark:text-neutral-400 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600"
              aria-label="Close"
              data-hs-overlay="#hs-vertically-centered-scrollable-modal"
            >
              <span class="sr-only">Close</span>
              <svg
                class="size-4 shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </button>
          </div>
          <div class="overflow-y-auto p-4">
            <div class="space-y-4">
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  1. SCOPE
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  These Terms and Conditions have been agreed between Nilfisk
                  and the customers of its Products transmitting data to Nilfisk
                  and to Nilfisk Food Connect Hybrid 7 Platform Web Portal
                  and/or App (“Connectivity Platform”), and are incorporated by
                  reference or by an appendix as an integral part of the sales
                  and/or lease agreement between Nilfisk and the customer.{" "}
                  <span className="font-bold">
                    To have access to the Connectivity Platform you (the User)
                    must comply with these General Terms and Conditions
                    (“Terms”).
                  </span>
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  These Terms apply when a User access and uses the Connectivity
                  Platform and the information available at the Connectivity
                  Platform. They set out the rights and obligations relevant
                  when using the Connectivity Platform. These Terms shall also
                  apply in case Nilfisk customers provide access to the
                  Connectivity Platform to their customers and/or third-party
                  service providers for the sole purpose of the service provider
                  to perform cleaning services for and/or on behalf of the
                  customer.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk may modify and amend these Terms as well as the
                  Connectivity Platform from time to time. Continued use of the
                  Connectivity Platform constitutes an acceptance of the amended
                  Terms.
                </p>
              </div>

              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  2. DEFINITIONS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  “User” means a person in legal possession of, using and/or
                  operating a Product and who will use and be provided access by
                  Nilfisk to the Connectivity Platform, including employees of
                  Nilfisk customers and their customers as well as persons
                  operating Products on behalf of Nilfisk customers or
                  otherwise.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  “Product” means any Nilfisk Products that can in any way
                  transmit machine operation data to the Connectivity Platform.
                </p>

                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  “Services” mean access to machine operation data from Products
                  such as information about the Product’s performance and
                  operation, notifications provided by Nilfisk to the User of
                  any deviations and issues with the Product, in order to help
                  the User with keeping the Product operational and functional,
                  and any similar information provided by Nilfisk to the User
                  via the Connectivity Platform.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  3. ACCEPTANCE
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The User can create a Connectivity Platform account by signing
                  up via the Web Portal or the App, by providing a name, email,
                  and password. The User will then have to accept these Terms
                  after and will subsequently receive an email from Nilfisk to
                  confirm the User’s account. When the User’s account has been
                  confirmed and activated the User accepts and must comply with
                  these Terms without any conditions or limitations. To receive
                  the machine operation data from a Product through the
                  Connectivity Platform the User shall after the initial login
                  to the Connectivity Platform then follow the instructions
                  provided on the Product. Users should direct any questions
                  they have about these Terms to their employer or to the
                  company for which they operate the Product.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  4. LICENSE TO THE CONNECTIVITY PLATFORM AND RIGHT OF ACCESS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk grants the User’s employer or the company for which
                  the User operates a Product a non-exclusive, non-transferable
                  right to permit Users to use the Connectivity Platform and the
                  Services provided through the Connectivity Platform until
                  termination in accordance with these Terms. This right may not
                  be assigned, sub-licensed, and may not otherwise transfer any
                  rights or obligations under these Terms to any other person or
                  legal entity, unless specifically agreed with Nilfisk.{" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  5. USE OF THE CONNECTIVITY PLATFORM AND USER OBLIGATIONS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The User is fully responsible for the use of the Connectivity
                  Platform in accordance with these Terms. The User confirms
                  that they have the right to use and operate the Product and
                  the associated data and information solely in connection with
                  the User’s employment. The User shall ensure that any
                  password(s) to the Connectivity Platform are kept
                  confidential, treated in a secured manner, and will
                  immediately notify Nilfisk of any breach of security with
                  respect to such password(s).
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The User must use the Connectivity Platform in a lawful manner
                  and in compliance with applicable laws and must not cause, or
                  knowingly allow others to cause, any interruption, nuisance,
                  or inconvenience to Nilfisk or to any other third party using
                  the Connectivity Platform by any means, including but not
                  limited to by knowingly introducing viruses, trojans, or other
                  malicious or harmful material.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Use of the Connectivity Platform requires any party accessing
                  the Platform to employ up-to-date virus protection software in
                  order to prevent viruses and similar harmful codes onto the
                  Connectivity Platform and take appropriate measures to avoid
                  accidental or unlawful destruction, loss, alteration,
                  unauthorized disclosure or access as well as unlawful
                  processing of to the information and/or data retrieved from
                  the Connectivity Platform.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  If the Product that transmit data to the Connectivity Platform
                  is sold or in any way handed over to someone else than the
                  User, it is the sole responsibility of the User to ensure that
                  all data and connections between the Product and to the
                  Connectivity Platform is disabled by resetting the connection
                  on the Product.{" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  6. NILFISK OBLIGATIONS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk shall provide the User access to the Connectivity
                  Platform once the User has created an account by providing a
                  name, email, and password in accordance with Section 3 above
                  as well as provide the Services. Nilfisk takes all reasonable
                  steps to safeguard the security of any information by the User
                  or information used by the User in connection with the
                  Connectivity Platform.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  7. INTELLECTUAL PROPERTY RIGHTS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  All intellectual property rights used or embodied in or in
                  connection with the Connectivity Platform are the sole
                  property of Nilfisk. Nothing in these Terms grants the User,
                  their employer and/or the company for which they operate the
                  Product any right or license to use any trademark, design
                  right or copyright owned or controlled by Nilfisk or any third
                  party except as expressly provided in these Terms.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  8. NO WARRANTY
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk attempts to provide the Services as accurate as
                  possible though the Connectivity Platform to the User. Any
                  Services provided through the Connectivity Platform is
                  provided "as is" and without any warranties, either expressed
                  or implied.
                  <br />
                  Due to the nature of the internet and dependencies on third
                  party suppliers, access to the Connectivity Platform may not
                  be continuous, uninterrupted, or error-free and the
                  Connectivity Platform may not always be available due to
                  upgrades, maintenance or otherwise. Therefore, Nilfisk is not
                  liable to the User if any of these things occur.
                  <br />
                  Nilfisk will use reasonable endeavors to make the Connectivity
                  Platform available for the User, but Nilfisk cannot guarantee
                  such availability. If the Connectivity Platform is
                  unavailable, Nilfisk will make all commercially reasonable
                  efforts to recover the Services on shortest notice possible.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  9. LIMITATION OF LIABILITY
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk is not liable for any consequential damages or
                  indirect loss, loss of income, loss of profit, loss of and/or
                  alteration of data, downtime, and/or loss of business
                  opportunity arising out of the use or inability to use the
                  Connectivity Platform, the misuse of the password(s) or of any
                  other login information.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  10. CONFIDENTIALITY
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  All information available on the Connectivity Platform,
                  Services, the processes used, and all related documentation
                  and data are Nilfisk’s proprietary confidential information
                  that may not be shared with any third party, except as
                  otherwise stated below in the section{" "}
                  <span className="font-bold">
                    Data Terms, Data Protection and Personal Data.
                  </span>
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The confidentiality obligation under this Section 10 shall
                  continue to apply if the User or Nilfisk, for whatever reason,
                  discontinue to use and/or support the Connectivity Platform.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The User may not sell, rent, or alter any information and/or
                  data retrieved from the Connectivity Platform. The User may
                  only transfer, distribute, disclose, and copy information from
                  the Connectivity Platform for the User’s internal employment
                  use involving Product operation.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  11. DATA TERMS, DATA PROTECTION, AND PERSONAL DATA
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  In consideration of the User’s access to the Connectivity
                  Platform, the User, as regards to Products transmitting
                  machine operation data, agrees to follow the Data Terms
                  applicable for the Nilfisk Product being operated, which Data
                  Terms are available on Nilfisk’s webpage
                  https://www.nilfisk.com/global/legal/data-terms/ and which
                  Nilfisk may amend from time to time.{" "}
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  The Connectivity Platform may only be used and operated in
                  compliance with applicable data privacy laws, including but
                  not limited to the European General Data Protection Regulation
                  (GDPR).
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Product operation information available on the Connectivity
                  Platform describing when and where a specific Product is
                  operated may, under applicable data privacy laws, be personal
                  data belonging to the Nilfisk customer owning or leasing the
                  Product, or to a third party operating the Product on that
                  customer’s behalf.
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk collects personal data from the User’s employer and/or
                  the User primarily for setting up the User’s Connectivity
                  Platform access account, and communications involving Product
                  operation, and such data is managed and processed in
                  accordance with Nilfisk’s privacy policy, which can be found
                  on Nilfisk’s webpage
                  https://www.nilfisk.com/global/legal/privacy/.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  12. SUSPENSION AND TERMINATION
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  Nilfisk may suspend the Connectivity Platform at any time at
                  Nilfisk’s sole discretion without any liability. The User may
                  also withdraw its acceptance of these Terms, after which time
                  the User will no longer be provided access to the Connectivity
                  Platform. The User’s right to use the Connectivity Platform
                  will automatically terminate immediately in the event of a
                  material breach by the User of these Terms.
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  13. MISCELLANEOUS
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  If a provision of these Terms is held by any competent
                  authority to be invalid, illegal, or unenforceable, in whole
                  or in part, the remaining provisions of these Terms shall
                  remain in full force and effect.{" "}
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  All clauses which either expressly or by implication are
                  intended to survive will continue to apply after these Terms
                  ends.{" "}
                </p>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  No rights or obligations under these Terms may be assigned or
                  delegated without the prior written consent of Nilfisk. Any
                  purported assignment or delegation in violation of this
                  Section is null and void. No assignment or delegation relieves
                  the User of any of its obligations under this Agreement.{" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg font-semibold text-gray-800 dark:text-white">
                  14. GOVERNING LAW AND DISPUTE RESOLUTION
                </h3>
                <p class="mt-4 text-gray-800 dark:text-neutral-400">
                  These Terms and any dispute or claim arising out of or in
                  connection with them, their subject matter or formation shall
                  be governed by and construed in accordance with the internal
                  laws (excluding the laws of conflicts) of Denmark, and each
                  party irrevocably agrees that the courts of Copenhagen,
                  Denmark have sole and exclusive jurisdiction and venue to
                  settle any dispute or claim arising out of or in connection
                  with these Terms, their subject matter or formation, including
                  without limitation non-contractual disputes and claims. The UN
                  Convention of Agreements for the International Sale of Goods
                  is expressly and entirely excluded.
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-2 border-t px-4 py-3 dark:border-neutral-700">
            <button
              type="button"
              class="inline-flex items-center gap-x-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 focus:bg-gray-50 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              data-hs-overlay="#hs-vertically-centered-scrollable-modal-label"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
