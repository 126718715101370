import React from "react";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import RemoveMachineDialog from "./components/RemoveMachineDialog";
import TextInputForm from "./components/TextInputForm";
import ChangeRoomField from "./components/ChangeRoomField";

export default function ViewMachineDetails() {
  const { machineData, machineLoading } = useOutletContext();
  console.log("testtttt", useOutletContext());
  const myAccount = useSelector((state) => state.user.user);
  const [removeMachineOpen, setRemoveMachineOpen] = React.useState(false);

  let canRemove =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === machineData?.FactoryId,
  )?.FactoryUser?.factoryRole;

  if (factoryRole === "Manager") {
    canRemove = true;
  }

  return (
    <>
      <RemoveMachineDialog
        open={removeMachineOpen}
        setOpen={setRemoveMachineOpen}
        machineId={machineData?.id}
        machineName={machineData?.name}
        serialNumber={machineData?.serialNumber}
        factoryId={machineData?.FactoryId}
      />
      <div className="mx-auto max-w-7xl justify-between gap-x-4 lg:grid lg:grid-cols-2">
        <div className="w-full ">
          <div className="overflow-visible bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Machine Information
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <TextInputForm
                  name="Alias"
                  autoComplete="Alias"
                  defaultValue={machineData?.name}
                  type="text"
                  required={true}
                  machineData={machineData}
                  isLoading={machineLoading}
                />
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Serial number
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {machineLoading ? (
                      <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
                    ) : (
                      machineData?.serialNumber
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Type</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {machineLoading ? (
                      <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
                    ) : (
                      machineData?.type
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Mac address
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {machineLoading ? (
                      <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
                    ) : (
                      machineData?.Mac?.macAddress || "N/A"
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Manufacture date
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {machineLoading ? (
                      <div className="h-3 w-1/2 animate-pulse rounded bg-slate-200"></div>
                    ) : (
                      moment(machineData?.createdAt || "2000-1-1").format(
                        "DD MMM YYYY",
                      )
                    )}
                  </dd>
                </div>
                <ChangeRoomField 
                      name="Room"
                      defaultValue={machineData?.Room || "N/A"}
                      type="text"
                      required={true}
                      machineData={machineData}
                      isLoading={machineLoading}
                      factoryId={machineData?.FactoryId}
                    />
              </dl>
            </div>
          </div>
          <div className="my-4 bg-white shadow-sm ring-1  ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Remove
              </h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    Remove the machine from the factory. This action is
                    irreversible and will remove all the data
                  </p>
                </div>
                <div className="mx-2 mt-5 text-end sm:mx-0 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <button
                    type="button"
                    className="inline-flex w-24 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={() => setRemoveMachineOpen(true)}
                    disabled={!canRemove}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full sm:mt-0">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Last Session Data
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Power</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {/* {factoryDetails?.machinesStatus == null ? "N/A" : factoryDetails?.machinesStatus} */}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Run Time
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {/* {factoryDetails?.machines?.length || 0} */}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Pressure Avg.
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {/* {factoryDetails?.machines?.length || 0} */}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Water Temp Avg.
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {/* {factoryDetails?.machines?.length || 0} */}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
