import React, { useEffect, useRef, useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useTelemetryDataQuery } from "hooks/machine";
import { initiateSocket, getSocket, joinRoom } from "shared/services/socket.service";
import LineChartNew from "pages/Dashboard/Chart/LineChartNew";
import LineChartInverter from "pages/Dashboard/Chart/LineChartInverter";
var format = require("date-format");

export default function ChartsWrapper(props) {
  const { machineData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataOrFound, setNoDataOrFound] = useState(false);

  const {
    data: telemetryData,
    isLoading: machineLoading,
    error: machineError,
  } = useTelemetryDataQuery(machineData?.serialNumber);

  useEffect(() => {
    if (telemetryData) {
      const data = telemetryData?.data?.device;

      

      setData(data ?? []);

      setLoading(false);
    }
  }, [telemetryData]);

  useEffect(() => {
    
    let socket = getSocket();
    console.log("sockeet", socket)
    if(socket)
    joinRoom(machineData?.serialNumber);

    socket.on("connect_error", (err) => {
      console.log("Connection Error socket", err);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected socket");
      setTimeout(() => {
        socket.connect();
      }, 2000);
    });

    socket.on("connect", function () {
      console.log("Connected socket");
    });

    socket.on("reconnect", () => {
      console.log("Trying to reconnect socket.io");
    });

    socket.on("telemetry-" + machineData?.serialNumber, (data) => {
      const messageData = JSON.parse(data);
      let device = messageData.IotData;
      console.log("device", device);
      var flowSwitch = 0;
      if (device.FlowSw > 1000) {
        flowSwitch = 10;
      }
      if(device.Temperature_Room > 1000){
        device.Temperature_Room = 0;
      }
      if(device.Temperature_igbt > 1000){
        device.Temperature_igbt = 0;
      }

      device = {
        ...device,
        EventEnqueuedUtcTime: messageData.MessageDate,
        Inverter_loadPowP: device.Inverter_loadPowP / 1000,
        FlowSw: flowSwitch,
      };

      if (data.length >= 270)
        setData((prevState) => [...prevState.slice(1), device]);
      //removing old telemetry data once a new one is added
      else setData((prevState) => [...prevState, device]); //removing old telemetry data once a new one is added
    });
  }, [machineData]);


  return (
    <div className="grid-cols-1 justify-between gap-4 sm:grid xl:grid-cols-2">
      <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Temperature and Pressure
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">The temperature is measured in °C </p> */}
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="min-h-80 min-w-80 px-4 py-6 sm:px-6">
              <LineChartNew
                telemetryData={data}
                loading={loading}
                noDataOrFound={noDataOrFound}
                type={"press-temp"}
              />
            </div>
          </dl>
        </div>
      </div>
      <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Inverter Frequency and Power
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">The pressure is measured in bar</p> */}
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="min-h-80 min-w-80 px-4 py-6 sm:px-6">
              <LineChartInverter
                telemetryData={data}
                loading={loading}
                noDataOrFound={noDataOrFound}
              />
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
