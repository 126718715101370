import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRoomsByFactoryIdQuery } from "../../../hooks/factory/useRoomsByFactoryIdQuery";
import { useDeleteRoom } from "hooks/room";
import { TrashIcon, ExclamationIcon } from "@heroicons/react/24/outline";
export default function DeleteRoomDialog({ open, setOpen, factoryId }) {
  const queryClient = useQueryClient();
  const { data: roomsData, isLoading: roomsLoading } =
    useRoomsByFactoryIdQuery(factoryId);
  const rooms = roomsData?.data?.rooms;
  const {
    mutate: deleteRoom,
    isError,
    error,
    isLoading: isDeleting,
  } = useDeleteRoom();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  useEffect(() => {
    if (!open) return;
    // This function runs when the dialog opens
  }, [open]);

  const handleDelete = () => {
    if (!selectedRoomId) return;
    deleteRoom(selectedRoomId, {
      onSuccess: () => {
        queryClient.invalidateQueries(["factory", factoryId, "rooms"]);
        closeConfirm();
      },
      onError: (err) => {
        console.error("Error deleting room:", err);
        closeConfirm();
      },
    });
  };

  const openConfirm = (roomId) => {
    setIsConfirmOpen(true);
    setSelectedRoomId(roomId);
  };

  const closeConfirm = () => {
    setIsConfirmOpen(false);
    setSelectedRoomId(null);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Delete Room
              </DialogTitle>
              <div className="mt-4 space-y-2">
                {roomsLoading ? (
                  <div className="flex items-center justify-center">
                    <div
                      className="top-1.5 ml-2 inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                      role="status"
                      aria-label="loading"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  rooms &&
                  rooms.map((room) => (
                    <div
                      key={room.id}
                      className="flex items-center justify-between rounded-md px-2 py-1 hover:bg-gray-50"
                    >
                      <span className="text-sm font-medium text-gray-700">
                        {room.name}
                      </span>
                      <button
                        onClick={() => openConfirm(room.id)}
                        className="rounded-full p-1 text-red-500 hover:bg-red-100"
                      >
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  ))
                )}
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Close
                </button>
              </div>
              {isError && (
                <div className="mt-2 text-sm text-red-600">
                  Make sure you delete or move all machines in the room before deleting the room.
                </div>
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={isConfirmOpen}
        onClose={closeConfirm}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Confirm Deletion
              </DialogTitle>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this room? This action cannot
                  be undone.
                </p>
              </div>
              <div className="mt-4 flex justify-end gap-3">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={closeConfirm}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={isDeleting}
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-70"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
