import apiClient from "./apiClient";

/* ----- Get Methods ----- */

// invitation/invite/own/factory/:FactoryId

/* ----- Post Methods ----- */
export const inviteOwnUser = (FactoryId, data) => {
  return apiClient.post(`invitation/invite/own/factory/${FactoryId}`, data);
};

export const getOwnInvitations = (data) => {
  return apiClient.get(`invitation/own`, data);
};

export const answerInvitation = (InvitationId, data) => {
  return apiClient.post(`invitation/answer/${InvitationId}`, data);
};
