import { useMutation } from "@tanstack/react-query";
import { updateFactory } from "api/factory.service";
import { useSelector } from "react-redux";
import { updateFactoryOwn } from "../../api/factory.service";

export const useUpdateFactory = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");
  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return updateFactory(data);
      else return updateFactoryOwn(data);
    },
  });
};
