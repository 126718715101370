import React from "react";
import { useNavigate } from "react-router-dom";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const ResetPasswordSuccessfully = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center bg-nilfisk py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Password changed successfully!
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img
            className="mx-auto mb-6 w-auto"
            src={nilfiskFoodLogo}
            alt="Nilfisk Food"
          />
          <div className="space-y-6">
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="size-5 text-green-400"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    Password changed successfully!
                  </p>
                </div>
              </div>
            </div>

            <div>
              <button
                onClick={() => navigate("/login")}
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Go to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordSuccessfully;
