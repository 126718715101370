import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
var format = require("date-format");

export default function BarChartConsumption({
  data,
  loading,
  // noDataOrFound,
  // type,
}) {
  const COLORS = [
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e74c3c",
    "#7f8c8d",
    "#107C10",
    "#E81123",
    "#FF8C00",
    "#0099BC",
  ];
  const tickFormatter = (tick) => format("hh:mm:ss", new Date(tick));

  var domain = [0, 120];
  // const data = res.data.result;
  if (data === undefined) {
    return <div>No data</div>;
  }
  const runningHoursArray = [];

  const totalPowerArray = [];
  const runningHoursLength = data.RunningHours.length;

  data.RunningHours.forEach((element, index) => {
    const day = new Date();
    day.setDate(day.getDate() - index);
    const dayName = day.toString().split(" ")[0];


    let existingEntry = runningHoursArray.find(
      (entry) => entry.name === dayName,
    );

    if (!existingEntry) {
      existingEntry = {
        name: dayName,
        thisWeek: 0,
        lastWeek: 0,
      };
      runningHoursArray.push(existingEntry);
    }

    if (runningHoursLength - 1 - index < 7) {
      existingEntry.lastWeek = element;
    } else {
      existingEntry.thisWeek = element;
    }
  });
  //flip the array so that the last day is first
  runningHoursArray.reverse();

  return (
    <div>
      {loading ? (
        <div className="h-72 w-auto animate-pulse rounded bg-slate-200"></div>
      ) : false ? (
        <div>
          <div className="flex flex-row items-center justify-center">
            <div className="text-base text-gray-700">
              We could not receive data from the machines <br />
              The machine might be turned off or the internet connection is
              lost.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                // label={<CustomLabel />}
                data={runningHoursArray}
                margin={{ top: 0, right: -10, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  // tickFormatter={tickFormatter}
                  // angle={30}
                  // dx={20}
                  // tickMargin={15}
                  height={10}
                  type="category"
                ></XAxis>
                {/* <YAxis yAxisId="left" unit=' Bar' domain={[0, 40]} />  label={{ value: "Bar", position: 'insideLeft' }} domain={['dataMin', 'dataMax']} label={{ value: this.state.widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}
                <YAxis
                  orientation="right"
                  unit=" H"
                  width={65}
                  // domain={[0, 120]}
                  allowDecimals={true}
                />
                {/* label={{ value: "°C", position: 'insideRight' }}*/}
                {/* <YAxis yAxisId="left" unit=" Bar" domain={[0, 40]} /> */}
                {/*label={{ value: "Bar", position: 'insideLeft' }}  domain={['dataMin', 'dataMax']} label={{ value: widget.deviceValue, angle: -90, position: 'insideLeft' }}  */}
                <Tooltip
                  // position={{ x: -20 }}

                  // allowEscapeViewBox={{ x: false, y: true }}
                  labelFormatter={(t) => {
                    //convert from the short day name to the full day name, for example t = "Mon" return "Monday" etc

                    const daysOfWeek = {
                      Mon: "Monday",
                      Tue: "Tuesday",
                      Wed: "Wednesday",
                      Thu: "Thursday",
                      Fri: "Friday",
                      Sat: "Saturday",
                      Sun: "Sunday",
                    };

                    return daysOfWeek[t] || "Invalid day";
                  }}
                  isAnimationActive={false}
                />
                <Legend
                  wrapperStyle={{ paddingTop: 15 }}
                  iconType="plainline"
                />
                <Bar
                  dataKey="lastWeek"
                  fill="#1abc9c"
                  activeBar={<Rectangle stroke="blue" />}
                />
                <Bar
                  dataKey="thisWeek"
                  fill="#3498db"
                  activeBar={<Rectangle stroke="blue" />}
                />
                {/* <Bar
                  // strokeWidth={2}
                  legendType="plainline"
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="PressureOUT"
                  stroke={COLORS[0]}
                  name="Pressure Out"
                  dot={false}
                  yAxisId="left"
                /> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div id="html-dist"></div>
        </div>
      )}
    </div>
  );
}
function CustomLabel({ x, y, stroke, value, width }) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}
